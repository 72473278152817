import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Button, Form, H1, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'
import { parse } from 'querystring'

import { resetPassword } from '../actions'

import { routeTo } from '@sodra/prutt'
import LandingPage from './LandingPage'

const ResetPassword = ({ isResettingPassword }) => {
  const [password, setPassword] = useState('')
  const { token } = parse(location.search.slice(1))
  const [passwordError, setPasswordError] = useState()

  const handleSubmit = async () => {
    if (!password || password.trim() === '') {
      setPasswordError('Please enter a password')
    } else {
      if (await resetPassword({ password, token })) {
        routeTo('/', true)
      }
    }
  }

  return (
    <LandingPage action="login">
      <H1 size="6">Set password</H1>
      <SpacerVertical />
      <Block>Please enter your new password</Block>
      <SpacerVertical />
      <Form>
        <TextField
          width="100%"
          type="password"
          label="New password"
          value={password}
          onInput={setPassword}
          errorText={passwordError}
        />
        <SpacerVertical />
        <Button contained type="submit" loading={isResettingPassword} onClick={handleSubmit}>
          Save
        </Button>
      </Form>
    </LandingPage>
  )
}

export default connect('isResettingPassword')(ResetPassword)
