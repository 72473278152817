import { store } from '../store'
import { get, patch, post, del } from '../api'

export const getLineAudio = async () => {
  store.setState({ isFetchingLineAudio: true })
  try {
    const userId = store.getState().user.id
    const { data: lineAudio } = await get(`/users/${userId}/line-audio`)
    store.setState({ isFetchingLineAudio: false })
    return lineAudio
  } catch (error) {
    store.setState({ error, isFetchingLineAudio: false })
  }
}

export const uploadLineAudio = async (lineId, waveBlob) => {
  store.setState({ isUploadingLineAudio: true })
  try {
    const {
      data: { signedUrl, uri }
    } = await post('/sign-url', {
      prefix: 'line-audio',
      contentType: 'audio/wav',
      ext: 'wav'
    })

    await fetch(signedUrl, {
      method: 'PUT',
      body: waveBlob,
      headers: {
        'Content-Type': 'audio/wav'
      }
    })

    const userId = store.getState().user.id
    await post(`/users/${userId}/line-audio`, { uri, lineId })
    store.setState({ isUploadingLineAudio: false })
    return uri
  } catch (error) {
    store.setState({ error, isUploadingLineAudio: false })
  }
}

export const deleteLineAudio = async (lineId) => {
  store.setState({ isDeletingLineAudio: true })
  try {
    const userId = store.getState().user.id
    await del(`/users/${userId}/line-audio/${lineId}`)
    store.setState({ isDeletingLineAudio: false })
  } catch (error) {
    store.setState({ error, isDeletingLineAudio: false })
  }
}

export const uploadAudio = async (file, params) => {
  store.setState({ isUploadingAudio: true })
  try {
    const ext =
      file.name.lastIndexOf('.') >= 0 ? file.name.slice(file.name.lastIndexOf('.') + 1) : ''

    const {
      data: { signedUrl, uri }
    } = await post('/sign-url', {
      prefix: 'audio',
      contentType: file.type,
      ext
    })

    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })

    params.uri = uri

    const userId = store.getState().user.id
    const { data: user } = await post(`/users/${userId}/audio`, params)
    store.setState({ user, isUploadingAudio: false })
    return user
  } catch (error) {
    store.setState({ error, isUploadingAudio: false })
  }
}

export const updateAudio = async (audioId, params) => {
  store.setState({ isUpdatingAudio: true })
  try {
    const userId = store.getState().user.id
    const { data: user } = await patch(`/users/${userId}/audio/${audioId}`, params)
    store.setState({ user, isUpdatingAudio: false })
    return user
  } catch (error) {
    store.setState({ error, isUpdatingAudio: false })
  }
}

export const deleteAudio = async (audioId) => {
  store.setState({ isDeletingAudio: true })
  try {
    const userId = store.getState().user.id
    const { data: user } = await del(`/users/${userId}/audio/${audioId}`)
    store.setState({ user, isDeletingAudio: false })
    return user
  } catch (error) {
    store.setState({ error, isDeletingAudio: false })
  }
}
