import { h } from 'preact'
import { ProgressCircular } from '@sodra/bongo-ui'
import { Row } from 'jsxstyle/preact'

const Spinner = (props) => {
  return (
    <Row
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <ProgressCircular />
    </Row>
  )
}

export default Spinner
