import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import './Badge.css'

const BadgeSvg = ({ size, color, strokeColor, visible = true }) => (
  <svg width={size} height={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
    <circle
      class={`bui-badge ${visible ? 'bui-badge-enter' : 'bui-badge-exit'}`}
      fill={color}
      stroke={strokeColor}
      stroke-width={strokeColor ? '6' : undefined}
      cx="32"
      cy="32"
      r="26"
    />
  </svg>
)

export const Badge = ({
  visible = true,
  color = 'var(--badge)',
  strokeColor,
  onEnterDone,
  onExitDone,
  props,
  ...style
}) => {
  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(onEnterDone, 1440)
      return () => clearTimeout(timeout)
    } else {
      const timeout = setTimeout(onExitDone, 360)
      return () => clearTimeout(timeout)
    }
  }, [visible])

  return (
    <Block
      position="absolute"
      top="0"
      right="0"
      width="14px"
      height="14px"
      props={props}
      {...style}
    >
      <BadgeSvg visible={visible} size={14} color={color} strokeColor={strokeColor} />
    </Block>
  )
}
