import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { goBack } from '@sodra/prutt'
import { BasicDialog, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { updateUser } from '../../actions'

const ChangePassword = ({ user, isUpdatingUser }) => {
  const [password, setPassword] = useState('')

  const onClose = () => goBack(`/user/${user.id}`)

  const handleSubmit = async () => {
    if (await updateUser({ password })) {
      onClose()
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Change password"
      primaryActionText="Save"
      primaryActionLoading={isUpdatingUser}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <TextField
        width="100%"
        type="password"
        name="new-password"
        label="New password"
        value={password}
        onInput={setPassword}
      />
      <SpacerVertical />
    </BasicDialog>
  )
}

export default connect('user, isUpdatingUser')(ChangePassword)
