import { h } from 'preact'
import { Block } from 'jsxstyle/preact'

const Section = ({ children, ...props }) => {
  return (
    <Block
      border="1px solid var(--container-outline)"
      overflow="hidden"
      padding="20px"
      borderRadius="5px"
      {...props}
    >
      {children}
    </Block>
  )
}

export default Section
