import { h } from 'preact'
import { Block } from 'jsxstyle/preact'

export const Divider = ({ middle, inset, ...style }) => {
  let margin
  if (middle) {
    margin = '0 30px'
  } else if (inset) {
    margin = '0 0 0 75px'
  }
  return (
    <Block
      class="bui"
      {...style}
      margin={margin}
      borderTop="1px solid var(--container-outline-lighter)"
    />
  )
}

export const DividerVertical = ({ middle, ...style }) => {
  let margin
  if (middle) {
    margin = '30px 0'
  }
  return (
    <Block
      class="bui"
      {...style}
      margin={margin}
      borderLeft="1px solid var(--container-outline-lighter)"
    />
  )
}

export const DividerHorizontal = ({ ...style }) => {
  return <Block class="bui" {...style} borderTop="1px solid var(--container-outline-lighter)" />
}
