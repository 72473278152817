import { useState, useEffect } from 'preact/hooks'

import { findScrollableContainer } from './find-scrollable-container'

export const useGetBounds = (elem, visible) => {
  const [bounds, setBounds] = useState()
  const [isUpdatingBounds, setIsUpdatingBounds] = useState(false)

  let boundsUpdateTimeout
  const handleBoundsUpdate = (elem) => {
    if (boundsUpdateTimeout) {
      clearTimeout(boundsUpdateTimeout)
    }
    setIsUpdatingBounds(true)
    boundsUpdateTimeout = setTimeout(() => {
      setBounds(elem.getBoundingClientRect())
      setIsUpdatingBounds(false)
    }, 50)
  }

  useEffect(() => {
    if (elem && visible) {
      handleBoundsUpdate(elem)
      const resizeObserver = new ResizeObserver((_) => {
        handleBoundsUpdate(elem)
      })
      resizeObserver.observe(document.body)
      return () => {
        resizeObserver.unobserve(document.body)
      }
    }
  }, [elem, visible])

  useEffect(() => {
    if (elem && visible) {
      const scrollableContainer = findScrollableContainer(elem)
      if (scrollableContainer) {
        const handleScroll = () => {
          handleBoundsUpdate(elem)
        }
        scrollableContainer.addEventListener('scroll', handleScroll)
        return () => {
          if (scrollableContainer) {
            scrollableContainer.removeEventListener('scroll', handleScroll)
          }
        }
      }
    }
  }, [elem, visible])

  return [bounds, isUpdatingBounds]
}
