export function getDialogueStats(
  lineAudio: LineAudio[],
  characterId: string,
  dialogue: Dialogue
): DialogueStats {
  const lines = dialogue.lines.filter((line: any) => line.line.character?.id === characterId)
  let totalLines = lines.length
  const recorderLineIds = lineAudio.map((la) => la.lineId)
  let recordedLines = 0
  for (let line of lines) {
    if (recorderLineIds.includes(line.line.id)) {
      recordedLines++
    }
  }
  return { totalLines, recordedLines, recorded: totalLines === recordedLines }
}
