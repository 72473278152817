import { h } from 'preact'
import { connect } from 'unistore/preact'
import { useState } from 'preact/hooks'
import { BasicDialog, Form, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { createUserInvitation } from '../../actions'

const InviteUser = ({ agency, isCreatingInvitation }) => {
  const [email, setEmail] = useState('')

  const onClose = () => goBack(`/agency/${agency.id}/manage-access`)

  const handleSubmit = async () => {
    if (await createUserInvitation({ email })) {
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Invite agency administrator"
      primaryActionText="Send"
      primaryActionLoading={isCreatingInvitation}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <TextField width="100%" type="email" label="Email" value={email} onInput={setEmail} />
      </Form>
    </BasicDialog>
  )
}

export default connect('agency, isCreatingInvitation')(InviteUser)
