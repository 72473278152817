import { h, Fragment } from 'preact'
import { useState, useRef } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'
import { Highlight } from './highlights'
import { Ripples } from './Ripples'
import { SpacerHorizontal } from './spacers'
import { IconButton } from './IconButton'
import { Tooltip } from './Tooltip'

export const SearchListItem = ({
  icon: Icon,
  visual,
  text,
  preventTextWrap = false,
  secondaryText,
  metaText,
  tooltipText,
  roundedCorners = false,
  selected = false,
  onHover,
  onClick,
  delayClick = false,
  leftWidth = '55px',
  actionIcon,
  actionTooltipText,
  onActionClick,
  props
}) => {
  const listItem = useRef()

  /* Border radius used when rendering as focused och clicked/tapped */
  const borderRadius = roundedCorners ? '3px' : '0'

  const [hover, setHover] = useState(false)

  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const handleClick = (e) => {
    e && e.preventDefault()
    setTimeout(
      () => {
        onClick && onClick(e)
      },
      delayClick ? 180 : 0
    )
  }

  const handleActionClick = (e) => {
    e && e.preventDefault && e.preventDefault()
    if (onActionClick) {
      onActionClick(e)
    }
  }

  const handleMouseEnter = (e) => {
    setHover(true)
    if (onHover) {
      onHover(true)
    }
  }

  const handleMouseLeave = () => {
    setHover(false)
    if (onHover) {
      onHover(false)
    }
  }

  const padding = '12px 20px'

  return (
    <Block
      component="li"
      class="bui"
      position="relative"
      outline="none"
      cursor="pointer"
      whiteSpace={preventTextWrap ? 'nowrap' : undefined}
      props={{
        ref: listItem,
        onMouseEnter: supportsHover ? handleMouseEnter : null,
        onMouseLeave: supportsHover ? handleMouseLeave : null,
        onClick: handleClick,
        role: 'listitem',
        ...props
      }}
    >
      {(hover || selected) && <Highlight borderRadius={borderRadius} opacity={0.15} />}
      <Ripples borderRadius={borderRadius} />
      <Row padding={padding} alignItems="center">
        {Icon && (
          <Block width={leftWidth}>
            <Icon fill="var(--on-surface-light)" />
          </Block>
        )}
        {visual && <Block width={leftWidth}>{visual}</Block>}
        <Col flex="1">
          <Block flex="1">{text}</Block>
          {secondaryText && (
            <Block
              marginTop="5px"
              fontSize="14px"
              lineHeight="16px"
              color="var(--on-surface-light)"
            >
              {secondaryText}
            </Block>
          )}
        </Col>
        {metaText && (
          <Fragment>
            <SpacerHorizontal small />
            <Block
              textAlign="right"
              fontSize="14px"
              lineHeight="16px"
              color="var(--on-surface-light)"
            >
              {metaText}
            </Block>
          </Fragment>
        )}
        {actionIcon && (
          <IconButton
            text
            icon={actionIcon}
            tooltipText={actionTooltipText}
            color="var(--on-surface-light)"
            focusable={false}
            onClick={handleActionClick}
            marginRight="-15px"
          />
        )}
        {tooltipText && (
          <Tooltip visible={focus || hover} generator={listItem} text={tooltipText} />
        )}
      </Row>
    </Block>
  )
}
