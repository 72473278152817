import { Block } from 'jsxstyle/preact'
import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { useIsMutating } from 'react-query'
import Spinner from './Spinner'

function useBeforeUnload(stopUnload: boolean){
  useEffect(() => {
    if (stopUnload) {
      const beforeUnloadListener = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        // TODO: Can't manage to get message to show up, only the standard browser message gets shown.
        return event.returnValue = "Are you sure you want to exit?";
      };

      addEventListener("beforeunload", beforeUnloadListener, { capture: true });

      return () => {
        removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
      }
    }
  }, [stopUnload])
}

export function GlobalLoadingIndicator() {
  const isMutating = useIsMutating()

  useBeforeUnload(isMutating > 0)

  if(isMutating > 0){
    return (
      <Block position="relative" width="35px" height="35px">
        <Spinner />
      </Block>
    )
  }

  return null
}