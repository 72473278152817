import { h } from 'preact'
import { useState } from 'preact/hooks'

import PopupMenu from './PopupMenu'

export const usePopupMenu = ({ options }) => {
  const [menuPosition, setMenuPosition] = useState()
  const [menuData, setMenuData] = useState()

  const showMenu = (e, data) => {
    if (e.type === 'keyboard') {
      setMenuPosition({ positionElement: e.target })
    } else {
      setMenuPosition({ position: { x: e.x, y: e.y } })
    }
    setMenuData(data)
  }

  const hideMenu = () => {
    setMenuPosition()
    setMenuData()
  }

  const popupMenu = menuPosition ? (
    <PopupMenu {...menuPosition} options={options} data={menuData} onClose={hideMenu} />
  ) : null

  return { PopupMenu: popupMenu, showMenu, hideMenu }
}
