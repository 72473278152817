import { h } from 'preact'
import { Dialog } from './Dialog'

export const BasicDialog = ({
  primaryActionText,
  primaryActionTooltipText,
  primaryActionId = 'primaryAction',
  primaryActionLoading = false,
  primaryActionDisabled = false,
  onPrimaryActionClick,
  action1Text,
  action1TooltipText,
  onAction1Click,
  action2Text,
  action2TooltipText,
  onAction2Click,
  secondaryActionText,
  secondaryActionStyle = 'text',
  secondaryActionIcon,
  secondaryActionTooltipText,
  secondaryActionLoading = false,
  secondaryActionDisabled = false,
  onSecondaryActionClick,
  delayClick = false,
  children,
  ...props
}) => {
  const actions = []
  if (primaryActionText) {
    actions.push({
      text: primaryActionText,
      tooltipText: primaryActionTooltipText,
      type: 'contained',
      onClick: onPrimaryActionClick,
      delayClick: delayClick,
      disabled: primaryActionDisabled,
      loading: primaryActionLoading,
      id: primaryActionId
    })
  }
  if (action1Text) {
    actions.push({
      text: action1Text,
      tooltipText: action1TooltipText,
      onClick: onAction1Click,
      delayClick: delayClick,
      id: 'action1'
    })
  }
  if (action2Text) {
    actions.push({
      text: action2Text,
      tooltipText: action2TooltipText,
      onClick: onAction2Click,
      delayClick: delayClick,
      id: 'action2'
    })
  }

  const secondaryActions = []
  if (secondaryActionText) {
    secondaryActions.push({
      text: secondaryActionText,
      tooltipText: secondaryActionTooltipText,
      type: secondaryActionStyle,
      icon: secondaryActionIcon,
      onClick: onSecondaryActionClick,
      delayClick: delayClick,
      loading: secondaryActionLoading,
      disabled: secondaryActionDisabled,
      id: 'secondaryAction'
    })
  }

  return (
    <Dialog actions={actions} secondaryActions={secondaryActions} {...props}>
      {children}
    </Dialog>
  )
}
