import { useMutation, useQueryClient } from 'react-query'
import { deleteLineAudio } from '../../actions'
import { saveAudio } from '../User/saveAudio'

export function useSaveMutation() {
  const queryClient = useQueryClient()

  return useMutation(saveAudio, {
    onSuccess: () => {
      queryClient.invalidateQueries('line-audio')
    },
    onMutate: async (newLine) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries('line-audio')

      // Snapshot the previous value
      const previousLineAudio = queryClient.getQueryData<LineAudio[]>('line-audio')

      // Optimistically update to the new value
      queryClient.setQueryData<LineAudio[]>('line-audio', (old) => {
        if (!old) return []
        // Find matching lineaudio
        let matchFound = false

        old = old.map((la) => {
          if (la.lineId === newLine.line.id) {
            matchFound = true
            return {
              ...la,
              uri: undefined,
              audioBuffer: newLine.recordedAudio
            }
          }
          return la
        })

        // Create if not found
        if (!matchFound) {
          old = [
            ...old,
            {
              created: new Date().toISOString(),
              lineAudioId: (Math.random() * 1e6).toString(),
              lineId: newLine.line.id,
              audioBuffer: newLine.recordedAudio
            }
          ]
        }

        return old
      })

      // Return a context object with the snapshotted value
      return { previousLineAudio }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData('line-audio', (context as any).previousLineAudio)
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries('line-audio')
    }
  })
}

export function useDeleteMutation() {
  const queryClient = useQueryClient()

  return useMutation(deleteLineAudio, {
    onSuccess: () => {
      queryClient.invalidateQueries('line-audio')
    },
    onMutate: async (lineId) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries('line-audio')

      // Snapshot the previous value
      const previousLineAudio = queryClient.getQueryData<LineAudio[]>('line-audio')

      // Optimistically update to the new value
      queryClient.setQueryData<LineAudio[]>('line-audio', (old) => {
        if (!old) return []

        old = old.filter((la) => la.lineId !== lineId)

        return old
      })

      // Return a context object with the snapshotted value
      return { previousLineAudio }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData('line-audio', (context as any).previousLineAudio)
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries('line-audio')
    }
  })
}
