import { h } from 'preact'
import { FloatingSheet } from './FloatingSheet'

export const SelectMenu = ({ positionElement, onClose, children }) => {
  return (
    <FloatingSheet
      positionElement={positionElement}
      padding={10}
      offset={5}
      onClose={onClose}
      positionFixed={true}
      disableScroll={true}
      placements={[
        'bottom-align-left',
        'bottom-align-right',
        'top-align-left',
        'top-align-right',
        'bottom-left',
        'bottom-right',
        'top-left',
        'top-right',
        'right',
        'left'
      ]}
    >
      {children}
    </FloatingSheet>
  )
}
