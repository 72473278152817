import { Fragment, h } from 'preact'
import { parse } from 'querystring'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Block, InlineBlock } from 'jsxstyle/preact'
import { routeTo } from '@sodra/prutt'
import { Button, Form, H1, P, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { acceptUserInvitation, fetchUserInvitation } from '../actions'

import LandingPage from './LandingPage'
import Spinner from './Spinner'
import Avatar from './Avatar'

const params = parse(location.search.slice(1))

const AcceptUserInvitation = ({ isAcceptingInvitation }) => {
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [invitation, setInvitation] = useState()

  useEffect(() => {
    fetchUserInvitation(params.invitation).then(setInvitation)
  }, [])

  if (!invitation) {
    return <Spinner />
  }

  const handleSubmit = async () => {
    if (await acceptUserInvitation(invitation.id, { name, password, userId: invitation.userId })) {
      routeTo('/')
    }
  }

  return (
    <LandingPage action="login">
      <Block textAlign="center">
        <Block component="h1" fontSize="14px" lineHeight="36px" fontWeight="normal">
          Join
          <br />
          <InlineBlock fontSize="34px" lineHeight="36px">
            {invitation.agency.name}
          </InlineBlock>
        </Block>
        {invitation.agency.picture && (
          <Avatar src={invitation.agency.picture} name={invitation.agency.name} size={90} />
        )}
      </Block>
      <SpacerVertical large />
      {invitation.userId && (
        <Block textAlign="center">
          <Button contained onClick={handleSubmit} loading={isAcceptingInvitation}>
            Accept invitation
          </Button>
        </Block>
      )}
      {!invitation.userId && (
        <Form onSubmit={handleSubmit}>
          <P padding="5px 5px 0 15px">
            <Block
              fontSize="12px"
              lineHeight="14px"
              marginBottom="2px"
              color="var(--on-surface-light)"
            >
              Email
            </Block>
            <Block>{invitation.email}</Block>
          </P>
          <SpacerVertical />
          <TextField
            width="100%"
            autocomplete="off"
            name="new-name"
            label="Name"
            value={name}
            onInput={setName}
          />
          <SpacerVertical />
          <TextField
            width="100%"
            type="password"
            autocomplete="new-password"
            name="new-password"
            label="Set password"
            value={password}
            onInput={setPassword}
          />
          <SpacerVertical />
          <P>
            By signing up you agree to the Speechless{' '}
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1ev23IgNfPYeorSRqyTG0izEa_cyuF9GI/view"
            >
              privacy policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1iDrB7l04B-AMUaNjwZqbAgRzFDDyr5y8/view"
            >
              agent terms and conditions
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1S-kb4kAdd6OurqWKytzSoWR0i-xcg-JW/view"
            >
              artist terms and conditions
            </a>
            .
          </P>
          <SpacerVertical large />
          <Button contained type="submit" loading={isAcceptingInvitation}>
            Continue
          </Button>
          <SpacerVertical />
        </Form>
      )}
    </LandingPage>
  )
}

export default connect('isAcceptingInvitation')(AcceptUserInvitation)
