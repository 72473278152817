import { store } from '../store'
import { get, post } from '../api'

export const fetchVoiceInvitation = async (invitationId) => {
  try {
    store.setState({ isFetchingInvitation: true })
    const { data: invitation } = await get(`/voice-invitations/${invitationId}`)
    store.setState({ isFetchingInvitation: false })
    return invitation
  } catch (error) {
    throw error
  }
}

export const acceptVoiceInvitation = async (invitationId, params) => {
  try {
    store.setState({ isAcceptingInvitation: true })
    const { data: currentUser } = await post(`/voice-invitations/${invitationId}`, params)
    store.setState({ currentUser, isAcceptingInvitation: false })
    return currentUser
  } catch (error) {
    store.setState({ error, isAcceptingInvitation: false })
  }
}

export const fetchUserInvitation = async (invitationId) => {
  try {
    store.setState({ isFetchingInvitation: true })
    const { data: invitation } = await get(`/user-invitations/${invitationId}`)
    store.setState({ isFetchingInvitation: false })
    return invitation
  } catch (error) {
    store.setState({ error, isFetchingInvitation: false })
    throw error
  }
}

export const acceptUserInvitation = async (invitationId, params) => {
  try {
    store.setState({ isAcceptingInvitation: true })
    const { data: currentUser } = await post(`/user-invitations/${invitationId}`, params)
    store.setState({ currentUser, isAcceptingInvitation: false })
    return currentUser
  } catch (error) {
    store.setState({ error, isAcceptingInvitation: false })
  }
}

export const fetchLinkInvitation = async (slug) => {
  try {
    store.setState({ isFetchingLinkInvitation: true })
    const { data: link } = await get(`/link-invitations/${slug}`)
    store.setState({ isFetchingLinkInvitation: false })
    return link
  } catch (error) {
    store.setState({ isFetchingLinkInvitation: false })
    throw error
  }
}

export const acceptLinkInvitation = async (slug, params) => {
  try {
    store.setState({ isAcceptingInvitation: true })
    const { data: currentUser } = await post(`/link-invitations/${slug}`, params)
    store.setState({ currentUser, isAcceptingInvitation: false })
    return currentUser
  } catch (error) {
    store.setState({ error, isAcceptingInvitation: false })
  }
}
