import { h, Fragment } from 'preact'
import { useRef, useState, useEffect, useMemo } from 'preact/hooks'
import { InlineBlock, Block, Row, Col } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'
import { InputInfo } from './InputInfo'
import { InputError } from './InputError'
import { BorderBottom } from './BorderBottom'
import { Highlight } from './highlights'
import { Outline } from './Outline'
import { IconButton } from './IconButton'
import { CancelIcon } from './icons'
import './TextField.css'
import { ProgressCircular } from './ProgressCircular'
import { getUniqueId } from './get-unique-id'
import { SpacerHorizontal } from './spacers'
import { CharacterCounter } from './CharacterCounter'

import { useSuggestIndex } from './use-suggest-index'
import { Suggest } from './Suggest'
import { useCursorPosition } from './use-cursor-position'

/**
 *
 */
const isOkDate = (str) => {
  const s = str ? str.trim() : ''
  // Full year, full month, and maybe a day
  // 31 days – Jan, March, May, July, August, October, December
  if (/^\d{4}-(01|03|05|07|08|10|12)-([0-3]|[0-2][0-9]|3[0-1])?$/.test(s)) {
    return true
  }
  // Full year, full month, and maybe a day
  // 30 days – April, June, Sept, Nov
  if (/^\d{4}-(04|06|09|11)-([0-3]|[0-2][0-9]|30)?$/.test(s)) {
    return true
  }
  // Full year, full month, and maybe a day
  // 28 or 29 days – Feb
  if (/^\d{4}-02-([0-2]|[0-2][0-9])?$/.test(s)) {
    // Verify leap year if a full date has been set
    if (/^\d{4}-02-\d{2}$/.test(s)) {
      return !isNaN(Date.parse(s))
    }
    return true
  }
  // Full year, and maybe a month
  if (/^\d{4}-(0|1|[0][1-9]|10|11|12)?$/.test(s)) {
    return true
  }
  // Started typing a year
  if (/^\d{1,4}$/.test(s)) {
    return true
  }
  if (s === '') {
    return true
  }
  return false
}

export const TextField = ({
  type = 'text',
  simple,
  outline,
  icon: Icon,
  loading,
  label,
  name,
  placeholder,
  value,
  required,
  readonly = false,
  disabled,
  min,
  max,
  autofocus,
  autocomplete,
  autocapitalize,
  autocorrect,
  textAlign,
  maxWidth,
  infoText,
  errorText,
  clearable,
  showCharacterCounter = false,
  maxCharacters,
  large,
  onKeyDown,
  onInput,
  onChange,
  onFocus,
  onBlur,
  suggest,
  suggestMode = 'below',
  onSuggestSelect,
  onSuggestClose,
  inputRef,
  props,
  ...style
}) => {
  const inputContainerEl = useRef()
  const inputEl = useRef()
  const [innerValue, setInnerValue] = useState(value || '')
  const [focus, setFocus] = useState(false)
  const [hover, setHover] = useState(false)
  const [ariaDescribedById, setAriaDescribedById] = useState()
  const inputId = useMemo(() => getUniqueId('bui-textfield-input'), [])

  const suggestIndex = useSuggestIndex(inputEl.current, suggest, onSuggestSelect, onSuggestClose)
  const caretPos =
    suggest && suggestMode === 'at-caret' && suggest.length > 0 && inputEl.current
      ? inputEl.current.selectionStart
      : undefined
  const cursorPosition =
    suggestMode === 'at-caret' ? useCursorPosition(inputEl.current, caretPos) : undefined

  useEffect(() => {
    if (infoText || errorText) {
      setAriaDescribedById(getUniqueId('bui-aria-describedby'))
    }
  }, [infoText, errorText])

  const setRef = (el) => {
    if (inputRef) {
      if (typeof inputRef === 'function') {
        inputRef(el)
      } else {
        inputRef.current = el
      }
    }
    inputEl.current = el
  }

  // :( It is not possible to style the shadow dom placeholder
  // for input type=date in Edge/Firefox. Special styles are needed
  // in Edge/Firefox.
  const isEdge = useMemo(
    () => window.navigator.userAgent.indexOf('Edge') > -1,
    [window.navigator.userAgent]
  )
  const isFirefox = useMemo(
    () => window.navigator.userAgent.indexOf('Firefox') > -1,
    [window.navigator.userAgent]
  )

  useEffect(() => {
    if (autofocus && inputEl.current) {
      setFocus(true)
      inputEl.current.focus()
    }
  }, [autofocus])

  useEffect(() => {
    setInnerValue(value)
  }, [value])

  const supportsHover = useMedia(['(hover: hover)'], [true], false)
  const clickable = !disabled

  const handleMouseEnter = (e) => setHover(true)

  const handleMouseLeave = (e) => setHover(false)

  const handleKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  const handleInput = (e) => {
    // Verify date string if browser doesn’t support input of type date (Safari etc.)
    if (type === 'date' && inputEl.current && inputEl.current.type !== 'date') {
      const textAdded = !innerValue || e.target.value.length > innerValue.length
      if (!isOkDate(e.target.value)) {
        e.target.value = innerValue || ''
        return
      }
      if (textAdded) {
        if (/^\d{4}-\d{2}$/.test(e.target.value)) {
          e.target.value = `${e.target.value}-`
        } else if (/^\d{4}$/.test(e.target.value)) {
          e.target.value = `${e.target.value}-`
        }
      }
    }

    setInnerValue(e.target.value)
    if (onInput) {
      onInput(e.target.value, e)
    }
  }

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value, e)
    }
  }

  const handleClear = () => {
    setInnerValue('')
    if (onChange) {
      onChange('')
    }
    if (inputEl.current) {
      inputEl.current.focus()
    }
  }

  const handleFocus = (e) => {
    setFocus(true)
    if (onFocus) {
      onFocus(e)
    }
  }

  const handleBlur = (e) => {
    setFocus(false)
    if (onBlur) {
      onBlur(e)
    }
  }

  useEffect(() => {
    if (inputEl.current) {
      const el = inputEl.current
      el.addEventListener('input', handleInput)
      el.addEventListener('change', handleChange)
      return () => {
        el.removeEventListener('input', handleInput)
        el.removeEventListener('change', handleChange)
      }
    }
  }, [inputEl.current])

  useEffect(() => {
    if (inputEl.current && clickable) {
      const el = inputEl.current
      el.addEventListener('focus', handleFocus)
      el.addEventListener('blur', handleBlur)
      return () => {
        el.removeEventListener('focus', handleFocus)
        el.removeEventListener('blur', handleBlur)
      }
    }
  }, [inputEl.current, clickable])

  useEffect(() => {
    if (inputEl.current && onKeyDown) {
      const el = inputEl.current
      el.addEventListener('keydown', handleKeyDown)
      return () => {
        el.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [inputEl.current, onKeyDown])

  const borderRadius = simple ? '0' : '3px'

  const numCharacters = innerValue ? innerValue.length : 0

  // Set date placeholder if browser doesn’t support
  // input of type date (Safari etc.)
  if (type === 'date' && inputEl.current && inputEl.current.type !== 'date') {
    placeholder = 'YYYY-MM-DD'
    clearable = true
  }

  const clearActive = clearable && innerValue && !disabled && !readonly

  // Decide input padding left/right and input width
  let paddingLeftPx = simple ? 0 : 15
  let paddingRightPx = simple ? (clearable ? 40 : 0) : clearable ? 40 : 15
  if (Icon) {
    paddingLeftPx += 29
  }
  const paddingLeft = `${paddingLeftPx}px`
  const paddingRight = `${paddingRightPx}px`

  let suggestPosition
  if (cursorPosition && inputContainerEl.current) {
    const bounds = inputContainerEl.current.getBoundingClientRect()
    suggestPosition = {
      x: bounds.x + cursorPosition.x,
      y: bounds.y + cursorPosition.y + 15
    }
  }

  let classes = ['bui']
  if (focus || value) {
    classes.push('bui-showdate')
  }

  return (
    <InlineBlock
      position="relative"
      component="label"
      class={classes.join(' ')}
      maxWidth={maxWidth ? maxWidth : 'none'}
      cursor={!disabled && !readonly ? 'text' : 'default'}
      props={props}
      outline={focus && readonly ? '3px solid var(--accent)' : undefined}
      {...style}
      opacity={disabled ? 0.33 : 1}
    >
      <Block
        position="relative"
        overflow={focus ? 'hidden' : undefined}
        borderRadius={borderRadius}
        backgroundColor={
          !simple && !outline && !readonly ? 'var(--container-background)' : undefined
        }
        background={simple || outline ? 'none' : undefined}
        props={{
          ref: inputContainerEl,
          onMouseEnter: clickable && supportsHover ? handleMouseEnter : undefined,
          onMouseLeave: clickable && supportsHover ? handleMouseLeave : undefined
        }}
        height={large ? '70px' : '54px'}
      >
        <Block height="100%">
          {!outline && !simple && (hover || focus) && !readonly && (
            <Highlight borderRadius={borderRadius} opacity="0.15" />
          )}
          {outline && !readonly && (
            <Block>
              <Outline time={0} borderRadius={borderRadius} />
              {(hover || focus) && (
                <Outline borderRadius={borderRadius} color="var(--on-surface-light)" />
              )}
              {focus && <Outline borderRadius={borderRadius} color="var(--accent)" width="3px" />}
            </Block>
          )}
          {label && (
            <Block
              position="absolute"
              top="18px"
              left={!simple ? (Icon ? '44px' : '15px') : Icon ? '29px' : '0'}
              fontSize="16px"
              lineHeight="18px"
              cursor={clickable ? 'text' : undefined}
              color={
                focus
                  ? 'var(--accent-text)'
                  : errorText
                  ? 'var(--error-text)'
                  : 'var(--on-surface-light)'
              }
              transition="transform 0.18s cubic-bezier(0, 0, .2, 1)"
              transform-origin="top left"
              transform={
                focus ||
                (innerValue !== undefined && innerValue !== '') ||
                (type === 'date' && (isEdge || isFirefox))
                  ? 'scale(.75) translateY(-16px)'
                  : undefined
              }
              component="label"
              zIndex="1"
              props={{ for: inputId }}
            >
              {label}
            </Block>
          )}
          <Block
            component="input"
            position="relative"
            boxSizing="border-box"
            width="100%"
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            height="100%"
            paddingTop={label ? '15px' : 0}
            paddingBottom="0"
            borderRadius={borderRadius}
            textAlign={textAlign}
            fontSize={large ? '20px' : '16px'}
            lineHeight={large ? '24px' : '18px'}
            color="var(--on-surface)"
            cursor={!clickable ? 'default' : undefined}
            placeholderColor={errorText ? 'var(--error-text)' : 'var(--on-surface-light)'}
            outline="none"
            border="none"
            background="none"
            props={{
              id: inputId,
              name: name || label,
              ref: setRef,
              type,
              value: innerValue,
              placeholder: focus || !label ? placeholder : undefined,
              disabled,
              tabIndex: clickable ? 0 : -1,
              readonly,
              autocomplete,
              autocapitalize,
              autocorrect,
              min,
              max,
              'aria-describedby': ariaDescribedById,
              'aria-required': required ? 'true' : undefined
            }}
          />
          {!loading && Icon && (
            <Icon
              position="absolute"
              top="50%"
              left={simple ? 0 : '15px'}
              marginTop="-12px"
              fill={
                focus ? 'var(--accent)' : errorText ? 'var(--error)' : 'var(--on-surface-light)'
              }
            />
          )}
          {loading && (
            <Block position="absolute" top="50%" left={simple ? 0 : '15px'} marginTop="-12px">
              <ProgressCircular
                size={24}
                color={focus ? 'var(--accent)' : 'var(--on-surface-light)'}
              />
            </Block>
          )}
          {clearActive && (
            <IconButton
              icon={CancelIcon}
              color={focus ? 'var(--accent)' : 'var(--on-surface-light)'}
              position="absolute"
              top="50%"
              right="0"
              marginTop="-20px"
              onClick={handleClear}
              tooltipText="Clear"
            />
          )}
          {simple && !disabled && !readonly && (
            <BorderBottom
              color={errorText ? 'var(--error)' : 'var(--container-outline)'}
              opacity="1"
            />
          )}
          {!outline && !disabled && !readonly && (
            <Fragment>
              <BorderBottom
                color={errorText ? 'var(--error)' : 'var(--container-outline)'}
                opacity={hover ? '1' : '0'}
              />
              <BorderBottom width="3px" color={'var(--accent)'} opacity={focus ? '1' : '0'} />
            </Fragment>
          )}
        </Block>
      </Block>
      {suggest && suggest.length > 0 && (
        <Suggest
          items={suggest}
          selectedIndex={suggestIndex}
          onSelect={onSuggestSelect}
          visible={focus}
          onClose={onSuggestClose}
          positionElement={inputContainerEl.current}
          position={suggestPosition}
        />
      )}
      <Row padding={simple ? '0' : '0 15px'}>
        <Block flex="1">
          {!errorText && (
            <InputInfo large={large} id={ariaDescribedById}>
              {infoText}
            </InputInfo>
          )}
          {errorText && (
            <InputError large={large} id={ariaDescribedById}>
              {errorText}
            </InputError>
          )}
        </Block>
        {(infoText || errorText) && showCharacterCounter && <SpacerHorizontal />}
        {showCharacterCounter && (
          <CharacterCounter large={large} count={numCharacters} max={maxCharacters} />
        )}
      </Row>
    </InlineBlock>
  )
}
