import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { Button } from '@sodra/bongo-ui'

import { patch } from '../api'
import { setError } from '../actions'

export const ApproveButton = ({ user, onChange }) => {
  const [hover, setHover] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const toggleApproved = async () => {
    setIsUpdating(true)
    try {
      await patch(`/users/${user.id}`, { isApproved: !user.isApproved })
      user.isApproved = !user.isApproved
      if (onChange) {
        onChange()
      }
    } catch (err) {
      setError(err.message)
    } finally {
      setIsUpdating(false)
    }
  }

  if (user.isApproved) {
    return (
      <Block
        props={{
          onMouseEnter: () => setHover(true),
          onMouseLeave: () => setHover(false)
        }}
      >
        <Button
          small
          contained={!hover}
          width="120px"
          loading={isUpdating}
          onClick={toggleApproved}
          color={hover ? 'var(--error)' : undefined}
        >
          {hover ? 'Unapprove' : 'Approved'}
        </Button>
      </Block>
    )
  }

  return (
    <Block
      props={{
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false)
      }}
    >
      <Button
        small
        width="120px"
        loading={isUpdating}
        color={hover ? 'var(--ok)' : 'var(--on-surface-light)'}
        onClick={toggleApproved}
      >
        {hover ? 'Approve' : 'Not approved'}
      </Button>
    </Block>
  )
}
