import { h } from 'preact'
import { Row, Block } from 'jsxstyle/preact'
import { SpacerVertical, SpacerHorizontal } from './spacers'
import { ErrorIcon } from './icons'

export const InputError = ({ children, id, large = false }) => {
  if (!children || children.length === 0) {
    return null
  }
  return (
    <Block class="bui">
      <SpacerVertical tiny />
      <Row
        fontSize={large ? '16px' : '14px'}
        lineHeight={large ? '19px' : '16px'}
        color="var(--error-text)"
        alignItems="flex-start"
        props={{
          id
        }}
      >
        <ErrorIcon fill="var(--error)" size="20" margin-top="-2px" />
        <SpacerHorizontal tiny />
        <Block flex="1" props={{ role: 'alert' }}>
          {children}
        </Block>
      </Row>
    </Block>
  )
}
