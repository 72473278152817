import { useEffect, useMemo, useState } from "preact/hooks"

type Props = {
    stream: MediaStream | null,
    onStateChange: (state: string | null) => void,
    onDone: (data: Blob) => void
}

export const useMediaRecorder = ({stream, onStateChange, onDone} : Props) => {
    const [mediaRecorderState, setMediaRecorderState] = useState<string | null>(null)
    const mediaRecorder = useMemo<MediaRecorder | null>(() => {
      if (stream) {
        const options = {}
        const mediaRecorder = new MediaRecorder(stream, options)
        const updateState = () => {
          setMediaRecorderState(mediaRecorder.state)
        }
        mediaRecorder.addEventListener('start', updateState)
        mediaRecorder.addEventListener('stop', updateState)
        mediaRecorder.addEventListener('pause', updateState)
        mediaRecorder.addEventListener('resume', updateState)
        return mediaRecorder
      }
  
      return null
    }, [stream])

    useEffect(() => {
        onStateChange(mediaRecorderState)
    }, [mediaRecorderState])
  
    useEffect(() => {
      if (!mediaRecorder) return
      if (mediaRecorderState === 'recording') {
        const chunks: Blob[] = []
        const onDataAvailable = (e: BlobEvent) => {
          chunks.push(e.data)
        }
        mediaRecorder.addEventListener('dataavailable', onDataAvailable)
        return () => {
          if (chunks.length > 0) {
            const blob = new Blob(chunks, { type: 'audio/webm; codecs=opus' })
            onDone(blob)
          }
        }
      }
    }, [mediaRecorderState, mediaRecorder])
  
    return mediaRecorder
  }