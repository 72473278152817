import { h } from 'preact'
import { Link } from '@sodra/bongo-ui'
import { Block, Col, Row } from 'jsxstyle/preact'
import { useMatchMedia } from 'jsxstyle'
import { routeTo } from '@sodra/prutt'
import Logo from './Logo'

const LandingPage = ({ children }) => {
  const isNarrow = useMatchMedia('(max-width: 600px)')

  if (isNarrow) {
    return (
      <Block>
        <Block
          padding="40px 20px"
          margin="0 0 10px"
          width="100%"
          borderBottom="1px solid var(--container-outline)"
        >
          {children}
        </Block>
        <Row alignItems="center" justifyContent="space-between" padding="5px 25px" width="100%">
          <Logo onClick={() => routeTo('/')} height="22px" />
          <Block>
            <Link
              to="https://www.speechless.games"
              target="_blank"
              title="Speechless"
              color="var(--on-surface)"
              underline
              text
            >
              About
            </Link>{' '}
            ·{' '}
            <Link onClick={() => routeTo(`/`)} color="var(--on-surface)" underline text>
              Sign in
            </Link>
          </Block>
        </Row>
      </Block>
    )
  } else {
    return (
      <Col>
        <Col alignItems="center" justifyContent="center" minHeight="calc(100vh - 60px)">
          <Block
            padding="40px 30px"
            margin="20px 20px 10px 20px"
            borderRadius="6px"
            width="calc(100% - 40px)"
            maxWidth="500px"
            border="1px solid var(--container-outline)"
          >
            {children}
          </Block>
          <Row
            alignItems="center"
            justifyContent="space-between"
            padding="5px 25px"
            width="calc(100% - 30px)"
            maxWidth="500px"
          >
            <Logo
              headFillColor="var(--on-surface-light)"
              textColor="var(--on-surface-light)"
              zipperHandleColor="var(--on-surface-light)"
              onClick={() => routeTo('/')}
              height="22px"
            />
            <Block>
              <Link
                to="https://www.speechless.games"
                target="_blank"
                title="Speechless"
                color="var(--on-surface)"
                underline
                text
              >
                About
              </Link>{' '}
              ·{' '}
              <Link onClick={() => routeTo(`/`)} color="var(--on-surface)" underline text>
                Sign in
              </Link>
            </Block>
          </Row>
        </Col>
      </Col>
    )
  }
}

export default LandingPage
