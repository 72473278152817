import { h, Fragment } from 'preact'
import { useRef, useState } from 'preact/hooks'
import { Row, Block, InlineBlock } from 'jsxstyle/preact'
import { SpacerHorizontal } from './spacers'
import { CheckmarkIcon } from './icons'
import { RemoveIcon } from './icons'
import { Ripples } from './Ripples'
import { CircularHighlight } from './highlights'
import { useMedia } from '@sodra/use-media'
import { Tooltip } from './Tooltip'

export const Checkbox = ({
  label,
  checked,
  indeterminate,
  disabled,
  onChange,
  sideSpacing = 15,
  roundedCorners = true,
  tooltipText,
  size = 50,
  props,
  ...style
}) => {
  const borderRadius = roundedCorners ? '3px' : undefined

  const elem = useRef()

  const [focus, setFocus] = useState(false)
  const [hover, setHover] = useState(false)

  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const handleClick = (e) => {
    if (onChange) {
      e.stopPropagation()
      onChange(!checked, e)
      setFocus(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault()
      if (onChange) {
        onChange(!checked, e)
      }
    }
  }

  const handleFocus = () => setFocus(true)

  const handleBlur = () => setFocus(false)

  const handleMouseEnter = () => setHover(true)

  const handleMouseLeave = () => setHover(false)

  return (
    <InlineBlock
      class="bui-show-keyboard-focus-inside"
      fontSize="16px"
      lineHeight="18px"
      color="var(--on-surface)"
      cursor={!disabled ? 'pointer' : 'not-allowed'}
      position="relative"
      userSelect="none"
      WebkitUserSelect="none"
      WebkitTapHighlightColor="rgba(0, 0, 0, 0)"
      outline="none"
      padding={`0 ${sideSpacing}px`}
      margin={`0 -${sideSpacing}px`}
      borderRadius={!label ? `${Math.round(size / 2)}px` : undefined}
      {...style}
      props={{
        ref: elem,
        onClick: !disabled ? handleClick : null,
        onFocus: !disabled ? handleFocus : null,
        onBlur: !disabled ? handleBlur : null,
        onKeyDown: !disabled ? handleKeyDown : null,
        onMouseEnter: !disabled && supportsHover ? handleMouseEnter : null,
        onMouseLeave: !disabled && supportsHover ? handleMouseLeave : null,
        tabIndex: !disabled ? 0 : -1,
        role: 'checkbox',
        'aria-checked': checked,
        ...props
      }}
    >
      <Row alignItems="center" height={`${size}px`}>
        <Block position="relative">
          {(hover || focus) && <CircularHighlight size={size} opacity={0.15} />}
          <Block
            height="20px"
            width="20px"
            borderRadius="3px"
            position="relative"
            opacity={disabled ? 0.33 : 1}
            transition="opacity 0.18s cubic-bezier(0, 0, .2, 1)"
          >
            <Block
              boxSizing="border-box"
              position="absolute"
              width="100%"
              height="100%"
              border="2px solid var(--selection-control)"
            />
            <Block
              position="absolute"
              width="100%"
              height="100%"
              transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
              opacity={indeterminate ? 1 : 0}
              backgroundColor={'var(--selection-control-selected)'}
            >
              <RemoveIcon fill="var(--on-selection-control-selected)" size={20} marginTop="-1px" />
            </Block>
            <Block
              position="absolute"
              width="100%"
              height="100%"
              transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
              opacity={checked ? 1 : 0}
              backgroundColor={'var(--selection-control-selected)'}
            >
              <CheckmarkIcon
                fill="var(--on-selection-control-selected)"
                size={20}
                marginTop="-1px"
              />
            </Block>
          </Block>
        </Block>
        {label && (
          <>
            <SpacerHorizontal />
            <InlineBlock flex="1" opacity={disabled ? 0.2 : 1}>
              {label}
            </InlineBlock>
          </>
        )}
        <Ripples
          borderRadius={!label ? `${Math.round(size / 2)}px` : borderRadius}
          disabled={disabled}
        />
      </Row>
      {tooltipText && <Tooltip visible={focus || hover} generator={elem} text={tooltipText} />}
    </InlineBlock>
  )
}
