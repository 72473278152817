export const setItem = (key, value) => {
  if (value !== undefined) {
    localStorage.setItem(key, JSON.stringify(value))
  } else {
    localStorage.removeItem(key)
  }
}

export const getItem = (key, defaultValue) => {
  if(!defaultValue) defaultValue = null
  const value = localStorage.getItem(key)
  return value ? JSON.parse(value) : defaultValue
}

export const removeItem = (key) => localStorage.removeItem(key)
