import { h } from 'preact'
import { connect } from 'unistore/preact'
import { goBack } from '@sodra/prutt'
import { BasicDialog } from '@sodra/bongo-ui'

import { deleteVoiceInvitation } from '../../actions'
import { useState } from 'preact/hooks'

const DeleteVoiceInvitation = ({ agency, invitationId, isDeletingInvitation }) => {
  const [invitation] = useState(
    agency.voiceInvitations.find((invitation) => invitation.id === invitationId)
  )

  const onClose = () => goBack(`/agency/${agency.id}`)

  const handleSubmit = async () => {
    if (await deleteVoiceInvitation(invitationId)) {
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Delete invitation"
      onClose={onClose}
      primaryActionText="Delete"
      primaryActionLoading={isDeletingInvitation}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Delete invitation to {invitation.email}?
    </BasicDialog>
  )
}

export default connect('agency, isDeletingInvitation')(DeleteVoiceInvitation)
