import { h } from 'preact'
import { Block } from 'jsxstyle/preact'

export const BorderBottom = ({ style = 'solid', color = 'black', width = '1px', opacity }) => (
  <Block
    position="absolute"
    bottom="0"
    left="0"
    right="0"
    borderBottomStyle={style}
    borderBottomWidth={width}
    borderBottomColor={color}
    opacity={opacity}
    transition="opacity .18s cubic-bezier(0,0,.2,1)"
    willChange="opacity"
  />
)
