import { Button, PlayIcon, StopIcon } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'
import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { AudioBufferVisualizer } from '../AudioRecorder/AudioBufferVisualizer'

async function audioBufferFromURI(uri: string): Promise<AudioBuffer> {
  const response = await fetch(uri)
  const arrayBuffer = await response.arrayBuffer()
  return new AudioContext().decodeAudioData(arrayBuffer)
}

function useAudioBufferFromURI(uri: string, enabled: boolean = true) {
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null)

  useEffect(() => {
    if (!enabled) return

    audioBufferFromURI(uri).then((buffer) => setAudioBuffer(buffer))
  }, [enabled, uri])

  return audioBuffer
}

type AudioButtonProps = {
  uri: string
  label?: string
  buttonProps?: any
}

export function AudioButton({ uri, label, buttonProps }: AudioButtonProps) {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [time, setTime] = useState(0)

  const audioBuffer = useAudioBufferFromURI(uri, label ? false : true)

  const togglePlaying = (isPlaying: boolean) => {
    if (!audioRef.current) return false
    if (isPlaying) {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
    }
    if (!isPlaying) {
      audioRef.current.play()
    }
    return !isPlaying
  }

  function updateTime() {
    if (!audioRef.current) return
    setTime(audioRef.current.currentTime)
  }
  const handleEnded = () => {
    setIsPlaying(false)
    setTime(0)
  }
  const handlePlay = () => {
    setIsPlaying(true)
  }

  useEffect(() => {
    if (audioRef.current && isPlaying) {
      let done = false
      const update = () => {
        if (!done && audioRef.current) {
          setTime(audioRef.current.currentTime)
          requestAnimationFrame(update)
        }
      }
      requestAnimationFrame(update)
      return () => {
        done = true
        setTime(0)
      }
    }
  }, [audioRef.current, isPlaying])

  return (
    <Block position="relative">
      <Button
        icon={isPlaying ? StopIcon : PlayIcon}
        onClick={() => setIsPlaying(togglePlaying)}
        {...(buttonProps ?? {})}
      >
        {label && label}
        {audioBuffer && (
          <AudioBufferVisualizer
            audioBuffer={audioBuffer}
            height="20px"
            currentTime={time}
            color="--accent"
            progressColor="--accent-light"
          />
        )}
        {!audioBuffer && <Block width="100%" />}
      </Button>
      <audio
        onPlay={handlePlay}
        onEnded={handleEnded}
        onPause={handleEnded}
        onTimeUpdate={updateTime}
        ref={audioRef}
        style={{ display: 'none' }}
        controls
        src={uri}
      />
    </Block>
  )
}
