import { useEffect, useState } from 'preact/hooks'
import { useDebounce } from './useDebounce'

type Options = {
  audioBuffer: AudioBuffer | null
  start: number
  end: number
  onChange: (trimmed: AudioBuffer | null) => void
  delay?: number
}

export function useTrimAudioBufferDebounced({
  audioBuffer,
  start,
  end,
  onChange,
  delay = 200
}: Options) {
  const audioBufferDebounced = useDebounce(audioBuffer, delay)
  const startDebounced = useDebounce(start, delay)
  const endDebounced = useDebounce(end, delay)

  useEffect(() => {
    if (!audioBuffer) return onChange(null)
    if (end - start <= 0) return onChange(null)

    async function trimAudio() {
      if (!audioBuffer) return

      const audioContext = new OfflineAudioContext({
        numberOfChannels: audioBuffer.numberOfChannels,
        length: (end - start) * audioBuffer.sampleRate,
        sampleRate: audioBuffer.sampleRate
      })
      const source = audioContext.createBufferSource()
      source.buffer = audioBuffer

      source.connect(audioContext.destination)
      source.start(0, start, end - start)

      const trimmed = await audioContext.startRendering()
      onChange(trimmed)
    }

    trimAudio()
  }, [audioBufferDebounced, startDebounced, endDebounced])
}
