import { h } from 'preact'
import { useEffect } from 'preact/hooks'

export const useSubmitOnEnter = (inputEl, onSubmit, enabled = true) => {
  useEffect(() => {
    if (!enabled || !inputEl || !onSubmit) {
      return
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        if (!(e.shiftKey || e.altKey) && enabled) {
          e.preventDefault()
          onSubmit(inputEl.value)
        }
      }
    }

    inputEl.addEventListener('keydown', handleKeyDown)
    return () => {
      inputEl.removeEventListener('keydown', handleKeyDown)
    }
  }, [inputEl, onSubmit, enabled])
}
