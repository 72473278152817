import { h, Fragment } from 'preact'
import { Block, Row } from 'jsxstyle/preact'
import { SpacerVertical, SpacerHorizontal } from './spacers'
import { ErrorIcon } from './icons'

export const CharacterCounter = ({ count = 0, max, large = false }) => {
  const text = max > 0 ? `${count} / ${max}` : `${count}`
  const tooMany = max > 0 && count > max
  return (
    <Block>
      <SpacerVertical tiny />
      <Row
        class="bui"
        color={tooMany ? 'var(--error-text)' : 'var(--on-surface-light)'}
        fontSize={large ? '16px' : '14px'}
        lineHeight={large ? '19px' : '16px'}
      >
        {tooMany && (
          <Fragment>
            <ErrorIcon fill="var(--error)" size="20" margin-top="-2px" />
            <SpacerHorizontal tiny />
          </Fragment>
        )}
        {text}
      </Row>
    </Block>
  )
}
