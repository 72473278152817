import { post } from '../api'
import { store } from '../store'

const getCropperParams = ({ url, crop }) => {
  const params = {
    url
  }
  if (crop) {
    const c = []
    c.push(crop.minX)
    c.push(crop.minY)
    c.push(crop.maxX - crop.minX)
    c.push(crop.maxY - crop.minY)
    params.crop = c.join(',')
  }
  return params
}

const fetchImage = async (url) => {
  const res = await fetch(url)
  if (!res.ok) {
    throw new Error(`Unable to fetch image: ${url}`)
  }

  const contentType = res.headers.get('Content-Type')
  const buffer = await res.arrayBuffer()

  return {
    contentType,
    buffer
  }
}

export const uploadCroppedImage = async ({ url, crop }) => {
  try {
    const params = getCropperParams({ url, crop })
    const encodedParams = btoa(JSON.stringify(params))
    const imageUrl = `${process.env.CROPPER_URL}/${encodedParams}`

    const { contentType, buffer } = await fetchImage(imageUrl)

    const {
      data: { signedUrl, uri }
    } = await post('/sign-url', { prefix: 'images', contentType: contentType })
    await fetch(signedUrl, {
      method: 'PUT',
      body: buffer,
      headers: {
        'Content-Type': contentType
      }
    })
    return uri
  } catch (error) {
    store.setState({ error })
  }
}
