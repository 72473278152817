import { h } from 'preact'
import { Block } from 'jsxstyle/preact'

const numberFormat = new Intl.NumberFormat()
export const DiffRenderer = (data) => {
  if (!data || data === 0) {
    return ''
  }

  let color = 'var(--on-surface)'
  if (data > 0) {
    color = 'var(--ok-text)'
  } else if (data < 0) {
    color = 'var(--error-text)'
  }

  const str = []
  if (data > 0) {
    str.push('+')
  }
  str.push(numberFormat.format(Number(data * 100).toFixed(0)))

  return <Block color={color}>{str.join('')}</Block>
}
