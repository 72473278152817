import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

let first = history.location.key

export const routeTo = (path, options = {}) => {
  if (typeof options === 'boolean') {
    options = {
      replace: options,
    }
  }

  if (options.replace) {
    history.replace(path, { from: history.location.pathname })
  } else {
    history.push(path, { from: history.location.pathname })
  }

  if (options.resetWindowScroll) {
    window.scroll(0, 0)
  }
}

export const goBack = (fallback = '/') => {
  if (history.location.key !== first) {
    history.goBack()
  } else {
    routeTo(fallback, true)
    first = history.location.key
  }
}
