import { h } from 'preact'
import { connect } from 'unistore/preact'

import { Button, SpacerHorizontal } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { Block, Row } from 'jsxstyle/preact'

import { logout } from '../actions'
import { usePopupMenu } from './use-popup-menu'
import Logo from './Logo'
import Avatar from './Avatar'
import { GlobalLoadingIndicator } from './GlobalLoadingIndicator'
import { useMedia } from '@sodra/use-media'
import { useHasScroll } from './use-has-scroll'

const Header = ({ currentUser, setDefaultAgencyId }) => {
  const isNarrow = useMedia(['(max-width: 600px)'], [true], false)
  const hasScroll = useHasScroll()

  const popupOptions = []

  if (currentUser.isSuperadmin || currentUser.agencies.length > 0) {
    popupOptions.push({ label: 'My account', onClick: () => routeTo('/account') })
  }

  if (currentUser.agencies && currentUser.agencies.length > 1) {
    popupOptions.push({ divider: true })
    for (let agency of currentUser.agencies) {
      popupOptions.push({
        label: (
          <Row alignItems="center">
            <Avatar size="20" src={agency.picture} name={agency.name} />
            <SpacerHorizontal small />
            <Block maxWidth="150px" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
              {agency.name}
            </Block>
          </Row>
        ),
        onClick: () => {
          setDefaultAgencyId(agency.id)
          routeTo(`/agency/${agency.id}`)
        }
      })
    }
    popupOptions.push({ divider: true })
  }

  popupOptions.push({ label: 'Log out', onClick: logout })

  const { PopupMenu, showMenu } = usePopupMenu({
    options: popupOptions
  })

  if (isNarrow) {
    return (
      <Row
        position="fixed"
        top="0"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        padding="10px 5px"
        background={hasScroll ? 'var(--surface-floating)' : 'var(--surface)'}
        boxShadow={hasScroll ? '1px 3px 3px rgba(0, 0, 0, 0.1)' : undefined}
        zIndex="2"
      >
        {currentUser.isSuperadmin && (
          <Row padding="0 5px">
            <Button
              color="var(--on-surface)"
              onClick={() => routeTo('/voices', { resetWindowScroll: true })}
            >
              Voices
            </Button>
            <Button color="var(--on-surface)" onClick={() => routeTo('/agencies')}>
              Agencies
            </Button>
          </Row>
        )}
        <Block flex={1} />
        <Row gap="10px">
          <GlobalLoadingIndicator />
          <Avatar onClick={showMenu} src={currentUser.picture} name={currentUser.name} size="40" />
        </Row>
        {PopupMenu}
      </Row>
    )
  }

  return (
    <Row
      position="fixed"
      top="0"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      padding="10px 20px"
      background={hasScroll ? 'var(--surface-floating)' : 'var(--surface)'}
      boxShadow={hasScroll ? '1px 3px 3px rgba(0, 0, 0, 0.1)' : undefined}
      zIndex="2"
    >
      <Row gap={50} alignItems="center">
        <Logo onClick={() => routeTo('/')} />
        {currentUser.isSuperadmin && !isNarrow && (
          <Row>
            <Button color="var(--on-surface)" onClick={() => routeTo('/voices')}>
              Voices
            </Button>
            <Button color="var(--on-surface)" onClick={() => routeTo('/agencies')}>
              Agencies
            </Button>
          </Row>
        )}
      </Row>
      <Block flex={1} />
      <Row gap="10px">
        <GlobalLoadingIndicator />
        <Avatar onClick={showMenu} src={currentUser.picture} name={currentUser.name} size="40" />
      </Row>
      {PopupMenu}
    </Row>
  )
}

export default connect('currentUser')(Header)
