import { useEffect, useState } from 'preact/hooks'
// import { useAnimationFrame } from "./useAnimationFrame"

type useAudioBufferPlayerOptions = {
  audioBuffer: AudioBuffer | null
  start?: number
  end?: number
  registerKeyboardEvent?: boolean
}

type PlayStates = 'stopped' | 'paused' | 'playing'

export function useAudioBufferPlayer({
  audioBuffer,
  start = 0,
  end,
  registerKeyboardEvent = false
}: useAudioBufferPlayerOptions) {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null)

  const [currentTime, setCurrentTime] = useState(0)
  const [state, setState] = useState<PlayStates>('stopped')

  useEffect(() => {
    if (audioBuffer) {
      setCurrentTime(0)
    }
  }, [audioBuffer])

  useEffect(() => {
    if (!registerKeyboardEvent) return
    if (audioBuffer) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === ' ') {
          e.preventDefault()
          e.stopPropagation()
          if (state === 'stopped') {
            play()
          } else if (state === 'paused') {
            resume()
          } else if (state === 'playing') {
            pause()
          }
        }
      }

      window.addEventListener('keydown', handleKeyDown)
      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [audioBuffer, state, start, end, registerKeyboardEvent])

  useEffect(() => {
    stop()
  }, [start, end])

  const play = () => {
    if (!audioBuffer) return
    setState('playing')

    const audioContext = new AudioContext()
    setAudioContext(audioContext)

    if (!end) end = audioBuffer.duration

    const duration = end - start
    const currentTime = audioContext.currentTime

    const gainNode = audioContext.createGain()
    gainNode.gain.setValueAtTime(0, currentTime)
    gainNode.gain.setTargetAtTime(1, currentTime + 0.03, 0.03)
    gainNode.gain.setTargetAtTime(0, currentTime + duration - 0.1, 0.03)

    const source = audioContext.createBufferSource()
    source.buffer = audioBuffer
    source.connect(gainNode).connect(audioContext.destination)

    source.onended = () => {
      stop()
    }

    source.start(0, start, duration)
  }

  const pause = () => {
    setState('paused')
    if (!audioContext) return
    audioContext.suspend()
  }

  const resume = () => {
    setState('playing')
    if (!audioContext) return
    audioContext.resume()
  }

  const stop = () => {
    setState('stopped')
    if (audioContext) {
      try {
        audioContext.close()
      } catch (err) {
        // Do nothing.
      }
    }
    setAudioContext(null)
  }

  useEffect(() => {
    if (state === 'playing') {
      if (audioContext) {
        let done = false
        const updateTime = () => {
          if (!done) {
            setCurrentTime(audioContext.currentTime)
            requestAnimationFrame(updateTime)
          }
        }
        requestAnimationFrame(updateTime)
        return () => {
          done = true
        }
      }
    } else if (state === 'stopped') {
      setCurrentTime(0)
    }
  }, [state, audioContext])

  return {
    currentTime,
    state,
    play,
    pause,
    stop
  }
}
