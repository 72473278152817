import { h } from 'preact'
import { connect } from 'unistore/preact'
import { CloseIcon, Dialog } from '@sodra/bongo-ui'

const LargeDialog = ({ isNarrow, onClose, ...props }) => {
  return (
    <Dialog
      dismissable={false}
      titleIcon={CloseIcon}
      onTitleIconClick={onClose}
      onClose={onClose}
      large={!isNarrow}
      full={isNarrow}
      flexGrowContent
      disableAutofocus
      {...props}
    />
  )
}

export default connect('isNarrow')(LargeDialog)
