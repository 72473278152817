import { h } from 'preact'
import { useRef, useState, useEffect } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'

export const Highlight = ({
  color = 'var(--surface-highlight)',
  borderRadius = '0',
  opacity = 0.25,
  time = 200,
  onDone
}) => {
  const [animating, setAnimating] = useState(false)
  const isMounted = useRef(true)

  useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        setAnimating(true)
        if (onDone) {
          setTimeout(onDone, time)
        }
      }
    }, 0)
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <Block
      position="absolute"
      top="0"
      left="0"
      marginLeft="0"
      marginTop="0"
      width="100%"
      height="100%"
      borderRadius={borderRadius}
      backgroundColor={color}
      opacity={animating ? opacity : '0'}
      transition={`opacity ${time}ms ease-in-out`}
      willChange="opacity"
      pointerEvents="none"
    ></Block>
  )
}

/* 100% width/height by default
   size - the size in pixels
   */
export const CircularHighlight = ({
  size,
  color = 'var(--surface-highlight)',
  opacity = 0.25,
  time = 200,
  onDone
}) => {
  const [animating, setAnimating] = useState(false)
  const isMounted = useRef(true)

  useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        setAnimating(true)
        if (onDone) {
          setTimeout(onDone, time)
        }
      }
    }, 0)
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <Block
      position="absolute"
      top={size ? '50%' : '0'}
      left={size ? '50%' : '0'}
      marginLeft={size ? `-${size / 2}px` : '0'}
      marginTop={size ? `-${size / 2}px` : '0'}
      width={size ? `${size}px` : '100%'}
      height={size ? `${size}px` : '100%'}
      borderRadius="50%"
      backgroundColor={color}
      opacity={animating ? opacity : '0'}
      transition={`opacity ${time}ms ease-in-out`}
      willChange="opacity"
      pointerEvents="none"
    ></Block>
  )
}
