import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Col } from 'jsxstyle/preact'

import { EditIcon, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { createAgencyVoice } from '../../actions'

import LargeDialog from '../LargeDialog'
import Avatar from '../Avatar'
import PictureDialog from '../PictureDialog'

const CreateVoice = ({ agency, isCreatingVoice }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [picture, setPicture] = useState('')

  const onClose = () => goBack(`/agency/${agency.id}`)

  const handleSave = async () => {
    const user = await createAgencyVoice({
      name,
      email,
      picture
    })
    if (user) {
      routeTo(`/user/${user.id}`, true)
    }
  }

  const handleUpdatePicture = (url) => {
    setPicture(url)
    goBack(`/agency/${agency.id}/create-voice`)
  }

  return (
    <LargeDialog
      title={'New voice'}
      onClose={onClose}
      actions={[
        { text: 'Save', type: 'contained', loading: isCreatingVoice, onClick: handleSave },
        { text: 'Cancel', onClick: onClose }
      ]}
    >
      <Col>
        <Col alignItems="center">
          <Avatar
            actionIcon={EditIcon}
            onActionClick={() => routeTo(`/agency/${agency.id}/create-voice/picture`)}
            size="150"
            src={picture}
            name={name}
          />
        </Col>
        <SpacerVertical />
        <TextField
          autocomplete="off"
          width="100%"
          name="new-name"
          label="Name"
          value={name}
          onInput={setName}
          onChange={setName}
        />
        <SpacerVertical />
        <TextField
          autocomplete="off"
          width="100%"
          name="new-email"
          label="Email (optional)"
          value={email}
          onInput={setEmail}
          onChange={setEmail}
        />
      </Col>
      <Route
        path="/agency/:agencyId/create-voice/picture"
        render={() => (
          <PictureDialog
            title="User picture"
            url={picture}
            name={name}
            onUpdate={handleUpdatePicture}
            onClose={() => goBack(`/agency/${agency.id}/create-voice`)}
          />
        )}
      />
    </LargeDialog>
  )
}

export default connect('agency, isCreatingVoice')(CreateVoice)
