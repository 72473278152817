import { h } from 'preact'
import { Divider, FloatingSheet, List, ListItem } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'

const PopupMenu = ({ position, positionElement, data, options, onClose }) => {
  return (
    <Block
      position="fixed"
      top="0"
      left="0"
      bottom="0"
      right="0"
      zIndex="5"
      props={{ onClick: onClose }}
    >
      <FloatingSheet position={position} positionElement={positionElement}>
        <List>
          {options.map((option) => {
            const { divider, label, onClick } = option
            if (divider) {
              return <Divider />
            }
            return (
              <ListItem
                onClick={() => {
                  onClick(data)
                  onClose()
                }}
                text={label}
              />
            )
          })}
        </List>
      </FloatingSheet>
    </Block>
  )
}

export default PopupMenu
