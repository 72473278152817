import { h } from 'preact'
import { connect } from 'unistore/preact'

import Breadcrumbs from '../Breadcrumbs'

const AgencyBreadcrumbs = ({ agency, currentUser, ...props }) => {
  if (currentUser.isSuperadmin) {
    return (
      <Breadcrumbs
        links={[{ label: 'Agencies', path: `/agencies` }, { label: agency.name }]}
        {...props}
      />
    )
  }
}

export default connect('currentUser')(AgencyBreadcrumbs)
