import { h } from 'preact'
import { Block, Col } from 'jsxstyle/preact'
import { SpacerVertical } from '@sodra/bongo-ui'
import { useMedia } from '@sodra/use-media'
import { LineContainer } from './LineContainer'

export type DialogueProps = {
  lines: DialogueLine[]
  location: string
  description: string
  activeCharacterId: string
  availableAudio: AudioBuffer | null
  activeLine: string | null
  setActiveLine: (lineId: string | null) => void
}

export function Dialogue({
  lines,
  location,
  description,
  activeCharacterId,
  availableAudio,
  activeLine,
  setActiveLine
}: DialogueProps) {
  const isNarrow = useMedia(['(max-width: 800px)'], [true], false)

  return (
    <Col fontSize="16px">
      <Block fontFamily="monospace" textTransform="uppercase" component="strong">
        {location}
      </Block>
      <SpacerVertical />
      <Block fontFamily="monospace" maxWidth="60ch">
        {description}
      </Block>
      <SpacerVertical large />

      {lines.map((line, i) => {
        const isActiveCharacter = line.line?.character?.id === activeCharacterId
        return (
          <LineContainer
            line={line}
            mode={isActiveCharacter ? 'record' : 'read'}
            availableAudio={availableAudio}
            selected={isActiveCharacter && line.line.id === activeLine}
            onSelect={() => setActiveLine(line.line.id === activeLine ? null : line.line.id)}
          />
        )
      })}
    </Col>
  )
}
