export const roleOptions = [
  { value: 'admin', label: 'Admin' },
  { value: 'editor', label: 'Editor' },
  { value: 'viewer', label: 'Viewer' }
]

export const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' }
]

export const yearOfBirthOptions = []
for (let i = 0; i < 100; i++) {
  const year = 2022 - i
  yearOfBirthOptions.push({ value: year, label: year })
}

export const accentOptions = [
  'UK Geordie',
  'UK Irish',
  'UK Lancashire',
  'UK Liverpool',
  'UK London',
  'UK Manchester',
  'UK Midlands',
  'UK Neutral',
  'UK North Eastern',
  'UK Northern',
  'UK RP',
  'UK Scottish',
  'UK Welsh',
  'UK West Country',
  'UK Yorkshire',
  'US California',
  'US East Coast',
  'US General american',
  'US Hawaii And Pidgin',
  'US High Tider',
  'US Maine Accent',
  'US Midwestern American',
  'US New England',
  'US New Orleans',
  'US Pacific Northwest',
  'US Southern American',
  'Abkhaz',
  'African',
  'Afrikaans',
  'Arabic',
  'Australian',
  'Catalan',
  'Canadian',
  'Chinese - Simplified',
  'Chinese - Traditional',
  'Cypriot/Cyprus',
  'Danish',
  'Dutch',
  'Etnicity',
  'Finnish',
  'French',
  'Galician',
  'German',
  'Greek',
  'Icelandic',
  'Italian',
  'Japanese',
  'Korean',
  'New Zeeland',
  'Norwegian',
  'Polish',
  'Portugese - Brazilian',
  'Portuguese',
  'Russian',
  'Spanish - Mexican',
  'Spanish',
  'Swedish',
  'Thai'
].map((accent) => ({ value: accent, label: accent + ' accent' }))

export const ageSoundsLikeOptions = [
  { value: 'child', label: 'Child (5-12)' },
  { value: 'teen', label: 'Teen (13-17)' },
  { value: 'young-adult', label: 'Young adult (18-34)' },
  { value: 'middle-aged', label: 'Middle aged (35-59)' },
  { value: 'senior', label: 'Senior (60+)' }
]

export const genderSoundsLikeOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'neither', label: 'Neither' }
]

const formatOption = (options, value) => {
  for (let option of options) {
    if (option.value === value) {
      return option.label
    }
  }
  return value
}

export const formatRole = (value) => formatOption(roleOptions, value)
export const formatGender = (value) => formatOption(genderOptions, value)
export const formatYearOfBirth = (value) => formatOption(yearOfBirthOptions, value)
export const formatAccent = (value) => formatOption(accentOptions, value)
export const formatSoundsLike = (value) => formatOption(soundsLikeOptions, value)
