import { Fragment, h } from 'preact'
import { goBack } from '@sodra/prutt'
import { BasicDialog, Link, List, ListItem, SearchList, SearchListItem, SpacerVertical } from '@sodra/bongo-ui'
import { useState } from 'preact/hooks'
// import { getCodes, getName } from '../../../languages'
import { connect } from 'unistore/preact'
import { useMedia } from '@sodra/use-media'
import { getCodes, getName } from './languages'

type DefaultLanguagesProps = {
    setShowAllLanguages: (value: boolean) => void
    handleSelectLanguage: (Language: string) => void
}
function DefaultLanguages({ setShowAllLanguages, handleSelectLanguage }: DefaultLanguagesProps) {
    const defaultLanguages = [
        "en",
        "de",
        "es",
        "it",
        "ja",
        "ko",
        "pl",
        "pt",
        "ar",
        "ru",
        "zh",
    ]

    return (
        <Fragment>
            <List>
                {defaultLanguages.map(language => {
                    return (
                        <ListItem
                            text={getName(language)}
                            onClick={() => handleSelectLanguage(language)}
                        />
                    )
                })}
            </List>
            <Link onClick={setShowAllLanguages} marginLeft="20px" marginTop="12px">More...</Link>
        </Fragment>
    )
}

type SearchableLanguagesProps = {
    onSelectLanguage: (language: string) => void
}

function SearchableLanguages({ onSelectLanguage }: SearchableLanguagesProps) {
    const languages = getCodes()
    const [query, setQuery] = useState('')

    const options = languages
        ? languages
            .map((code) => ({
                value: code,
                label: getName(code)
            }))
            .filter((option) => option.label.toLowerCase().startsWith(query.toLowerCase()))
            .sort((a, b) => a.label.localeCompare(b.label))
        : []

    return (
        <SearchList
            label="Languages"
            placeholder="Search language"
            width="100%"
            query={query}
            onQueryUpdate={setQuery}
            onSelect={onSelectLanguage}
        >
            {options.map(({ value, label }) => {
                return (
                    <SearchListItem
                        value={value}
                        text={label}
                        onClick={() => onSelectLanguage(value)}
                    />
                )
            })}
        </SearchList>
    )
}

type Props = {
    onSelect: (Language: string) => void
    onClose: () => void
}

export function SelectLanguage({ onSelect, onClose }: Props) {
    const [showAllLanguages, setShowAllLanguages] = useState(false)
    const isNarrow = useMedia(['(max-width: 800px)'], [true], false)

    const handleSelectLanguage = (language: string) => {
        onSelect(language)
        onClose()
    }

    return (
        <BasicDialog
            full={isNarrow}
            large={!isNarrow}
            title="Select language"
            action1Text="Cancel"
            onAction1Click={onClose}
            onClose={onClose}
        >
            {showAllLanguages ? (
                <SearchableLanguages
                    onSelectLanguage={handleSelectLanguage}
                />
            ) : (
                <DefaultLanguages
                    setShowAllLanguages={() => setShowAllLanguages(true)}
                    handleSelectLanguage={handleSelectLanguage}
                />
            )}
            <SpacerVertical large />
        </BasicDialog>
    )
}

