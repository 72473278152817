import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Col } from 'jsxstyle/preact'

import { EditIcon, SpacerVertical, TextArea, TextField } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { createAgency } from '../../actions'

import LargeDialog from '../LargeDialog'
import Avatar from '../Avatar'
import PictureDialog from '../PictureDialog'

const AddAgency = ({ isCreatingAgency }) => {
  const [name, setName] = useState('')
  const [legalName, setLegalName] = useState('')
  const [picture, setPicture] = useState('')
  const [address, setAddress] = useState('')

  const onClose = () => goBack(`/agencies`)

  const handleSave = async () => {
    const agency = await createAgency({ name, legalName, picture, address })
    if (agency) {
      routeTo(`/agency/${agency.id}`, true)
    }
  }

  const handleUpdatePicture = (url) => {
    setPicture(url)
    goBack('/agencies/add')
  }

  return (
    <LargeDialog
      title="New agency"
      actions={[
        { text: 'Save', type: 'contained', loading: isCreatingAgency, onClick: handleSave },
        { text: 'Cancel', onClick: onClose }
      ]}
      onClose={onClose}
    >
      <Col alignItems="center">
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() => routeTo(`/agencies/add/picture`)}
          size="150"
          src={picture}
          name={name}
        />
        <SpacerVertical />
        <TextField width="100%" label="Name" value={name} onInput={setName} onChange={setName} />
        <SpacerVertical />
        <TextField
          width="100%"
          label="Legal name"
          value={legalName}
          onInput={setLegalName}
          onChange={setLegalName}
        />
        <SpacerVertical />
        <TextArea width="100%" label="Address" value={address} onInput={setAddress} />
        <SpacerVertical />
      </Col>
      <Route
        path="/agencies/add/picture"
        render={() => (
          <PictureDialog
            title="Agency picture"
            url={picture}
            name={name}
            onUpdate={handleUpdatePicture}
            onClose={() => goBack(`/agencies/add`)}
          />
        )}
      />
    </LargeDialog>
  )
}

export default connect('isCreatingAgency')(AddAgency)
