import { h } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { InlineBlock, Block } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'
import { getHighlightColor } from './highlight-colors'
import { CircularHighlight } from './highlights'
import { useKeyPressedHighlight } from './use-key-pressed-highlight'
import { Ripples } from './Ripples'
import { ProgressCircular } from './ProgressCircular'
import { Tooltip } from './Tooltip'
import {
  getBackgroundColor,
  getColor,
  getBorder,
  getIconSize,
  getLoadingSize
} from './button-styles'

export const IconButton = ({
  name = 'button',
  icon: Icon,
  iconRotation,
  onClick,
  outlined,
  contained,
  error,
  type = 'button',
  small,
  disabled,
  focusable = true,
  onFocus,
  onBlur,
  color: propColor,
  borderColor: propBorderColor,
  backgroundColor: propBackgroundColor,
  highlightColor: propHighlightColor,
  loading,
  tooltipText,
  delayClick = false,
  props,
  ...style
}) => {
  const iconButton = useRef()

  useEffect(() => {
    if (disabled) {
      setFocus(false)
      setHover(false)
    }
  }, [disabled])

  const buttonType = outlined ? 'outlined' : contained ? 'contained' : error ? 'error' : 'text'

  const borderRadius = '50%'
  const color = propColor || getColor(buttonType)
  const backgroundColor = propBackgroundColor || getBackgroundColor(buttonType)
  const highlightColor = propHighlightColor || getHighlightColor(buttonType)
  const borderColor = propBorderColor || color
  const border = getBorder({ type: buttonType, color: borderColor, disabled, loading })

  const [focus, setFocus] = useState(false)
  const [hover, setHover] = useState(false)
  const [keyPressedHighlights, addKeyPressedHighlight] = useKeyPressedHighlight({
    color: highlightColor,
    borderRadius
  })

  const supportsHover = useMedia(['(hover: hover)'], [true], false)
  const clickable = !disabled && !loading

  const handleClick = (e) => {
    e.stopPropagation()
    setTimeout(
      () => {
        setFocus(false)
        if (onClick) {
          onClick({ type: 'pointer', x: e.clientX, y: e.clientY, target: e.target })
        }
      },
      delayClick ? 180 : 0
    )
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault()
      e.stopPropagation()
      addKeyPressedHighlight()
      onClick && onClick({ type: 'keyboard', target: iconButton.current })
    }
  }

  const handleMouseOver = (e) => setHover(true)

  const handleMouseOut = (e) => setHover(false)

  const handleFocus = (e) => {
    setFocus(true)
    if (onFocus) {
      onFocus()
    }
  }

  const handleBlur = (e) => {
    setFocus(false)
    if (onBlur) {
      onBlur()
    }
  }

  return (
    <InlineBlock
      class="bui-show-keyboard-focus"
      component="button"
      height={small ? '30px' : '40px'}
      width={small ? '30px' : '40px'}
      padding="0"
      borderRadius={borderRadius}
      position="relative"
      outline="none"
      userSelect="none"
      WebkitUserSelect="none"
      WebkitTapHighlightColor="rgba(0, 0, 0, 0)"
      background={!backgroundColor ? 'none' : undefined}
      backgroundColor={backgroundColor || 'none'}
      color={color}
      border={border}
      cursor={clickable ? 'pointer' : undefined}
      props={{
        ref: iconButton,
        name,
        'aria-label': name,
        type,
        onClick: clickable ? handleClick : null,
        onKeyDown: clickable ? handleKeyDown : null,
        onMouseOver: clickable && supportsHover ? handleMouseOver : null,
        onMouseOut: clickable && supportsHover ? handleMouseOut : null,
        onFocus: clickable ? handleFocus : null,
        onBlur: clickable ? handleBlur : null,
        tabIndex: focusable && clickable ? 0 : -1,
        ...props
      }}
      {...style}
    >
      {focus && <CircularHighlight color={highlightColor} />}
      {hover && <CircularHighlight color={highlightColor} />}
      {keyPressedHighlights}
      <Ripples borderRadius={borderRadius} color={highlightColor} disabled={!clickable} />
      <Block display="flex" alignItems="center" justifyContent="center">
        {loading && <ProgressCircular color={color} size={getLoadingSize({ small })} />}
        {!loading && (
          <Icon
            fill={color}
            size={getIconSize({ small })}
            opacity={disabled ? 0.33 : 1}
            flexShrink="0"
            transform={iconRotation ? `rotate(${iconRotation}deg)` : undefined}
            transformOrigin={iconRotation ? '50% 50%' : undefined}
            transition="transform .36s cubic-bezier(0, 0, .2, 1)"
          />
        )}
      </Block>
      {tooltipText && (
        <Tooltip visible={focus || hover} generator={iconButton} text={tooltipText} />
      )}
    </InlineBlock>
  )
}
