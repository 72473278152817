import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Button, Checkbox, Form, H1, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { Block, Col, Row } from 'jsxstyle/preact'

import { login } from '../actions'
import LandingPage from './LandingPage'
import { routeTo } from '@sodra/prutt'

const NotLoggedIn = ({ isLoggingIn }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const [emailError, setEmailError] = useState()
  const [passwordError, setPasswordError] = useState()

  const handleSubmit = async () => {
    let error = false
    if (!email || email.trim() === '') {
      setEmailError('Please enter email')
      error = true
    } else {
      setEmailError()
    }
    if (!password || password.trim() === '') {
      setPasswordError('Please enter password')
      error = true
    } else {
      setPasswordError()
    }
    if (error) return

    login({ email, password })
  }

  return (
    <LandingPage action="register">
      <H1 size="6">Sign in</H1>
      <SpacerVertical />
      <Form onSubmit={handleSubmit}>
        <Col alignItems="center">
          <TextField
            width="100%"
            type="email"
            label="Email"
            value={email}
            onInput={setEmail}
            onChange={setEmail}
            autocomplete="username"
            errorText={emailError}
          />
          <SpacerVertical />
          <TextField
            width="100%"
            type={!showPassword ? 'password' : 'text'}
            label="Password"
            value={password}
            onInput={setPassword}
            onChange={setPassword}
            autocomplete="current-password"
            errorText={passwordError}
          />
          <SpacerVertical small />
          <Checkbox
            label="Show password"
            checked={showPassword}
            onChange={setShowPassword}
            width="100%"
          />
          <SpacerVertical small />
          <Row alignItems="center" justifyContent="space-between" width="100%">
            <Button contained type="submit" loading={isLoggingIn}>
              Sign in
            </Button>
            <Button onClick={() => routeTo('/forgot-password')}>Forgot password</Button>
          </Row>
        </Col>
      </Form>
    </LandingPage>
  )
}

export default connect('isLoggingIn, error')(NotLoggedIn)
