import { h } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { getPopupPosition } from './popup-position'
import { getBounds } from './bounds'

let numTooltips = 0
let closeCurrentTooltip

export const Tooltip = ({ text, visible, generator }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [position, setPosition] = useState()
  const [showing, setShowing] = useState(false)
  const tooltip = useRef()

  useEffect(() => {
    if (visible) {
      if (numTooltips === 0) {
        const timeout = setTimeout(() => setIsVisible(true), 500)
        return () => clearTimeout(timeout)
      } else {
        setIsVisible(true)
      }
    } else {
      setShowing(false)
      setTimeout(() => {
        setIsVisible(false)
      }, 150)
    }
  }, [visible])

  useEffect(() => {
    if (isVisible) {
      numTooltips += 1
      if (closeCurrentTooltip) {
        closeCurrentTooltip()
      }
      closeCurrentTooltip = () => setIsVisible(false)
      setPosition(getPosition())
      setShowing(true)
    } else {
      numTooltips = Math.max(0, numTooltips - 1)
      if (numTooltips === 0) {
        closeCurrentTooltip = undefined
      }
      setShowing(false)
    }
  }, [isVisible])

  const getPosition = () => {
    const generatorBounds = generator && getBounds(generator.current)
    const tooltipBounds = tooltip.current.getBoundingClientRect()
    return getPopupPosition({
      generatorBounds,
      popupBounds: tooltipBounds,
      placements: [
        'bottom-center',
        'bottom-right',
        'bottom-left',
        'top-center',
        'top-right',
        'top-left'
      ],
      offset: 10,
      padding: 5
    })
  }

  if (!isVisible) {
    return null
  }
  return (
    <Block
      class="bui"
      position="fixed"
      top={position && position.top}
      left={position && position.left}
      fontSize="14px"
      lineHeight="16px"
      textAlign="left"
      padding="8px 12px"
      maxWidth="200px"
      borderRadius="3px"
      background="var(--inverted-surface)"
      color="var(--on-inverted-surface)"
      zIndex="4"
      opacity={position && showing ? 1 : 0}
      transition="opacity .18s cubic-bezier(0,0,.2,1)"
      whillChange="opacity"
      pointerEvents="none"
      props={{
        ref: tooltip
      }}
    >
      {text}
    </Block>
  )
}
