import { useEffect } from 'preact/hooks'

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = { 37: 1, 38: 1, 39: 1, 40: 1 }

export const useDisableScroll = (selector, enabled = true) => {
  useEffect(() => {
    if (enabled) {
      const preventDefault = (e) => {
        const element = selector && e.target.closest(selector)
        if (!element || element.offsetHeight === element.scrollHeight) {
          e.preventDefault()
        }
      }

      const preventDefaultForScrollKeys = (e) => {
        if (keys[e.keyCode]) {
          preventDefault(e)
          return false
        }
      }

      window.addEventListener('wheel', preventDefault, { passive: false })
      window.addEventListener('touchmove', preventDefault, { passive: false })
      document.addEventListener('keydown', preventDefaultForScrollKeys)
      return () => {
        window.removeEventListener('wheel', preventDefault, { passive: false })
        window.removeEventListener('touchmove', preventDefault, { passive: false })
        document.removeEventListener('keydown', preventDefaultForScrollKeys)
      }
    }
  }, [enabled])
}
