import { Fragment, h } from 'preact'
import { Block, Row } from 'jsxstyle/preact'
import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Divider,
  IconButton,
  List,
  ListItem,
  MoreIcon,
  SpacerHorizontal,
  SpacerVertical
} from '@sodra/bongo-ui'

import { usePopupMenu } from './use-popup-menu'

import Section from './Section'
import Avatar from './Avatar'

const Pager = ({ page, pages, setPage }) => {
  return (
    <Row alignItems="center" justifyContent="flex-end">
      <IconButton icon={ChevronLeftIcon} disabled={page === 1} onClick={() => setPage(page - 1)} />{' '}
      Page {page} of {pages}{' '}
      <IconButton
        icon={ChevronRightIcon}
        disabled={page === pages}
        onClick={() => setPage(page + 1)}
      />
    </Row>
  )
}

const Listing = ({ items, popupMenuOptions, onClick, page, pages, setPage }) => {
  const { PopupMenu, showMenu } = usePopupMenu({ options: popupMenuOptions })

  return (
    <Fragment>
      {items.length > 0 && (
        <List>
          {items.map((item) => {
            return (
              <ListItem
                visual={item.icon || <Avatar size="40" src={item.picture} name={item.title} />}
                text={item.text || item.title}
                secondaryText={item.description}
                actionIcon={popupMenuOptions ? MoreIcon : undefined}
                onActionClick={(e) => showMenu(e, item.data)}
                onClick={() => onClick(item.data)}
              />
            )
          })}
        </List>
      )}
      {pages > 1 && (
        <Fragment>
          <SpacerVertical />
          <Pager page={page} pages={pages} setPage={setPage} />
        </Fragment>
      )}
      {PopupMenu}
    </Fragment>
  )
}

export default Listing
