import createStore from 'unistore'

const initialState = {
  isNarrow: window.innerWidth < 800,
  player: { status: 'stopped' }
}

export const store = createStore(initialState)

export const resetStore = () => store.setState(initialState, true)
