import { goBack } from '@sodra/prutt'
import { Block, Col, Inline, Row } from 'jsxstyle/preact'
import { h } from 'preact'
import { BackArrowIcon, CheckmarkIcon, Dialog, IconButton, P } from '@sodra/bongo-ui'
import { Dialogue } from './Dialogue'
import { Recorder } from './Recorder'
import { useEffect, useState } from 'preact/hooks'
import { useSaveMutation } from './mutationHooks'
import { getDialogueStats } from './getDialogueStats'
import { TooltipWrapper } from './TooltipWrapper'
import { useHasScroll } from '../use-has-scroll'

type SessionStatsProps = {
  lineAudio: LineAudio[]
  dialogue: Dialogue
  activeCharacterId: string
}
function SessionStats({ lineAudio, dialogue, activeCharacterId }: SessionStatsProps) {
  const stats = getDialogueStats(lineAudio, activeCharacterId, dialogue)

  return (
    <TooltipWrapper
      tooltipText={
        stats.recorded
          ? `All lines recorded`
          : `${stats.recordedLines} out of ${stats.totalLines} lines recorded`
      }
    >
      <Row alignItems="center" gap="8px">
        {stats.recorded && <CheckmarkIcon fill="var(--accent)" />}
        Lines recorded:
        <Inline
          userSelect="none"
          letterSpacing="10px"
          color={stats.recorded ? 'var(--accent)' : 'var(--on-surface-ligth)'}
        >
          {stats.recordedLines}/{stats.totalLines}
        </Inline>
      </Row>
    </TooltipWrapper>
  )
}

type Props = {
  user: User
  lineAudio: LineAudio[]
  dialogue: Dialogue
  activeCharacterId: string
}

export function Session({ user, lineAudio, dialogue, activeCharacterId }: Props) {
  const hasScroll = useHasScroll()

  const [activeLine, setActiveLine] = useState<string | null>(null)
  const [trimmedBuffer, setTrimmedBuffer] = useState<AudioBuffer | null>(null)
  const saveMutation = useSaveMutation()

  const [confirmAssign, setConfirmAssign] = useState<boolean>(false)

  const lines = dialogue.lines.map((line) => {
    for (let la of lineAudio) {
      if (la.lineId === line.line.id) {
        return {
          ...line,
          recordedAudioUri: la.uri,
          recordedAudio: la.audioBuffer
        }
      }
    }
    return line
  })

  const handleAssign = () => {
    if (!activeLine || !trimmedBuffer) return

    const e = document.querySelector(`#${activeLine}`)

    //
    // Scroll selected line into view before assigning audio
    //
    if (e) {
      let scrollNeeded = false
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) {
              scrollNeeded = true
              e.scrollIntoView({ behavior: 'smooth', block: 'center' })
            } else {
              const line = lines.find((line) => line.line.id === activeLine)
              if (line?.recordedAudioUri) {
                setTimeout(() => setConfirmAssign(true), scrollNeeded ? 720 : 0)
              } else {
                assignAudio()
              }
              observer.unobserve(e)
            }
          })
        },
        {
          rootMargin: '-70px 0px -200px 0px',
          threshold: 0.75
        }
      )
      observer.observe(e)
    }
  }

  const assignAudio = () => {
    saveMutation.mutate({
      line: { id: activeLine, line: '' },
      recordedAudio: trimmedBuffer
    })
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'a') {
        handleAssign()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [activeLine, trimmedBuffer, handleAssign])

  const activateFirstSelectableLine = () => {
    const firstSelectableLine = dialogue.lines.find(
      (line) => line.line?.character?.id === activeCharacterId
    )
    if (firstSelectableLine) {
      setActiveLine(firstSelectableLine.line.id)
    }
  }

  return (
    <Col minHeight="80vh" justifyContent="center">
      <Block
        zIndex={1}
        position="fixed"
        top="0"
        right="0"
        left="0"
        padding="10px"
        background={hasScroll ? 'var(--surface-floating)' : 'var(--surface)'}
        boxShadow={hasScroll ? '1px 3px 3px rgba(0, 0, 0, 0.1)' : undefined}
      >
        <Row
          maxWidth="800px"
          margin="0 auto"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton
            icon={BackArrowIcon}
            color="var(--on-surface)"
            onClick={() => goBack(`/user/${user.id}`)}
          />
          <SessionStats
            lineAudio={lineAudio}
            dialogue={dialogue}
            activeCharacterId={activeCharacterId}
          />
        </Row>
      </Block>
      <Block margin="0 auto" maxWidth="800px" padding="140px 20px 180px 20px">
        <Dialogue
          availableAudio={trimmedBuffer}
          activeCharacterId={activeCharacterId}
          location={dialogue.location}
          description={dialogue.description}
          lines={lines}
          activeLine={activeLine}
          setActiveLine={setActiveLine}
        />
      </Block>
      <Block
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        background="var(--surface-floating)"
        boxShadow="rgba(0, 0, 0, 0.1) -1px -3px 3px;"
      >
        <Block maxWidth="800px" margin="0 auto">
          <Recorder
            onChange={(trimmed) => setTrimmedBuffer(trimmed)}
            onAssign={activeLine ? handleAssign : undefined}
          />
        </Block>
      </Block>
      {confirmAssign && (
        <Dialog
          title="Replace audio?"
          actions={[
            {
              text: 'Replace',
              onClick: () => {
                assignAudio()
                setConfirmAssign(false)
              }
            },
            { text: 'Cancel', onClick: () => setConfirmAssign(false) }
          ]}
          onClose={() => setConfirmAssign(false)}
        >
          <P>Do you want to replace the existing audio?</P>
        </Dialog>
      )}
    </Col>
  )
}
