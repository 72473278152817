import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { getItem, removeItem, setItem } from './storage'

export const useLocalStorageState = (name, initialValue) => {
  const [state, setState] = useState(getItem(name, initialValue))

  useEffect(() => {
    if (state !== undefined) {
      setItem(name, state)
    } else {
      removeItem(name)
    }
  }, [state])

  return [state, setState]
}
