import { CheckmarkIcon, ProgressCircular } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'
import { h } from 'preact'

export function OnboardingProgress({ progress }) {
    if (!progress) {
      return null
    }
  
    return (
      <Block position="relative">
        {progress >= 1 && (
          <Block position="absolute" marginLeft="4px" marginTop="1px">
            <CheckmarkIcon size={16} fill="var(--accent)" />
          </Block>
        )}
        <ProgressCircular value={progress} size={24} />
      </Block>
    )
  }