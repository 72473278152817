import { h, Fragment } from 'preact'
import { useRef, useState, useEffect } from 'preact/hooks'
import { InlineBlock, Block } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'
import { DownIcon } from './icons'
import { InputInfo } from './InputInfo'
import { InputError } from './InputError'
import { BorderBottom } from './BorderBottom'
import { Highlight } from './highlights'
import { Outline } from './Outline'
import { getUniqueId } from './get-unique-id'

export const NativeSelect = ({
  simple,
  outline,
  label,
  placeholder,
  value,
  infoText,
  errorText,
  minWidth,
  maxWidth,
  options = [],
  disabled,
  large = false,
  onChange,
  onClick,
  onFocus,
  ...style
}) => {
  const select = useRef()

  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)

  const [ariaDescribedById, setAriaDescribedById] = useState()

  const [selectedOption, setSelectedOption] = useState()

  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === value))
  }, [options, value])

  useEffect(() => {
    if (infoText || errorText) {
      setAriaDescribedById(getUniqueId('bui-aria-describedby'))
    }
  }, [infoText, errorText])

  const handleMouseEnter = (e) => setHover(true)

  const handleMouseLeave = (e) => setHover(false)

  const handleFocus = () => {
    setFocus(true)
    if (onFocus) {
      onFocus()
    }
  }

  const handleBlur = () => {
    setFocus(false)
  }

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const handleClick = (e) => {
    if (!disabled && onClick) {
      onClick(e.target.value)
    }
  }

  const color = disabled
    ? 'var(--on-surface-light)'
    : focus && !label && !outline
    ? 'var(--accent-text)'
    : selectedOption
    ? 'var(--on-surface)'
    : 'var(--on-surface-light)'

  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  return (
    <InlineBlock class="bui" minWidth={minWidth} maxWidth={maxWidth} {...style}>
      <Block component="label" position="relative">
        <Block
          alignItems="center"
          //height={large ? '70px' : '54px'}
          height={large ? '70px' : label ? '54px' : '40px'}
          position="relative"
          borderRadius={simple ? '0' : '3px'}
          backgroundColor={!simple && !outline ? 'var(--container-background)' : 'none'}
          props={{
            onMouseEnter: !disabled && supportsHover ? handleMouseEnter : null,
            onMouseLeave: !disabled && supportsHover ? handleMouseLeave : null
          }}
        >
          {!outline && !simple && (hover || focus) && (
            <Highlight borderRadius={simple ? '0' : '3px'} opacity="0.15" />
          )}
          {outline && (
            <Block>
              <Outline
                time={0}
                color={!disabled ? 'var(--container-outline)' : 'var(--container-outline-lighter)'}
              />
              {(hover || focus) && <Outline color="var(--on-surface-light)" />}
              {focus && <Outline color="var(--accent)" width="3px" />}
            </Block>
          )}

          <Block opacity={disabled ? 0.33 : 1}>
            <Block
              component="select"
              width="100%"
              padding={label ? '14px 34px 12px 15px' : '11px 34px 11px 15px'}
              paddingLeft={simple ? '0' : '15px'}
              paddingTop={label ? '24px' : '11px'}
              height="100%"
              color={color}
              background="none"
              fontFamily="'helvetica neue', arial, sans-serif"
              fontSize={large ? '20px' : '16px'}
              fontStyle={disabled ? 'italic' : 'normal'}
              lineHeight={large ? '24px' : '18px'}
              tabIndex={!disabled ? '0' : '-1'}
              WebkitAppearance="none"
              border="none"
              outline="none"
              opacity={selectedOption ? 1 : 0}
              cursor={!disabled ? 'pointer' : 'not-allowed'}
              props={{
                ref: select,
                value,
                disabled,
                'aria-describedby': ariaDescribedById,
                onFocus: !disabled ? handleFocus : null,
                onBlur: handleBlur,
                onClick: !disabled ? handleClick : null,
                onChange: handleChange
              }}
            >
              {options.map(({ value, label }) => (
                <option value={value}>{label}</option>
              ))}
            </Block>
            <Block position="absolute" right="5px" top="50%" marginTop="-12px" pointerEvents="none">
              <DownIcon fill={!disabled ? 'var(--on-surface)' : 'var(--on-surface-light)'} />
            </Block>
            {placeholder && !label && (
              <Block
                class="bui-placeholder"
                pointerEvents="none"
                position="absolute"
                top="11px"
                left={!simple ? '15px' : '0'}
                fontSize="16px"
                lineHeight="18px"
                fontStyle={disabled ? 'italic' : 'normal'}
                color={focus ? 'var(--accent-text)' : 'var(--on-surface-light)'}
                opacity={!selectedOption ? 1 : 0}
                transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
              >
                <Block>{placeholder}</Block>
              </Block>
            )}
            {label && (
              <Block
                class="bui-label"
                pointerEvents="none"
                position="absolute"
                top="18px"
                left={!simple ? '15px' : '0'}
                fontSize="16px"
                lineHeight="18px"
                fontStyle={disabled ? 'italic' : 'normal'}
                color={
                  focus
                    ? 'var(--accent-text)'
                    : errorText
                    ? 'var(--error-text)'
                    : 'var(--on-surface-light)'
                }
                transition="transform .18s cubic-bezier(0, 0, .2, 1)"
                transform-origin="top left"
                transform={selectedOption ? 'scale(.75) translateY(-16px)' : undefined}
              >
                {label}
              </Block>
            )}
            {simple && (
              <Fragment>
                <BorderBottom color={errorText ? 'var(--error)' : 'var(--container-outline)'} />
                <BorderBottom
                  color={errorText ? 'var(--error)' : 'var(--container-outline)'}
                  opacity={hover ? '1' : '0'}
                />
                <BorderBottom width="3px" color={'var(--accent)'} opacity={focus ? '1' : '0'} />
              </Fragment>
            )}
          </Block>
        </Block>
      </Block>
      <Block padding={simple ? '0' : '0 15px'}>
        {!errorText && <InputInfo id={ariaDescribedById}>{infoText}</InputInfo>}
        {errorText && <InputError id={ariaDescribedById}>{errorText}</InputError>}
      </Block>
    </InlineBlock>
  )
}
