import { h } from 'preact'
import { useRef, useState, useEffect } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'

export const Ripple = ({
  x,
  y,
  width,
  height,
  time = 360,
  color = 'var(--surface-highlight)',
  onDone
}) => {
  const [animating, setAnimating] = useState(false)
  const supportsHover = useMedia(['(hover: hover)'], [true], false)
  const animationDoneTimeout = useRef()

  useEffect(() => {
    setTimeout(() => {
      setAnimating(true)
      animationDoneTimeout.current = setTimeout(() => {
        onDone()
        animationDoneTimeout.current = null
      }, time)
    }, 0)
    return () => {
      if (animationDoneTimeout.current) {
        clearTimeout(animationDoneTimeout.current)
      }
    }
  }, [])

  const startOpacity = supportsHover ? 0.5 : 0.6
  const endOpacity = supportsHover ? 0.1 : 0.2

  const size = Math.max(width, height)
  return (
    <Block
      position="absolute"
      pointerEvents="none"
      top={`${y}px`}
      left={`${x}px`}
      width="2px"
      height="2px"
      borderRadius="50%"
      backgroundColor={color}
      opacity={animating ? endOpacity : startOpacity}
      transform={animating ? `scale(${size})` : 'scale(1)'}
      transition={`opacity ${time}ms ease-in-out, transform ${time}ms cubic-bezier(0, 0, .2, 1)`}
      willChange="opacity, transform"
    ></Block>
  )
}
