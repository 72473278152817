import { h } from 'preact'
import { connect } from 'unistore/preact'
import { Snackbar, SnackbarItem } from '@sodra/bongo-ui'

import { hideSnackbar } from '../actions'

const MySnackbar = ({ snackbar }) => {
  const items = snackbar ? [<SnackbarItem text={snackbar} onClosed={hideSnackbar} />] : []

  return <Snackbar>{items}</Snackbar>
}

export default connect('snackbar')(MySnackbar)
