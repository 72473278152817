import { Block } from 'jsxstyle/preact'
import { h } from 'preact'
import { useRef } from 'preact/hooks'

type Props = {
  zoom: number
  pos: number
  setPos: (pos: number) => void
}

export function ScrollBar({ zoom, pos, setPos }: Props) {
  const scrollWrapperRef = useRef<HTMLDivElement | null>(null)
  const scrollDummyRef = useRef<HTMLDivElement | null>(null)

  return (
    <Block
      class="bui-show-scroll"
      overflowX="scroll"
      maxWidth="100%"
      props={{
        ref: scrollWrapperRef,
        onscroll: (e: any) => {
          if (!scrollWrapperRef.current || !scrollDummyRef.current) return
          const scroll = scrollWrapperRef.current.scrollLeft
          const width = scrollDummyRef.current.clientWidth
          const parentWidth = scrollWrapperRef.current.clientWidth
          setPos((scroll + parentWidth / 2) / width)
        }
      }}
    >
      <Block
        props={{
          ref: scrollDummyRef
        }}
        width={`${zoom * 100}%`}
        lineHeight="1px"
      >
        {/* Zero width space to allow forcing div width */}
        &#8203;
      </Block>
    </Block>
  )
}
