import { h } from 'preact'
import { Block } from 'jsxstyle/preact'
import { SpacerVertical } from './spacers'

export const InputInfo = ({ children, id, large = false }) => {
  if (!children || children.length === 0) {
    return null
  }
  return (
    <Block>
      <SpacerVertical tiny />
      <Block
        class="bui"
        color="var(--on-surface-light)"
        fontSize={large ? '16px' : '14px'}
        lineHeight={large ? '19px' : '16px'}
        props={{
          id
        }}
      >
        {children}
      </Block>
    </Block>
  )
}
