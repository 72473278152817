import { Fragment, h } from 'preact'
import { IconButton } from '@sodra/bongo-ui'
import { usePopupMenu } from './use-popup-menu'

const PopupMenuIconButton = ({ options, ...props }) => {
  const { PopupMenu, showMenu } = usePopupMenu({ options })

  return (
    <Fragment>
      <IconButton {...props} onClick={showMenu} />
      {PopupMenu}
    </Fragment>
  )
}

export default PopupMenuIconButton
