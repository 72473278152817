import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import './KeyPressedHighlight.css'

const KeyPressedHighlight = ({ time = 180, onDone, color, borderRadius }) => {
  useEffect(() => {
    onDone && setTimeout(onDone, time)
  }, [])

  return (
    <Block
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      backgroundColor={color}
      borderRadius={borderRadius}
      animation={`fadeinout ${time}ms cubic-bezier(0, 0, .2, 1) forwards`}
    ></Block>
  )
}

export const useKeyPressedHighlight = ({
  color = 'var(--surface-highlight)',
  borderRadius = '0'
}) => {
  const [highlights, setHighlights] = useState([])

  const addHighlight = () => {
    setHighlights((highlights) => {
      return [
        ...highlights,
        <KeyPressedHighlight onDone={removeHighlight} color={color} borderRadius={borderRadius} />
      ]
    })
  }

  const removeHighlight = () => {
    setHighlights((highlights) => highlights.slice(1, highlights.length))
  }

  return [highlights, addHighlight]
}
