const getBottomCenterBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.left + generatorBounds.width / 2 - popupBounds.width / 2,
  maxX: generatorBounds.left + generatorBounds.width / 2 + popupBounds.width / 2,
  minY: generatorBounds.bottom + offset,
  maxY: generatorBounds.bottom + offset + popupBounds.height
})

const getBottomLeftBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.left + generatorBounds.width / 2 - popupBounds.width - offset,
  maxX: generatorBounds.left + generatorBounds.width / 2 - offset,
  minY: generatorBounds.bottom + offset,
  maxY: generatorBounds.bottom + offset + popupBounds.height
})

const getLeftBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.left + generatorBounds.width / 2 - popupBounds.width - offset,
  maxX: generatorBounds.left + generatorBounds.width / 2 - offset,
  minY: generatorBounds.bottom - generatorBounds.height / 2 - popupBounds.height / 2,
  maxY: generatorBounds.bottom - generatorBounds.height / 2 - popupBounds.height / 2
})

const getBottomAlignLeftBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.left,
  maxX: generatorBounds.left + popupBounds.width,
  minY: generatorBounds.bottom + offset,
  maxY: generatorBounds.bottom + offset + popupBounds.height
})

const getBottomRightBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.left + generatorBounds.width / 2 + offset,
  maxX: generatorBounds.left + generatorBounds.width / 2 + popupBounds.width + offset,
  minY: generatorBounds.bottom + offset,
  maxY: generatorBounds.bottom + offset + popupBounds.height
})

const getRightBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.left + generatorBounds.width / 2 + offset,
  maxX: generatorBounds.left + generatorBounds.width / 2 + popupBounds.width + offset,
  minY: generatorBounds.bottom - generatorBounds.height / 2 - popupBounds.height / 2,
  maxY: generatorBounds.bottom - generatorBounds.height / 2 - popupBounds.height / 2
})

const getBottomAlignRightBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.right - popupBounds.width,
  maxX: generatorBounds.right,
  minY: generatorBounds.bottom + offset,
  maxY: generatorBounds.bottom + offset + popupBounds.height
})

const getTopCenterBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.left + generatorBounds.width / 2 - popupBounds.width / 2,
  maxX: generatorBounds.left + generatorBounds.width / 2 + popupBounds.width / 2,
  minY: generatorBounds.top - offset - popupBounds.height,
  maxY: generatorBounds.top - offset
})

const getTopRightBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.left + generatorBounds.width / 2 + offset,
  maxX: generatorBounds.left + generatorBounds.width / 2 + popupBounds.width + offset,
  minY: generatorBounds.top - offset - popupBounds.height,
  maxY: generatorBounds.top - offset
})

const getTopAlignRightBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.right - popupBounds.width,
  maxX: generatorBounds.right,
  minY: generatorBounds.top - offset - popupBounds.height,
  maxY: generatorBounds.top - offset
})

const getTopLeftBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.left + generatorBounds.width / 2 - popupBounds.width - offset,
  maxX: generatorBounds.left + generatorBounds.width / 2 - offset,
  minY: generatorBounds.top - offset - popupBounds.height,
  maxY: generatorBounds.top - offset
})

const getTopAlignLeftBounds = (generatorBounds, popupBounds, offset = 5) => ({
  minX: generatorBounds.left,
  maxX: generatorBounds.left + popupBounds.width,
  minY: generatorBounds.top - offset - popupBounds.height,
  maxY: generatorBounds.top - offset
})

const getBounds = (generatorBounds, popupBounds, placement, offset) => {
  if (placement === 'bottom-center') {
    return getBottomCenterBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'bottom-right') {
    return getBottomRightBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'right') {
    return getRightBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'bottom-align-right') {
    return getBottomAlignRightBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'bottom-left') {
    return getBottomLeftBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'left') {
    return getLeftBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'bottom-align-left') {
    return getBottomAlignLeftBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'top-center') {
    return getTopCenterBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'top-right') {
    return getTopRightBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'top-align-right') {
    return getTopAlignRightBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'top-left') {
    return getTopLeftBounds(generatorBounds, popupBounds, offset)
  } else if (placement === 'top-align-left') {
    return getTopAlignLeftBounds(generatorBounds, popupBounds, offset)
  } else {
    throw new Error(
      'Invalid placement ' + placement + '. Should be one of bottom-center, bottom-right…'
    )
  }
}

/*
  Score 0 means that the bounds fits within the viewport
  A score higher than 0 tells how much of the bounds that doesn’t
  fit within the viewport.

  @param padding Minimum distance from window edge
*/
const viewportOverflow = ({ bounds, padding }) => {
  const { minX, minY, maxX, maxY } = bounds
  let overflowLeft = 0
  if (minX - padding < 0) {
    overflowLeft = Math.abs(minX - padding)
  }
  let overflowRight = 0
  if (maxX + padding > window.innerWidth) {
    overflowRight = maxX + padding - window.innerWidth
  }
  let overflowTop = 0
  if (minY - padding < 0) {
    overflowTop = Math.abs(minY - padding)
  }
  let overflowBottom = 0
  if (maxY + padding > window.innerHeight) {
    overflowBottom = maxY + padding - window.innerHeight
  }
  // console.log('viewportOverflow')
  // console.log('minX/maxX', minX, maxX, 'minY/maxY', minY, maxY)
  // console.log('window.innerWidth/Height', window.innerWidth, window.innerHeight)
  // console.log('overflowLeft', overflowLeft)
  // console.log('overflowRight', overflowRight)
  // console.log('overflowTop', overflowTop)
  // console.log('overflowBottom', overflowBottom)
  return {
    left: overflowLeft,
    right: overflowRight,
    top: overflowTop,
    bottom: overflowBottom,
    score: overflowLeft + overflowRight + overflowTop + overflowBottom
  }
}

/*
  generatorBounds: The bounds (top, left, width, height, bottom, right) for the
    element that generated the popup
  popupBounds: The bounds (see above) for the popup
  placements: The preferred placements in order – eg. ['bottom-center', 'bottom-right', 'bottom-align-left'...
  padding: Minimum distance from window edge (default 20px)
  offset: Distance from generator component (default 5 px)
*/
export const getPopupPosition = ({
  generatorBounds,
  popupBounds,
  placements,
  padding = 20,
  offset = 5
}) => {
  let position = undefined
  let bestOverflowScore = Number.POSITIVE_INFINITY
  let bestPositionSoFar = undefined
  let i = 0
  while (!position && i < placements.length) {
    // console.log('placement', placements[i])
    const bounds = getBounds(generatorBounds, popupBounds, placements[i], offset)
    const overflow = viewportOverflow({ bounds, padding })
    // console.log('overflow', overflow)
    if (overflow.score === 0) {
      position = {
        left: bounds.minX,
        top: bounds.minY,
        width: popupBounds.width,
        height: popupBounds.height
      }
    } else if (overflow.score < bestOverflowScore) {
      let left = bounds.minX
      let top = bounds.minY
      let width = popupBounds.width
      let height = popupBounds.height
      bestOverflowScore = overflow.score
      if (bounds.minY < 0) {
        top = 0
      }
      if (bounds.minX < 0) {
        left = 0
      }
      if (top + height > window.innerHeight) {
        height = window.innerHeight - top
      }
      if (left + width > window.innerWidth) {
        width = window.innerWidth - left
      }
      bestPositionSoFar = { left, top, width, height }
    }
    i++
  }
  return position || bestPositionSoFar
}
