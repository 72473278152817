import { FileInput, IconButton, UploadIcon } from '@sodra/bongo-ui'
import { useMedia } from '@sodra/use-media'
import { Fragment, h, JSX } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'

type Props = {
  onChange: (audioBuffer: AudioBuffer) => void
  color?: string
}

export function AudioFileSelect({ onChange, color = 'var(--on-surface)' }: Props) {
  const fileInput = useRef<HTMLInputElement | null>(null)

  const [file, setFile] = useState<File | null>(null)
  const [fileError, setFileError] = useState<string | null>(null)
  const [fileAudioBuffer, setFileAudioBuffer] = useState<AudioBuffer | null>(null)

  const handleChange = ({ currentTarget }: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    if (!currentTarget.files) return
    const file = currentTarget.files[0]
    if (!file) return
    setFile(file)
  }

  function clearFile() {
    setFile(null)
    setFileError(null)
    setFileAudioBuffer(null)
  }

  useEffect(() => {
    if (!file) return
    async function getAudio(file: File) {
      try {
        const arrayBuffer = await file.arrayBuffer()
        const audioContext = new AudioContext()
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)

        setFileAudioBuffer(audioBuffer)
      } catch (e) {
        setFileError('Failed decoding audio file')
      }
    }
    getAudio(file)
  }, [file])

  useEffect(() => {
    if (!fileAudioBuffer) return

    onChange(fileAudioBuffer)
    clearFile()
  }, [fileAudioBuffer])

  return (
    <Fragment>
      <IconButton
        tooltipText="Upload audio file"
        color={color}
        icon={UploadIcon}
        onClick={() => fileInput.current?.click()}
      />
      <input
        type="file"
        ref={fileInput}
        accept="audio/*"
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </Fragment>
  )
}
