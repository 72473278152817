/**
 * Returns the bounding rect for the given elem
 * @param {*} elem
 */
const getBounds = (elem) => {
  const bounds = elem.getBoundingClientRect()
  const { x, y } = bounds
  return {
    x,
    left: x,
    y,
    top: y,
    right: x + bounds.width,
    bottom: y + bounds.height,
    width: bounds.width,
    height: bounds.height
  }
}

/**
 * Creates a bounds object based on the given arguments
 * @param {int} x the x coordinate
 * @param {int} y the y coordinate
 * @param {int} width the width of the bounds
 * @param {int} height the height of the bounds
 */
const createBounds = ({ x, y, width = 0, height = 0 }) => {
  return {
    left: x,
    top: y,
    right: x + width,
    bottom: y + height,
    width,
    height
  }
}

export { getBounds, createBounds }
