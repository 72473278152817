const replicaUrl = 'https://cdn.speechless.sodra.io/replica/v1'
const kimSpeakerId = '2e72c59a-d80e-4316-9a20-ae233cc5b71e'
const robinSpeakerId = '8a15430f-ddc2-4d1f-a918-02788e01319c'

export const studioQualityDemo = (user: User): Dialogue => ({
  title: 'Studio quality demo',
  location: 'Your home studio',
  description: 'A test of your recording equipment.',
  lines: [
    {
      line: {
        id: 'studio-quality-demo',
        line: `Hi! This is ${user.name} at Speechless, and this is an example of what my studio sounds like.`,
        character: { name: user.name ?? '<YOUR NAME>', picture: null, email: null, id: user.id }
      },
      referenceAudioUri: `${replicaUrl}?speaker_id=4807ea95-5b17-43b7-b25d-e409736a099f&txt=Hi! This is ${user.name} at Speechless, and this is an example of what my studio sounds like.`
    }
  ]
})

export const bar: Dialogue = {
  location: 'int. bar - night',
  description: 'Two friends are having an important conversation over drinks:',
  title: 'Session - Example Scene',
  lines: [
    {
      line: {
        id: 'kim1',
        line: 'How about that mustache guy in Raiders? Who gets chopped up by the propeller.',
        character: { name: 'kim', picture: null, email: null, id: 'kim' }
      },
      referenceAudioUri: `${replicaUrl}?speaker_id=${kimSpeakerId}&txt=How about that moustache guy in Raiders%3F Who gets chopped up by the propeller.`
    },
    {
      line: {
        id: 'robin1',
        line: 'The bald one?',
        character: { name: 'robin', picture: null, email: null, id: 'robin' }
      },
      referenceAudioUri: `${replicaUrl}?speaker_id=${robinSpeakerId}&txt=The bald one%3F`
    },
    {
      line: {
        id: 'kim2',
        line: 'Yeah, him. Could he take on a cave troll? Unarmed?',
        character: { name: 'kim', picture: null, email: null, id: 'kim' }
      },
      referenceAudioUri: `${replicaUrl}?speaker_id=${kimSpeakerId}&txt=Yeah, him. Could he take on a cave troll%3F Unarmed%3F`
    },
    {
      line: {
        id: 'robin2',
        line: 'Nah... I’d say you need at least two moustaches for one troll.',
        character: { name: 'robin', picture: null, email: null, id: 'robin' }
      },
      referenceAudioUri: `${replicaUrl}?speaker_id=${robinSpeakerId}&txt=Nah... I’d say you need at least 2 moustaches for one troll.`
    },
    {
      line: {
        id: 'kim3',
        line: 'To beat it? Or for an even fight?',
        character: { name: 'kim', picture: null, email: null, id: 'kim' }
      },
      referenceAudioUri: `${replicaUrl}?speaker_id=${kimSpeakerId}&txt=To beat it%3F Or for an even fight%3F`
    },
    {
      line: {
        id: 'robin3',
        line: 'For an even fight. I’d watch that.',
        character: { name: 'robin', picture: null, email: null, id: 'robin' }
      },
      referenceAudioUri: `${replicaUrl}?speaker_id=${robinSpeakerId}&txt=For an even fight. I’d watch that.`
    },
    {
      line: {
        id: 'kim4',
        line: 'So... how many moustaches for Batman?',
        character: { name: 'kim', picture: null, email: null, id: 'kim' }
      },
      referenceAudioUri: `${replicaUrl}?speaker_id=${kimSpeakerId}&txt=So... how many moustaches for Batman%3F`
    },
    {
      line: {
        id: 'robin4',
        line: 'Come on, enough with the mixed franchises.',
        character: { name: 'robin', picture: null, email: null, id: 'robin' }
      },
      referenceAudioUri: `${replicaUrl}?speaker_id=${robinSpeakerId}&txt=Come on, enough with the mixed franchises.`
    }
  ]
}

export const battle: Dialogue = {
  location: 'EXT. VIETNAM BATTLEFIELD - DAWN',
  description:
    'An LRRP team has been discovered and is pinned down with bullets snapping by overhead as they return fire:',
  title: 'Example scene - Battle',
  lines: [
    {
      line: {
        id: 'radioman1',
        line: 'I got beacoup movement on the left flank! Over by the treeline!',
        character: { name: 'radioman', picture: null, email: null, id: 'radioman' }
      }
    },
    {
      line: {
        id: 'leader1',
        line: 'Martin, shift fire left! Rodriguez, any news on that Spooky?',
        character: { name: 'leader', picture: null, email: null, id: 'leader' }
      }
    },
    {
      line: {
        id: 'radioman2',
        paranthetical: '(into radio, slightly hysteric)',
        line: 'I say again: This is Wolverine Five-Seven, request immediate airsupport. We are taking heavy fire. Over.',
        character: { name: 'radioman', picture: null, email: null, id: 'radioman' }
      }
    },
    {
      line: {
        id: 'airsupport1',
        paranthetical: '(calm, over radio)',
        line: 'Calm down, son. I got two Phantoms and a Spooky inbound on your position. ETA, 2 minutes.',
        character: { name: 'airsupport', picture: null, email: null, id: 'airsupport' }
      }
    },
    {
      line: {
        id: 'radioman3',
        paranthetical: '(to the others)',
        line: 'We’re getting a Puff! ETA two--',
        character: { name: 'radioman', picture: null, email: null, id: 'radioman' }
      }
    },
    {
      line: {
        id: 'description1',
        line: 'He’s cut off by a bullet blowing his brains out.',
        character: { name: 'description', picture: null, email: null, id: 'description' }
      }
    },
    {
      line: {
        id: 'leader2',
        line: 'Rodriquez!? Fuuuuuuck!!!',
        character: { name: 'leader', picture: null, email: null, id: 'leader' }
      }
    },
    {
      line: {
        id: 'description2',
        line: 'Shooting intensifies.',
        character: { name: 'description', picture: null, email: null, id: 'description' }
      }
    },
    {
      line: {
        id: 'airsupport2',
        paranthetical: '(calm, over radio)',
        line: 'Wolverine? Come in Wolverine. I got those Phantoms on the horn. Get ready to pop smoke.',
        character: { name: 'airsupport', picture: null, email: null, id: 'airsupport' }
      }
    }
  ]
}

export const exampleDialogues = {
  bar,
  battle,
  'studio-quality-demo': studioQualityDemo
}
