import { h, Fragment } from 'preact'
import { useState, useRef, useEffect } from 'preact/hooks'
import { Row, Block } from 'jsxstyle/preact'

import { TextField } from './TextField'
import { SpacerHorizontal } from './spacers'
import { Button } from './Button'
import { InputInfo } from './InputInfo'
import { InputError } from './InputError'
import { getUniqueId } from './get-unique-id'

export const FileInput = ({
  simple,
  outline,
  label,
  placeholder,
  value,
  icon,
  infoText,
  errorText,
  accept,
  onChange,
  props
}) => {
  const fileInput = useRef(null)
  const [ariaDescribedById, setAriaDescribedById] = useState()

  useEffect(() => {
    if (infoText || errorText) {
      setAriaDescribedById(getUniqueId('bui-aria-describedby'))
    }
  }, [infoText, errorText])

  const handleChange = (e) => {
    const file = e.target.files[0]
    if (onChange && file) {
      onChange(file)
    }
  }

  const filename = value ? value.name : ''

  return (
    <Fragment>
      <Row alignItems="stretch" props={{ ...props }}>
        <Block flex="1" lineHeight="0">
          <TextField
            readonly
            simple={simple}
            outline={outline}
            label={label}
            placeholder={placeholder}
            value={filename}
            icon={icon}
            width="100%"
            props={{
              'aria-describedby': ariaDescribedById
            }}
          />
        </Block>
        <SpacerHorizontal tiny />
        <Button outlined height="auto" onClick={() => fileInput.current.click()}>
          Select file
        </Button>
        <input
          type="file"
          ref={fileInput}
          accept={accept}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </Row>
      <Block padding={simple ? '0' : '0 15px'}>
        {!errorText && <InputInfo id={ariaDescribedById}>{infoText}</InputInfo>}
        {errorText && <InputError id={ariaDescribedById}>{errorText}</InputError>}
      </Block>
    </Fragment>
  )
}

export default FileInput
