import {
  Button,
  CheckCircleIcon,
  DeleteIcon,
  FileInput,
  IconButton,
  ProgressCircular,
  SpacerVertical,
  UploadIcon
} from '@sodra/bongo-ui'
import { useMedia } from '@sodra/use-media'
import { Block, Row } from 'jsxstyle/preact'
import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { AudioBufferButton } from './AudioBufferButton'
import { AudioButton } from './AudioButton'
import { AudioFileSelect } from './AudioFileSelect'
import { useDeleteMutation, useSaveMutation } from './mutationHooks'
import { Outline } from './Outline'

const SHOW_SELECT_AUDIO_FILE = false

type LineAudioButtonsProps = {
  recordedAudioUri?: string
  availableAudio?: AudioBuffer | null
  recordedAudio?: AudioBuffer | null
  line: Line
}

function LineAudioButtons({
  recordedAudioUri,
  availableAudio,
  recordedAudio,
  line
}: LineAudioButtonsProps) {
  const isNarrow = useMedia(['(max-width: 600px)'], [true], false)
  const audioButtonWidth = isNarrow ? '100px' : '150px'

  const saveMutation = useSaveMutation()
  const deleteMutation = useDeleteMutation()

  return (
    <Row alignItems="center">
      {recordedAudioUri && (
        <AudioButton uri={recordedAudioUri} buttonProps={{ tiny: true, width: audioButtonWidth }} />
      )}
      {!recordedAudioUri && recordedAudio && (
        <AudioBufferButton
          audioBuffer={recordedAudio}
          buttonProps={{ tiny: true, width: audioButtonWidth }}
        />
      )}
      {!(recordedAudioUri || recordedAudio) && (
        <Block margin="0 10px" color="var(--on-surface-light)">
          Not recorded
        </Block>
      )}
      {!recordedAudioUri && !recordedAudio && (
        <Button
          disabled={!availableAudio}
          loading={saveMutation.isLoading}
          tiny
          onClick={() => {
            if (!availableAudio) return
            saveMutation.mutate({
              line,
              recordedAudio: availableAudio
            })
          }}
        >
          Assign recorded audio
        </Button>
      )}
      {SHOW_SELECT_AUDIO_FILE && (
        <AudioFileSelect
          onChange={(buffer) => {
            saveMutation.mutate({
              line,
              recordedAudio: buffer
            })
          }}
        />
      )}
      {(recordedAudioUri || recordedAudio) && (
        <Button
          loading={deleteMutation.isLoading}
          onClick={() => {
            deleteMutation.mutate(line.id)
          }}
          tooltipText="Delete audio"
          tiny
        >
          Delete
        </Button>
      )}
    </Row>
  )
}

type LineProps = {
  line: Line
  color: string
}

function Line({ line, color }: LineProps) {
  if (line.character?.name === 'description') {
    return (
      <Block
        padding="15px 40px 0 0px"
        color={color}
        fontFamily="monospace"
        position="relative"
        flex={1}
      >
        <p>{line.line}</p>
      </Block>
    )
  }

  return (
    <Block
      padding="15px 40px 0 60px"
      color={color}
      fontFamily="monospace"
      position="relative"
      flex={1}
    >
      {line.character && (
        <Block textTransform="uppercase" component="strong" marginLeft={'10ch'}>
          {line.character.name}
        </Block>
      )}
      {line.paranthetical && <Block marginLeft="5ch">{line.paranthetical}</Block>}
      <p>{line.line}</p>
    </Block>
  )
}

type Props = {
  line: DialogueLine
  mode: 'read' | 'record'
  availableAudio: AudioBuffer | null
  selected: boolean
  onSelect: () => void
}

export const LineContainer = ({
  line,
  mode = 'read',
  availableAudio,
  selected,
  onSelect
}: Props) => {
  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const [hover, setHover] = useState(false)

  const handleMouseEnter = () => setHover(true)

  const handleMouseLeave = () => setHover(false)

  const select = () => {
    if (isActiveCharacter) onSelect()
  }

  const isActiveCharacter = mode === 'record'
  return (
    <Block
      cursor={isActiveCharacter ? 'pointer' : 'inherit'}
      props={{
        id: line.line.id,
        onMouseEnter: supportsHover ? handleMouseEnter : undefined,
        onMouseLeave: supportsHover ? handleMouseLeave : undefined,
        onClick: () => select()
      }}
    >
      <Block borderRadius="8px" position="relative">
        {isActiveCharacter && (
          <Fragment>
            <Outline time={0} borderRadius="8px" color="var(--container-outline-lighter)" />
            {hover && <Outline time={90} borderRadius="8px" color="var(--container-outline)" />}
            {selected && <Outline time={90} borderRadius="8px" color="var(--accent)" width="3px" />}

            <Block position="absolute" top="10px" left="10px" opacity={hover || selected ? 1 : 0}>
              <IconButton
                icon={CheckCircleIcon}
                onClick={() => select()}
                color={selected ? 'var(--accent)' : 'var(--on-surface-lighter)'}
              />
            </Block>
          </Fragment>
        )}

        <Line
          key={line.line.line}
          line={line.line}
          color={isActiveCharacter ? 'var(--on-surface)' : 'var(--on-surface-light)'}
        />
        <Block marginLeft="-10px" padding="0 10px 15px 60px">
          {!isActiveCharacter && line.referenceAudioUri && (
            <AudioButton
              uri={line.referenceAudioUri}
              label="Reference"
              buttonProps={{ tiny: true }}
            />
          )}
          {isActiveCharacter && (
            <LineAudioButtons
              availableAudio={availableAudio}
              line={line.line}
              recordedAudio={line.recordedAudio}
              recordedAudioUri={line.recordedAudioUri}
            />
          )}
        </Block>
      </Block>
      <SpacerVertical />
    </Block>
  )
}
