import { AddIcon, Button, H2, SelectChip, SpacerVertical, TextArea } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'
import { Row } from 'jsxstyle/preact'
import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { showSnackbar, updateUser } from '../../actions'

import Section from '../Section'

import AddMicrophone from './AddMicrophone'

type Props = {
  user: User
  isUpdatingUser: boolean
}

export function Equipment({ user, isUpdatingUser }: Props) {
  const [microphones, setMicrophones] = useState(user.microphones || [])
  const [homeStudioEquipment, setHomeStudioEquipment] = useState(user.homeStudioEquipment)

  const addMicrophone = (microphone: string) => {
    if (!microphones.includes(microphone)) {
      setMicrophones((microphones) => [...microphones, microphone])
    }
  }

  const removeMicrophone = (microphone: string) => {
    setMicrophones((microphones) => microphones.filter((m) => m !== microphone))
  }

  const closeDialog = () => goBack(`/user/${user.id}`)

  useEffect(() => {
    const timeout = setTimeout(save, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [homeStudioEquipment, microphones])

  const save = async () => {
    const params: Partial<User> = {
      microphones,
      homeStudioEquipment
    }
    return updateUser(params)
  }

  const handleSave = async () => {
    if (await save()) {
      showSnackbar('Equipment saved')
    }
  }

  return (
    <Section>
      <H2 size="7">Microphones</H2>
      <SpacerVertical />
      <Row alignItems="center" flexWrap="wrap" gap={10}>
        {microphones.map((microphone) => {
          return <SelectChip value={microphone} onClear={() => removeMicrophone(microphone)} />
        })}
        <SelectChip
          icon={AddIcon}
          label="Add microphone"
          onClick={() => routeTo(`/user/${user.id}/add-microphone`)}
        />
      </Row>
      <SpacerVertical large />
      <H2 size="7">Equipment</H2>
      <SpacerVertical />

      <TextArea
        width="100%"
        label="Home studio equipment"
        value={homeStudioEquipment}
        onInput={setHomeStudioEquipment}
      />

      <SpacerVertical />
      <Row alignItems="center" justifyContent="space-between">
        <Button contained loading={isUpdatingUser} onClick={handleSave}>
          Save
        </Button>
      </Row>

      <Route
        path="/user/:userId/add-microphone"
        render={(props: any) => (
          <AddMicrophone
            {...props}
            current={microphones}
            onClose={closeDialog}
            onAdd={addMicrophone}
          />
        )}
      />
    </Section>
  )
}
