import { Button, PlayIcon, StopIcon } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'
import { h } from 'preact'
import { AudioBufferVisualizer } from '../AudioRecorder/AudioBufferVisualizer'
import { useAudioBufferPlayer } from '../AudioRecorder/useAudioBufferPlayer'

type Props = {
  audioBuffer: AudioBuffer
  buttonProps?: any
  label?: string
}

export function AudioBufferButton({ audioBuffer, buttonProps, label }: Props) {
  const { play, stop, state, currentTime } = useAudioBufferPlayer({
    audioBuffer
  })

  return (
    <Button
      icon={state === 'playing' ? StopIcon : PlayIcon}
      onClick={() => {
        if (state === 'playing') {
          stop()
        } else {
          play()
        }
      }}
      {...(buttonProps ?? {})}
    >
      {label && (
        label
      )}
      {audioBuffer && (
        <AudioBufferVisualizer
          audioBuffer={audioBuffer}
          height="20px"
          currentTime={currentTime}
        />
      )}
    </Button>
  )
}
