import regexparam from 'regexparam'
import { history } from './history'

const patterns = []

const getPattern = (path, exact = false) => {
  const key = exact ? path + '_' + exact : path
  if (!patterns[key]) {
    patterns[key] = regexparam(path, !exact)
  }
  return patterns[key]
}

export const exec = (path, exact = false) => {
  const { pattern, keys } = getPattern(path, exact)
  const matches = pattern.exec(history.location.pathname)
  if (!matches) {
    return null
  }
  const params = {}
  for (let i = 0; i < keys.length; i++) {
    params[keys[i]] = matches[i + 1]
  }
  return params
}

export const test = (path, exact = false) => {
  const { pattern } = getPattern(path, exact)
  return pattern.test(history.location.pathname)
}
