export const getColor = ({ colorProp }) => (colorProp ? colorProp : 'var(--accent)')

export const getBackgroundColor = ({ colorProp, backgroundColorProp }) => {
  if (backgroundColorProp) {
    return backgroundColorProp
  } else if (colorProp) {
    return colorProp
  }
  return 'var(--accent-light)'
}

export const getBackgroundOpacity = ({ colorProp, backgroundColorProp, backgroundOpacityProp }) => {
  if (backgroundOpacityProp) {
    return backgroundOpacityProp
  } else if (!backgroundColorProp && colorProp) {
    return 0.25
  }
  return 1
}
