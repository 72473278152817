import { h } from 'preact'
import { Block } from 'jsxstyle/preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { useMedia } from '@sodra/use-media'

import { useGetBounds } from './use-get-bounds'
import { useIsInViewport } from './use-is-in-viewport'
import Area from './Area'
import Mask from './Mask'
import CircularMask from './CircularMask'
import RuleOfThirds from './RuleOfThirds'

const areaMinWidth = 50
const areaMinHeight = 50

const cropToStr = (crop) => {
  return `minX: ${crop.minX}, minY: ${crop.minY}, maxX: ${crop.maxX}, maxY: ${crop.maxY}`
}

const cropEquals = (crop1, crop2) => {
  if (!crop1 && !crop2) {
    return true
  }
  if ((crop1 && !crop2) || (!crop1 && crop2)) {
    return false
  }
  return (
    crop1.minX === crop2.minX &&
    crop1.minY === crop2.minY &&
    crop1.maxX === crop2.maxX &&
    crop1.maxY === crop2.maxY
  )
}

const parseCrop = (crop) => {
  if (!crop) {
    return
  }

  // Make sure crop is number
  return {
    minX: parseFloat(crop.minX),
    maxX: parseFloat(crop.maxX),
    minY: parseFloat(crop.minY),
    maxY: parseFloat(crop.maxY)
  }
}

const roundCrop = (crop) => {
  if (!crop) {
    return
  }
  return {
    minX: Math.round(crop.minX),
    maxX: Math.round(crop.maxX),
    minY: Math.round(crop.minY),
    maxY: Math.round(crop.maxY)
  }
}

//
// ImageCropper
//
export const ImageCropper = ({
  url, // The URL of the image to crop
  aspectRatio, // width / height
  crop: initialCropProp, // Cooridinates in the image{ minX, minY, maxX, maxY}
  useCircularMask = false,
  margin = 100, // Margin outside crop area (pixels)
  onChange,
  ...styles
}) => {
  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const [image, setImage] = useState()

  const [area, setArea] = useState()
  const [areaMaxWidth, setAreaMaxWidth] = useState()
  const [areaMaxHeight, setAreaMaxHeight] = useState()
  const [updatedArea, setUpdatedArea] = useState()

  const prevInitialCrop = useRef()
  const [crop, setCrop] = useState()
  const [updatedCrop, setUpdatedCrop] = useState()

  const canvasElem = useRef()
  const imgElem = useRef()

  const canvasIsInViewport = useIsInViewport(canvasElem.current)
  const [canvasBounds, isUpdatingCanvasBounds] = useGetBounds(
    canvasElem.current,
    canvasIsInViewport
  )

  const [action, setAction] = useState('move')
  const [moveStart, setMoveStart] = useState()
  const [touchStart, setTouchStart] = useState()

  useEffect(() => {
    if (url) {
      setCrop()
      setArea()
      setImage()
      const i = new Image()
      const handleOnLoad = (e) => {
        setImage({
          src: url,
          width: i.width,
          height: i.height
        })
      }
      i.addEventListener('load', handleOnLoad)
      i.src = url
      return () => {
        i.removeEventListener('load', handleOnLoad)
      }
    }
  }, [url])

  useEffect(() => {
    if (image) {
      setCrop()
      setArea()
    }
  }, [image, aspectRatio])

  useEffect(() => {
    if (canvasBounds && !isUpdatingCanvasBounds) {
      const areaMaxWidth = canvasBounds.width - margin * 2
      const areaMaxHeight = canvasBounds.height - margin * 2
      setAreaMaxWidth(areaMaxWidth)
      setAreaMaxHeight(areaMaxHeight)
      if (image && crop) {
        const areaAspectRatio = areaMaxWidth / areaMaxHeight
        const cropAspectRatio = (crop.maxX - crop.minX) / (crop.maxY - crop.minY)
        const area = {
          width: cropAspectRatio > areaAspectRatio ? areaMaxWidth : areaMaxHeight * cropAspectRatio,
          height:
            cropAspectRatio <= areaAspectRatio ? areaMaxHeight : areaMaxWidth / cropAspectRatio
        }
        setArea(area)
      }
    }
  }, [image, crop, canvasBounds, isUpdatingCanvasBounds, margin])

  const getCropAspectRatio = (crop) => {
    return (crop.maxX - crop.minX) / (crop.maxY - crop.minY)
  }

  const getAreaFromCrop = (crop) => {
    const areaAspectRatio = areaMaxWidth / areaMaxHeight
    const cropAspectRatio = (crop.maxX - crop.minX) / (crop.maxY - crop.minY)
    if (cropAspectRatio > areaAspectRatio) {
      return {
        width: areaMaxWidth,
        height: areaMaxWidth / cropAspectRatio
      }
    } else {
      return {
        height: areaMaxHeight,
        width: areaMaxHeight * cropAspectRatio
      }
    }
  }

  useEffect(() => {
    // New initial crop?
    const initialCrop = parseCrop(initialCropProp)
    const newInitialCrop = initialCrop ? !cropEquals(initialCrop, prevInitialCrop.current) : false
    // Find out if crop has to be updated
    const updateCrop = newInitialCrop ? !cropEquals(initialCrop, crop) : false
    prevInitialCrop.current = initialCrop

    if (image && areaMaxWidth > 0 && areaMaxHeight > 0 && (!crop || updateCrop)) {
      //
      // Set crop and area
      //
      const areaAspectRatio = areaMaxWidth / areaMaxHeight
      if (aspectRatio && initialCrop) {
        //
        // Aspect ratio and crop set
        // Make sure aspect ratio and aspect ratio of crop matches
        //
        const cropAspectRatio = getCropAspectRatio(initialCrop)
        if (Math.abs(aspectRatio - cropAspectRatio) > 0.1) {
          throw new Error(`Crop aspect ratio should be ${aspectRatio} – was ${cropAspectRatio}`)
        }
        setArea(getAreaFromCrop(initialCrop))
        setCrop(initialCrop)
      } else if (!aspectRatio) {
        //
        // No aspect ratio set
        // 1. Use given initial crop
        // 2. Select whole image
        //
        let crop = initialCrop
          ? initialCrop
          : {
              minX: 0,
              minY: 0,
              maxX: image.width,
              maxY: image.height
            }
        if (crop.minX < 0) {
          throw new Error(`minX < 0 – crop: ${cropToStr(crop)}`)
        }
        if (crop.minY < 0) {
          throw new Error(`minY < 0 – crop: ${cropToStr(crop)}`)
        }
        if (crop.maxX > image.width) {
          throw new Error(
            `maxX (${crop.maxX}) > image width (${image.width}) – crop: ${cropToStr(crop)}`
          )
        }
        if (crop.maxY > image.height) {
          throw new Error(
            `maxY (${crop.maxY}) > image height (${image.height}) – crop: ${cropToStr(crop)}`
          )
        }
        if (crop.minX > crop.maxX) {
          throw new Error(`minX > maxX – crop: ${cropToStr(crop)}`)
        }
        if (crop.minY > crop.maxY) {
          throw new Error(`minY > maxY – crop: ${cropToStr(crop)}`)
        }
        setArea(getAreaFromCrop(crop))
        setCrop(crop)
      } else {
        //
        // Aspect ratio set
        // Calculate maximum crop
        //
        const imageAspectRatio = image.width / image.height

        let initialArea
        if (aspectRatio > areaAspectRatio) {
          initialArea = {
            width: areaMaxWidth,
            height: areaMaxWidth / aspectRatio
          }
        } else {
          initialArea = {
            height: areaMaxHeight,
            width: areaMaxHeight * aspectRatio
          }
        }

        const initialScale =
          aspectRatio > imageAspectRatio
            ? image.width / initialArea.width
            : image.height / initialArea.height

        let initialMinX
        let initialMinY
        if (aspectRatio < imageAspectRatio) {
          initialMinX = image.width / 2 - (initialArea.width / 2) * initialScale
          initialMinY = 0
        } else {
          initialMinX = 0
          initialMinY = image.height / 2 - (initialArea.height / 2) * initialScale
        }

        const initialCrop = {
          minX: initialMinX,
          minY: initialMinY,
          maxX:
            aspectRatio > imageAspectRatio ? image.width : initialMinX + image.height * aspectRatio,
          maxY:
            aspectRatio <= imageAspectRatio ? image.height : initialMinY + image.width / aspectRatio
        }

        setArea(initialArea)
        setCrop(initialCrop)
      }
    }
  }, [image, initialCropProp, area, aspectRatio, areaMaxWidth, areaMaxHeight])

  useEffect(() => {
    if (onChange && !moveStart && !touchStart && crop) {
      onChange(roundCrop(crop))
    }
  }, [crop, moveStart, touchStart])

  const moveByKeyboard = (dx, dy) => {
    setCrop(({ minX, minY, maxX, maxY }) => {
      const crop = {
        minX: minX + dx / scale,
        minY: minY + dy / scale,
        maxX: maxX + dx / scale,
        maxY: maxY + dy / scale
      }
      if (crop.minX < 0) {
        crop.maxX -= crop.minX
        crop.minX = 0
      }
      if (crop.maxX > image.width) {
        crop.minX -= crop.maxX - image.width
        crop.maxX = image.width
      }
      if (crop.minY < 0) {
        crop.maxY -= crop.minY
        crop.minY = 0
      }
      if (crop.maxY > image.height) {
        crop.minY -= crop.maxY - image.height
        crop.maxY = image.height
      }
      return crop
    })
  }

  const zoomIn = (fixedCorner) => {
    setCrop(({ minX, minY, maxX, maxY }) => {
      const dx = (maxX - minX) / 10
      const dy = (maxY - minY) / 10

      if (fixedCorner === 'top-left') {
        return {
          minX,
          minY,
          maxX: maxX - dx,
          maxY: maxY - dy
        }
      } else if (fixedCorner === 'top-right') {
        return {
          minX: minX + dx,
          minY,
          maxX,
          maxY: maxY - dy
        }
      } else if (fixedCorner === 'bottom-left') {
        return {
          minX,
          minY: minY + dy,
          maxX: maxX - dx,
          maxY
        }
      } else if (fixedCorner === 'bottom-right') {
        return {
          minX: minX + dx,
          minY: minY + dy,
          maxX,
          maxY
        }
      }

      return {
        minX: minX + dx,
        minY: minY + dy,
        maxX: maxX - dx,
        maxY: maxY - dy
      }
    })
  }

  const zoomOut = (fixedCorner) => {
    setCrop(({ minX, minY, maxX, maxY }) => {
      const width = maxX - minX
      const height = maxY - minY
      const cropAspectRatio = width / height
      const dx = width / 10
      const dy = height / 10

      let crop
      if (fixedCorner === 'top-left') {
        crop = {
          minX,
          minY,
          maxX: maxX + dx * 2,
          maxY: maxY + dy * 2
        }
        if (crop.maxX > image.width) {
          crop.maxX = image.width
          crop.maxY = minY + (crop.maxX - crop.minX) / cropAspectRatio
        }
        if (crop.maxY > image.height) {
          crop.maxY = image.height
          crop.maxX = minX + (crop.maxY - crop.minY) * cropAspectRatio
        }
      } else if (fixedCorner === 'top-right') {
        crop = {
          minX: minX - dx * 2,
          minY,
          maxX,
          maxY: maxY + dy * 2
        }
        if (crop.minX < 0) {
          crop.minX = 0
          crop.maxY = crop.minY + (crop.maxX - crop.minX) / cropAspectRatio
        }
        if (crop.maxY > image.height) {
          crop.maxY = image.height
          crop.minX = crop.maxX - (crop.maxY - crop.minY) * cropAspectRatio
        }
      } else if (fixedCorner === 'bottom-left') {
        crop = {
          minX,
          minY: minY - dy * 2,
          maxX: maxX + dx * 2,
          maxY
        }
        if (crop.minY < 0) {
          crop.minY = 0
          crop.maxX = crop.minX + (crop.maxY - crop.minY) * cropAspectRatio
        }
        if (crop.maxX > image.width) {
          crop.maxX = image.width
          crop.minY = crop.maxY - (crop.maxX - crop.minX) / cropAspectRatio
        }
      } else if (fixedCorner === 'bottom-right') {
        crop = {
          minX: minX - dx * 2,
          minY: minY - dy * 2,
          maxX,
          maxY
        }
        if (crop.minX < 0) {
          crop.minX = 0
          crop.minY = crop.maxY - (crop.maxX - crop.minX) / cropAspectRatio
        }
        if (crop.minY < 0) {
          crop.minY = 0
          crop.minX = crop.maxX - (crop.maxY - crop.minY) * cropAspectRatio
        }
      } else {
        // Regular zoom out
        // Keep center
        crop = {
          minX: minX - dx,
          minY: minY - dy,
          maxX: maxX + dx,
          maxY: maxY + dy
        }

        const overflow = {
          left: crop.minX < 0 ? -crop.minX : 0,
          right: crop.maxX > image.width ? crop.maxX - image.width : 0,
          top: crop.minY < 0 ? -crop.minY : 0,
          bottom: crop.maxY > image.height ? crop.maxY - image.height : 0
        }

        if (overflow.top > 0 && overflow.left > 0) {
          crop.minX = 0
          crop.maxY += overflow.top
          crop.minY = 0
          crop.maxX += overflow.left
          if (crop.maxX > image.width) {
            crop.maxX = image.width
            crop.maxY = crop.minY + (crop.maxX - crop.minX) / cropAspectRatio
          }
          if (crop.maxY > image.height) {
            crop.maxY = image.height
            crop.maxX = crop.minX + (crop.maxY - crop.minY) * cropAspectRatio
          }
        } else if (overflow.top > 0 && overflow.right > 0) {
          crop.minX -= overflow.right
          crop.maxY += overflow.top
          crop.minY = 0
          crop.maxX = image.width
          if (crop.minX < 0) {
            crop.minX = 0
            crop.maxY = crop.minY + (crop.maxX - crop.minX) / cropAspectRatio
          }
          if (crop.maxY > image.height) {
            crop.maxY = image.height
            crop.minX = crop.maxX - (crop.maxY - crop.minY) * cropAspectRatio
          }
        } else if (overflow.bottom > 0 && overflow.right > 0) {
          crop.minX -= overflow.right
          crop.maxY = image.height
          crop.minY -= overflow.bottom
          crop.maxX = image.width
          if (crop.minX < 0) {
            crop.minX = 0
            crop.minY = crop.maxY - (crop.maxX - crop.minX) / cropAspectRatio
          }
          if (crop.minY < 0) {
            crop.minY = 0
            crop.minX = crop.maxX - (crop.maxY - crop.minY) * cropAspectRatio
          }
        } else if (overflow.bottom > 0 && overflow.left > 0) {
          crop.minX = 0
          crop.maxY = image.height
          crop.minY -= overflow.bottom
          crop.maxX += overflow.left
          if (crop.maxX > image.width) {
            crop.maxX = image.width
            crop.minY = crop.maxY - (crop.maxX - crop.minX) / cropAspectRatio
          }
          if (crop.minY < 0) {
            crop.minY = 0
            crop.minX = crop.maxX - (crop.maxY - crop.minY) * cropAspectRatio
          }
        } else if (overflow.top > 0) {
          crop.minY = 0
          crop.maxY += overflow.top
          if (crop.maxY > image.height) {
            crop.maxY = image.height
            const dxx = (crop.maxY - crop.minY) * cropAspectRatio - (maxX - minX)
            crop.minX = minX - dxx / 2
            crop.maxX = maxX + dxx / 2
          }
        } else if (overflow.bottom > 0) {
          crop.maxY = image.height
          crop.minY -= overflow.bottom
          if (crop.minY < 0) {
            crop.minY = 0
            const dxx = (crop.maxY - crop.minY) * cropAspectRatio - (maxX - minX)
            crop.minX = minX - dxx / 2
            crop.maxX = maxX + dxx / 2
          }
        } else if (overflow.left > 0) {
          crop.minX = 0
          crop.maxX += overflow.left
          if (crop.maxX > image.width) {
            crop.maxX = image.width
            const dyy = (crop.maxX - crop.minX) / cropAspectRatio - (maxY - minY)
            crop.minY = minY - dyy / 2
            crop.maxY = maxY + dyy / 2
          }
        } else if (overflow.right > 0) {
          crop.maxX = image.width
          crop.minX -= overflow.right
          if (crop.minX < 0) {
            crop.minX = 0
            const dyy = (crop.maxX - crop.minX) / cropAspectRatio - (maxY - minY)
            crop.minY = minY - dyy / 2
            crop.maxY = maxY + dyy / 2
          }
        }
      }

      if (crop) {
        return crop
      }
      return { minX, minY, maxX, maxY }
    })
  }

  const touchZoom = (touches) => {
    const { minX, minY, maxX, maxY } = touchStart.crop

    const startCropWidth = maxX - minX
    const startCropHeight = maxY - minY
    const cropAspectRatio = startCropWidth / startCropHeight

    const move1 = {
      x: touchStart.touches[0].clientX - touches[0].clientX,
      y: touchStart.touches[0].clientY - touches[0].clientY
    }
    const move2 = {
      x: touchStart.touches[1].clientX - touches[1].clientX,
      y: touchStart.touches[1].clientY - touches[1].clientY
    }

    const zoomInX =
      Math.abs(touchStart.touches[0].clientX - touchStart.touches[1].clientX) <
      Math.abs(touches[0].clientX - touches[1].clientX)

    let moveX
    if (Math.abs(move1.x) > Math.abs(move2.x)) {
      moveX = move1.x
    } else {
      moveX = move2.x
    }
    moveX = Math.abs(moveX) * (zoomInX ? 1 : -1)

    const zoomInY =
      Math.abs(touchStart.touches[0].clientY - touchStart.touches[1].clientY) <
      Math.abs(touches[0].clientY - touches[1].clientY)

    let moveY
    if (Math.abs(move1.y) > Math.abs(move2.y)) {
      moveY = move1.y
    } else {
      moveY = move2.y
    }
    moveY = Math.abs(moveY) * (zoomInY ? 1 : -1)

    let dx
    let dy
    if (Math.abs(moveX) > Math.abs(moveY)) {
      dx = moveX
      dy = dx / cropAspectRatio
    } else {
      dy = moveY
      dx = dy * cropAspectRatio
    }

    // Keep center
    let crop = {
      minX: minX + dx / scale,
      minY: minY + dy / scale,
      maxX: maxX - dx / scale,
      maxY: maxY - dy / scale
    }

    if (crop.maxX < crop.minX || crop.maxY < crop.minY) {
      return
    }
    if (crop.maxX - crop.minX < 5) {
      return
    }
    if (crop.maxY - crop.minY < 5) {
      return
    }

    let overflow = {
      left: crop.minX < 0 ? -crop.minX : 0,
      right: crop.maxX > image.width ? crop.maxX - image.width : 0,
      top: crop.minY < 0 ? -crop.minY : 0,
      bottom: crop.maxY > image.height ? crop.maxY - image.height : 0
    }

    if ((overflow.top > 0 && overflow.bottom > 0) || (overflow.left > 0 && overflow.right > 0)) {
      // Decide if crop should fill width or height of image
      const imageAspectRatio = image.width / image.height
      if (aspectRatio < imageAspectRatio) {
        crop.minY = 0
        crop.maxY = image.height
        const centerX = minX + (maxX - minX) / 2
        crop.minX = centerX - ((crop.maxY - crop.minY) * cropAspectRatio) / 2
        crop.maxX = centerX + ((crop.maxY - crop.minY) * cropAspectRatio) / 2
        if (crop.minX < 0) {
          crop.minX = 0
          crop.maxX = crop.minX + (crop.maxY - crop.minY) * cropAspectRatio
        }
        if (crop.maxX > image.width) {
          crop.maxX = image.width
          crop.minX = crop.maxX - (crop.maxY - crop.minY) * cropAspectRatio
        }
      } else {
        crop.minX = 0
        crop.maxX = image.width
        const centerY = minY + (maxY - minY) / 2
        crop.minY = centerY - (crop.maxX - crop.minX) / cropAspectRatio / 2
        crop.maxY = centerY + (crop.maxX - crop.minX) / cropAspectRatio / 2
        if (crop.minY < 0) {
          crop.minY = 0
          crop.maxY = crop.minY + (crop.maxX - crop.minX) / cropAspectRatio
        }
        if (crop.maxY > image.height) {
          crop.maxY = image.height
          crop.minY = crop.maxY - (crop.maxX - crop.minX) / cropAspectRatio
        }
      }
    } else if (overflow.top > 0 && overflow.left > 0) {
      crop.minX = 0
      crop.maxY += overflow.top
      crop.minY = 0
      crop.maxX += overflow.left
      if (crop.maxX > image.width) {
        crop.maxX = image.width
        crop.maxY = crop.minY + (crop.maxX - crop.minX) / cropAspectRatio
      }
      if (crop.maxY > image.height) {
        crop.maxY = image.height
        crop.maxX = crop.minX + (crop.maxY - crop.minY) * cropAspectRatio
      }
    } else if (overflow.top > 0 && overflow.right > 0) {
      crop.minX -= overflow.right
      crop.maxY += overflow.top
      crop.minY = 0
      crop.maxX = image.width
      if (crop.minX < 0) {
        crop.minX = 0
        crop.maxY = crop.minY + (crop.maxX - crop.minX) / cropAspectRatio
      }
      if (crop.maxY > image.height) {
        crop.maxY = image.height
        crop.minX = crop.maxX - (crop.maxY - crop.minY) * cropAspectRatio
      }
    } else if (overflow.bottom > 0 && overflow.right > 0) {
      crop.minX -= overflow.right
      crop.maxY = image.height
      crop.minY -= overflow.bottom
      crop.maxX = image.width
      if (crop.minX < 0) {
        crop.minX = 0
        crop.minY = crop.maxY - (crop.maxX - crop.minX) / cropAspectRatio
      }
      if (crop.minY < 0) {
        crop.minY = 0
        crop.minX = crop.maxX - (crop.maxY - crop.minY) * cropAspectRatio
      }
    } else if (overflow.bottom > 0 && overflow.left > 0) {
      crop.minX = 0
      crop.maxY = image.height
      crop.minY -= overflow.bottom
      crop.maxX += overflow.left
      if (crop.maxX > image.width) {
        crop.maxX = image.width
        crop.minY = crop.maxY - (crop.maxX - crop.minX) / cropAspectRatio
      }
      if (crop.minY < 0) {
        crop.minY = 0
        crop.minX = crop.maxX - (crop.maxY - crop.minY) * cropAspectRatio
      }
    } else if (overflow.top > 0) {
      crop.minY = 0
      crop.maxY += overflow.top
      if (crop.maxY > image.height) {
        crop.maxY = image.height
        const dxx = (crop.maxY - crop.minY) * cropAspectRatio - (maxX - minX)
        crop.minX = minX - dxx / 2
        crop.maxX = maxX + dxx / 2
      }
    } else if (overflow.bottom > 0) {
      crop.maxY = image.height
      crop.minY -= overflow.bottom
      if (crop.minY < 0) {
        crop.minY = 0
        const dxx = (crop.maxY - crop.minY) * cropAspectRatio - (maxX - minX)
        crop.minX = minX - dxx / 2
        crop.maxX = maxX + dxx / 2
      }
    } else if (overflow.left > 0) {
      crop.minX = 0
      crop.maxX += overflow.left
      if (crop.maxX > image.width) {
        crop.maxX = image.width
        const dyy = (crop.maxX - crop.minX) / cropAspectRatio - (maxY - minY)
        crop.minY = minY - dyy / 2
        crop.maxY = maxY + dyy / 2
      }
    } else if (overflow.right > 0) {
      crop.maxX = image.width
      crop.minX -= overflow.right
      if (crop.minX < 0) {
        crop.minX = 0
        const dyy = (crop.maxX - crop.minX) / cropAspectRatio - (maxY - minY)
        crop.minY = minY - dyy / 2
        crop.maxY = maxY + dyy / 2
      }
    }

    setCrop(crop)
  }

  const move = ({ startX, startY, x, y, minX, minY, maxX, maxY }) => {
    const dx = x - startX
    const dy = y - startY

    let dxCorner = dx
    let dyCorner = dy

    const scaleX = area.width / (maxX - minX)
    const scaleY = area.height / (maxY - minY)
    const scale = Math.max(scaleX, scaleY)

    //
    // Regular move
    //
    if (action === 'move') {
      const crop = {
        minX: minX - dx / scale,
        minY: minY - dy / scale,
        maxX: maxX - dx / scale,
        maxY: maxY - dy / scale
      }
      if (crop.minX < 0) {
        crop.maxX -= crop.minX
        crop.minX = 0
      }
      if (crop.maxX > image.width) {
        crop.minX -= crop.maxX - image.width
        crop.maxX = image.width
      }
      if (crop.minY < 0) {
        crop.maxY -= crop.minY
        crop.minY = 0
      }
      if (crop.maxY > image.height) {
        crop.minY -= crop.maxY - image.height
        crop.maxY = image.height
      }
      setCrop(crop)
    }

    //
    // Move top left
    //
    if (action === 'move-top-left') {
      if (aspectRatio) {
        dxCorner = dy * parseFloat(aspectRatio)
      }
      const crop = {
        minX: minX + dxCorner / scale,
        minY: minY + dyCorner / scale,
        maxX,
        maxY
      }
      if (crop.minX < 0) {
        crop.minX = 0
        if (aspectRatio) {
          crop.minY = crop.maxY - (crop.maxX - crop.minX) / aspectRatio
        }
      }
      if (crop.minY < 0) {
        crop.minY = 0
        if (aspectRatio) {
          crop.minX = crop.maxX - (crop.maxY - crop.minY) * aspectRatio
        }
      }
      if (crop.maxX - crop.minX < areaMinWidth / scale) {
        crop.minX = crop.maxX - areaMinWidth / scale
        if (aspectRatio) {
          crop.minY = crop.maxY - (crop.maxX - crop.minX) / aspectRatio
        }
      }
      if (crop.maxY - crop.minY < areaMinHeight / scale) {
        crop.minY = crop.maxY - areaMinHeight / scale
        if (aspectRatio) {
          crop.minX = crop.maxY - (crop.maxX - crop.minX) / aspectRatio
        }
      }

      if ((dxCorner < 0 && minX > 0) || (dyCorner < 0 && minY > 0)) {
        setUpdatedArea()
        setUpdatedCrop()
        setCrop(crop)
        if (!aspectRatio) {
          const newArea = calculateArea(crop.maxX - crop.minX, crop.maxY - crop.minY)
          setArea(newArea)
        }
        setMoveStart({ x, y, crop })
      } else {
        const newArea = {
          top: dyCorner,
          left: dxCorner,
          width: area.width - dxCorner,
          height: area.height - dyCorner
        }
        if (newArea.top < 0) {
          newArea.top = 0
          newArea.height = area.height
        }
        if (newArea.left < 0) {
          newArea.left = 0
          newArea.width = area.width
        }
        if (area.width - newArea.left < areaMinWidth) {
          newArea.left = area.width - areaMinWidth
          newArea.width = areaMinWidth
        }
        if (area.height - newArea.top < areaMinHeight) {
          newArea.top = area.height - areaMinHeight
          newArea.height = areaMinHeight
        }

        if (!aspectRatio || Math.abs(newArea.width / newArea.height - aspectRatio) < 0.1) {
          setUpdatedArea(newArea)
          setUpdatedCrop(crop)
        }
      }
    }

    //
    // Move top right
    //
    if (action === 'move-top-right') {
      if (aspectRatio) {
        dxCorner = -dy * parseFloat(aspectRatio)
      }
      const crop = {
        minX,
        minY: minY + dyCorner / scale,
        maxX: maxX + dxCorner / scale,
        maxY
      }
      if (crop.minY < 0) {
        crop.minY = 0
        if (aspectRatio) {
          crop.maxX = crop.minX + (crop.maxY - crop.minY) * aspectRatio
        }
      }
      if (crop.maxX > image.width) {
        crop.maxX = image.width
        if (aspectRatio) {
          crop.minY = crop.maxY - (crop.maxX - crop.minX) / aspectRatio
        }
      }
      if (crop.maxX - crop.minX < areaMinWidth / scale) {
        crop.maxX = crop.minX + areaMinWidth / scale
        if (aspectRatio) {
          crop.minY = crop.maxY - (crop.maxX - crop.minX) / aspectRatio
        }
      }
      if (crop.maxY - crop.minY < areaMinHeight / scale) {
        crop.minY = crop.maxY - areaMinHeight / scale
        if (aspectRatio) {
          crop.minX = crop.maxY - (crop.maxX - crop.minX) / aspectRatio
        }
      }

      if ((dxCorner > 0 && maxX + dxCorner / scale < image.width) || (dyCorner < 0 && minY > 0)) {
        setUpdatedArea()
        setUpdatedCrop()
        setCrop(crop)
        if (!aspectRatio) {
          const newArea = calculateArea(crop.maxX - crop.minX, crop.maxY - crop.minY)
          setArea(newArea)
        }
        setMoveStart({ x, y, crop })
      } else {
        const newArea = {
          top: dyCorner,
          left: 0,
          width: area.width + dxCorner,
          height: area.height - dyCorner
        }
        if (newArea.top < 0) {
          newArea.top = 0
          newArea.height = area.height
        }
        if (newArea.left + newArea.width > area.width) {
          newArea.width = area.width - newArea.left
        }
        if (newArea.top + newArea.height > area.height) {
          newArea.height = area.height - newArea.top
        }
        if (newArea.width < areaMinWidth) {
          newArea.width = areaMinWidth
        }
        if (area.height - newArea.top < areaMinHeight) {
          newArea.top = area.height - areaMinHeight
          newArea.height = areaMinHeight
        }

        if (!aspectRatio || Math.abs(newArea.width / newArea.height - aspectRatio) < 0.1) {
          setUpdatedArea(newArea)
          setUpdatedCrop(crop)
        }
      }
    }

    //
    // Move bottom left
    //
    if (action === 'move-bottom-left') {
      if (aspectRatio) {
        dxCorner = -dy * parseFloat(aspectRatio)
      }
      const crop = {
        minX: minX + dxCorner / scale,
        minY,
        maxX,
        maxY: maxY + dyCorner / scale
      }
      if (crop.minX < 0) {
        crop.minX = 0
        if (aspectRatio) {
          crop.maxY = crop.minY + (crop.maxX - crop.minX) / aspectRatio
        }
      }
      if (crop.maxY > image.height) {
        crop.maxY = image.height
        if (aspectRatio) {
          crop.minX = crop.maxX - (crop.maxY - crop.minY) * aspectRatio
        }
      }
      if (crop.maxX - crop.minX < areaMinWidth / scale) {
        crop.minX = crop.maxX - areaMinWidth / scale
        if (aspectRatio) {
          crop.maxY = crop.minY + (crop.maxX - crop.minX) / aspectRatio
        }
      }
      if (crop.maxY - crop.minY < areaMinHeight / scale) {
        crop.maxY = crop.minY + areaMinHeight / scale
        if (aspectRatio) {
          crop.minX = crop.maxX - (crop.maxY - crop.minY) * aspectRatio
        }
      }

      if (crop.minX < minX || crop.maxY > maxY) {
        setUpdatedArea()
        setUpdatedCrop()
        setCrop(crop)
        if (!aspectRatio) {
          const newArea = calculateArea(crop.maxX - crop.minX, crop.maxY - crop.minY)
          setArea(newArea)
        }
        setMoveStart({ x, y, crop })
      } else {
        const newArea = {
          top: 0,
          left: dxCorner,
          width: area.width - dxCorner,
          height: area.height + dyCorner
        }
        if (newArea.left < 0) {
          newArea.left = 0
          newArea.width = area.width
        }
        if (newArea.left + newArea.width > area.width) {
          newArea.width = area.width - newArea.left
        }
        if (newArea.top + newArea.height > area.height) {
          newArea.height = area.height - newArea.top
        }
        if (area.width - newArea.left < areaMinWidth) {
          newArea.left = area.width - areaMinWidth
          newArea.width = areaMinWidth
        }
        if (newArea.height < areaMinHeight) {
          newArea.height = areaMinHeight
        }

        if (!aspectRatio || Math.abs(newArea.width / newArea.height - aspectRatio) < 0.1) {
          setUpdatedArea(newArea)
          setUpdatedCrop(crop)
        }
      }
    }

    //
    // Move bottom right
    //
    if (action === 'move-bottom-right') {
      if (aspectRatio) {
        dxCorner = dy * parseFloat(aspectRatio)
      }
      const crop = {
        minX,
        minY,
        maxX: maxX + dxCorner / scale,
        maxY: maxY + dyCorner / scale
      }
      if (crop.maxX > image.width) {
        crop.maxX = image.width
        if (aspectRatio) {
          crop.maxY = crop.minY + (crop.maxX - crop.minX) / aspectRatio
        }
      }
      if (crop.maxY > image.height) {
        crop.maxY = image.height
        if (aspectRatio) {
          crop.maxX = crop.minX + (crop.maxY - crop.minY) * aspectRatio
        }
      }
      if (crop.maxX - crop.minX < areaMinWidth / scale) {
        crop.maxX = crop.minX + areaMinWidth / scale
        if (aspectRatio) {
          crop.maxY = crop.minY + (crop.maxX - crop.minX) / aspectRatio
        }
      }
      if (crop.maxY - crop.minY < areaMinHeight / scale) {
        crop.maxY = crop.minY + areaMinHeight / scale
        if (aspectRatio) {
          crop.minX = crop.maxY - (crop.maxX - crop.minX) * aspectRatio
        }
      }

      if (crop.maxX > maxX || crop.maxY > maxY) {
        setCrop(crop)
        setUpdatedArea()
        setUpdatedCrop()
        if (!aspectRatio) {
          const newArea = calculateArea(crop.maxX - crop.minX, crop.maxY - crop.minY)
          setArea(newArea)
        }
        setMoveStart({ x, y, crop })
      } else {
        const newArea = {
          top: 0,
          left: 0,
          width: area.width + dxCorner,
          height: area.height + dyCorner
        }
        if (newArea.left + newArea.width > area.width) {
          newArea.width = area.width - newArea.left
        }
        if (newArea.top + newArea.height > area.height) {
          newArea.height = area.height - newArea.top
        }
        if (newArea.width < areaMinWidth) {
          newArea.width = areaMinWidth
        }
        if (newArea.height < areaMinHeight) {
          newArea.height = areaMinHeight
        }

        if (!aspectRatio || Math.abs(newArea.width / newArea.height - aspectRatio) < 0.1) {
          setUpdatedArea(newArea)
          setUpdatedCrop(crop)
        }
      }
    }
  }

  const handleMouseDown = (e) => {
    // Primary button (e.buttons === 1) is pressed
    if (e.buttons === 1) {
      setMoveStart({ x: e.clientX, y: e.clientY, crop })
    }
  }

  const getTouchAction = (touches) => {
    if (touches.length === 1) {
      return 'move'
    } else if (touches.length === 2) {
      return 'zoom'
    }
  }

  const handleTouchStart = (e) => {
    e.preventDefault()
    var touches = e.touches
    const action = getTouchAction(touches)
    if (action === 'move') {
      setMoveAction(touches[0].clientX, touches[0].clientY, 48)
    }
    setTouchStart({ touches, crop, action })
    setMoveStart({ x: touches[0].clientX, y: touches[0].clientY, crop: crop })
  }

  const handleMouseMove = (e) => {
    // Primary button (e.buttons === 1) is pressed
    if (moveStart && e.buttons === 1) {
      const { minX, minY, maxX, maxY } = moveStart.crop
      move({
        startX: moveStart.x,
        startY: moveStart.y,
        x: e.clientX,
        y: e.clientY,
        minX,
        minY,
        maxX,
        maxY
      })
    }
  }

  const handleTouchMove = (e) => {
    e.preventDefault()

    var touches = e.touches
    const action = getTouchAction(touches)

    let ts = touchStart

    // Finish current touch zoom
    if (ts && ts.action === 'zoom' && action !== 'zoom') {
      ts = undefined
    }
    // Finish current move
    if (ts && ts.action === 'move' && action !== 'move') {
      ts = undefined
      setMoveStart()
    }

    if (!ts && action === 'move') {
      setMoveAction(touches[0].clientX, touches[0].clientY)
      setMoveStart({ x: touches[0].clientX, y: touches[0].clientY, crop: crop })
      setTouchStart({ touches, crop, action: 'move' })
    }
    if (!ts && action === 'zoom') {
      setTouchStart({ touches, crop, action: 'zoom' })
    }

    if (ts && ts.action === 'move' && action === 'move') {
      const { minX, minY, maxX, maxY } = touchStart.crop
      const x = touches[0].clientX
      const y = touches[0].clientY
      move({
        startX: touchStart.touches[0].clientX,
        startY: touchStart.touches[0].clientY,
        x,
        y,
        minX,
        minY,
        maxX,
        maxY
      })
    }
    if (ts && ts.action === 'zoom' && action === 'zoom') {
      touchZoom(touches)
    }
  }

  const handleMouseUp = (e) => {
    setMoveStart()
  }

  const handleTouchEnd = (e) => {
    setTouchStart()
    setMoveStart()
  }

  const handleTouchCancel = (e) => {
    setTouchStart()
    setMoveStart()
  }

  const handleMouseLeave = (e) => {
    if (moveStart) {
      // Alt 1: Use move made so far
      // setMoveStart()
      // setCrop(updatedCrop)
      // setUpdatedArea()
      // setUpdatedCrop()

      // Alt 2. Cancel move made so far
      setMoveStart()
      setUpdatedArea()
      setUpdatedCrop()
    }
  }

  // d - Number of pixels on each side of hit area center
  const intersects = ({ elem, pointer, d = 24 }) => {
    return (
      pointer.x > elem.x - d &&
      pointer.x < elem.x + d &&
      pointer.y > elem.y - d &&
      pointer.y < elem.y + d
    )
  }

  // Set move action based on pointer x, y
  const setMoveAction = (x, y, d = 24) => {
    const pointer = { x, y }

    const canvasCenter = {
      x: canvasBounds.left + canvasBounds.width / 2,
      y: canvasBounds.top + canvasBounds.height / 2
    }

    const topLeft = {
      x: canvasCenter.x - area.width / 2,
      y: canvasCenter.y - area.height / 2
    }
    const topRight = {
      x: canvasCenter.x + area.width / 2,
      y: canvasCenter.y - area.height / 2
    }
    const bottomLeft = {
      x: canvasCenter.x - area.width / 2,
      y: canvasCenter.y + area.height / 2
    }
    const bottomRight = {
      x: canvasCenter.x + area.width / 2,
      y: canvasCenter.y + area.height / 2
    }

    if (intersects({ elem: topLeft, pointer, d })) {
      setAction('move-top-left')
    } else if (intersects({ elem: topRight, pointer, d })) {
      setAction('move-top-right')
    } else if (intersects({ elem: bottomLeft, pointer, d })) {
      setAction('move-bottom-left')
    } else if (intersects({ elem: bottomRight, pointer, d })) {
      setAction('move-bottom-right')
    } else {
      setAction('move')
    }
  }

  const handleMouseMoveAction = (e) => {
    setMoveAction(e.clientX, e.clientY)
  }

  const calculateArea = (width, height) => {
    const newAspectRatio = width / parseFloat(height)
    // Calculate size of new area
    // Fill width or height?
    if (areaMaxWidth / newAspectRatio < areaMaxHeight) {
      return {
        width: areaMaxWidth,
        height: areaMaxWidth / newAspectRatio
      }
    } else {
      return {
        width: areaMaxHeight * newAspectRatio,
        height: areaMaxHeight
      }
    }
  }

  useEffect(() => {
    if (!moveStart && updatedArea && updatedCrop) {
      const newArea = calculateArea(updatedArea.width, updatedArea.height)
      setUpdatedArea()
      setUpdatedCrop()
      setArea(newArea)
      setCrop(updatedCrop)
    }
  }, [moveStart, updatedArea, updatedCrop])

  useEffect(() => {
    if (canvasElem.current && imgElem.current && crop) {
      const canvas = canvasElem.current
      const image = imgElem.current
      const handleDragStart = (e) => {
        e.preventDefault()
      }
      if (supportsHover) {
        canvas.addEventListener('mousedown', handleMouseDown)
      }
      image.addEventListener('dragstart', handleDragStart)
      return () => {
        if (supportsHover) {
          canvas.removeEventListener('mousedown', handleMouseDown)
        }
        image.removeEventListener('dragstart', handleDragStart)
      }
    }
  }, [canvasElem.current, imgElem.current, crop, action, supportsHover])

  // Change mouse pointer based on position of mouse
  useEffect(() => {
    if (
      supportsHover &&
      canvasElem.current &&
      crop &&
      canvasBounds &&
      !isUpdatingCanvasBounds &&
      !moveStart
    ) {
      const elem = canvasElem.current
      elem.addEventListener('mousemove', handleMouseMoveAction)
      return () => {
        elem.removeEventListener('mousemove', handleMouseMoveAction)
      }
    }
  }, [
    canvasElem.current,
    crop,
    area,
    canvasBounds,
    isUpdatingCanvasBounds,
    moveStart,
    supportsHover
  ])

  useEffect(() => {
    if (canvasElem.current && !isUpdatingCanvasBounds && crop && canvasBounds) {
      const elem = canvasElem.current
      elem.addEventListener('touchstart', handleTouchStart, { passive: false })
      return () => {
        elem.removeEventListener('touchstart', handleTouchStart)
      }
    }
  }, [canvasElem.current, canvasBounds, isUpdatingCanvasBounds, crop, action])

  useEffect(() => {
    if (canvasElem.current && moveStart) {
      if (supportsHover) {
        const elem = canvasElem.current
        elem.addEventListener('mousemove', handleMouseMove)
        elem.addEventListener('mouseup', handleMouseUp)
        elem.addEventListener('mouseleave', handleMouseLeave)
        return () => {
          elem.removeEventListener('mousemove', handleMouseMove)
          elem.removeEventListener('mouseup', handleMouseUp)
          elem.removeEventListener('mouseleave', handleMouseLeave)
        }
      }
    }
  }, [canvasElem.current, moveStart, crop, action, supportsHover])

  useEffect(() => {
    if (canvasElem.current && touchStart) {
      const elem = canvasElem.current
      elem.addEventListener('touchmove', handleTouchMove, { passive: false })
      elem.addEventListener('touchend', handleTouchEnd, { passive: true })
      elem.addEventListener('touchcancel', handleTouchCancel, { passive: true })
      return () => {
        elem.removeEventListener('touchmove', handleTouchMove)
        elem.removeEventListener('touchend', handleTouchEnd)
        elem.removeEventListener('touchcancel', handleTouchCancel, { passive: true })
      }
    }
  }, [canvasElem.current, touchStart, action])

  let cursor
  if (image) {
    if (action === 'move-top-left') {
      cursor = 'nwse-resize'
    } else if (action === 'move-top-right') {
      cursor = 'nesw-resize'
    } else if (action === 'move-bottom-left') {
      cursor = 'nesw-resize'
    } else if (action === 'move-bottom-right') {
      cursor = 'nwse-resize'
    } else if (moveStart) {
      cursor = 'grabbing'
    } else {
      cursor = 'grab'
    }
  }

  let scale
  let translateX
  let translateY
  if (area) {
    const cropAspectRatio = (crop.maxX - crop.minX) / (crop.maxY - crop.minY)
    const areaAspectRatio = area.width / area.height
    if (aspectRatio && Math.abs(cropAspectRatio - areaAspectRatio) > 0.1) {
      console.log('BUG', aspectRatio, cropAspectRatio, areaAspectRatio)
    }
    const scaleX = area.width / (crop.maxX - crop.minX)
    const scaleY = area.height / (crop.maxY - crop.minY)
    scale = Math.max(scaleX, scaleY)
    translateX = -crop.minX * scale
    translateY = -crop.minY * scale
  }

  return (
    <Block
      display="flex"
      background="#111"
      overflow="hidden"
      cursor={cursor}
      props={{
        ref: canvasElem
      }}
      {...styles}
    >
      {area && (
        <Block
          class="CropAreaContainer"
          width={`${area.width}px`}
          height={`${area.height}px`}
          margin="auto"
        >
          <Block position="relative">
            <Block
              class="Image"
              component="img"
              position="absolute"
              top="0"
              left="0"
              transform={`translate(${translateX}px, ${translateY}px) scale(${scale})`}
              transformOrigin="0 0"
              userSelect="none"
              props={{ ref: imgElem, src: image ? image.src : undefined }}
            />
            {useCircularMask && (
              <CircularMask
                top={updatedArea ? updatedArea.top : 0}
                left={updatedArea ? updatedArea.left : 0}
                width={updatedArea ? updatedArea.width : area.width}
                height={updatedArea ? updatedArea.height : area.height}
              />
            )}
            {!useCircularMask && (
              <Mask
                top={updatedArea ? updatedArea.top : 0}
                left={updatedArea ? updatedArea.left : 0}
                width={updatedArea ? updatedArea.width : area.width}
                height={updatedArea ? updatedArea.height : area.height}
              />
            )}
            <RuleOfThirds
              visible={moveStart || touchStart}
              top={updatedArea ? updatedArea.top : 0}
              left={updatedArea ? updatedArea.left : 0}
              width={updatedArea ? updatedArea.width : area.width}
              height={updatedArea ? updatedArea.height : area.height}
            />
            <Area
              top={updatedArea ? updatedArea.top : 0}
              left={updatedArea ? updatedArea.left : 0}
              width={updatedArea ? updatedArea.width : area.width}
              height={updatedArea ? updatedArea.height : area.height}
              move={moveByKeyboard}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
            />
          </Block>
        </Block>
      )}
    </Block>
  )
}
