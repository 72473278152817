import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'

export const Timer = ({ millis, color }) => {
  const numSeconds = millis / 1000

  let hours = Math.floor(numSeconds / 3600)
  let minutes = Math.floor((numSeconds - hours * 3600) / 60)
  let seconds = numSeconds - hours * 3600 - minutes * 60

  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  let str
  if (hours > 0) {
    str = hours + ':' + minutes + ':' + seconds
  } else {
    str = minutes + ':' + seconds
  }
  return (
    <Block fontFamily="monospace" color={color}>
      {str}
    </Block>
  )
}

export const useTimer = (initialMillis = 0) => {
  const [timerState, setTimerState] = useState('stopped')
  const [millis, setMillis] = useState(initialMillis)

  useEffect(() => {
    if (timerState === 'running') {
      const interval = setInterval(() => setMillis((millis) => millis + 1000), 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [timerState])

  return {
    millis,
    start: () => {
      setTimerState('running')
    },
    pause: () => {
      setTimerState('paused')
    },
    resume: () => {
      setTimerState('running')
    },
    stop: () => {
      setTimerState('stopped')
      setMillis(0)
    },
    reset: () => {
      setMillis(0)
    }
  }
}
