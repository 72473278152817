import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { history } from '@sodra/prutt'

import { stop } from '../actions'

const audio = new Audio()
audio.crossOrigin = 'anonymous'

const Player = ({ player }) => {
  const { status, uri } = player

  useEffect(() => {
    return history.listen(stop)
  }, [])

  useEffect(() => {
    if (status === 'playing' && uri) {
      const handleEnded = () => stop()
      const handleCanPlay = () => audio.play()
      audio.addEventListener('ended', handleEnded)
      audio.addEventListener('error', handleEnded)
      audio.addEventListener('canplay', handleCanPlay)
      audio.src = uri
      return () => {
        audio.pause()
        audio.removeEventListener('ended', handleEnded)
        audio.removeEventListener('error', handleEnded)
        audio.removeEventListener('canplay', handleCanPlay)
      }
    }
  }, [status, uri])
}

export default connect('player')(Player)
