import { h } from 'preact'

const CircularMask = ({ left, top, width, height }) => {
  return (
    <svg
      viewBox="0 0 1 1"
      width={width}
      height={height}
      overflow="visible"
      style={`position: absolute; top: ${top}px; left: ${left}px`}
    >
      <path
        d="M -40 -40 L -40 41 L 41 41 L 41 -40 Z M 0 .5 A .5 .5 0 0 0 1 .5 A .5 .5 0 0 0 0 .5 "
        fill="#202124 "
        fill-rule="evenodd"
        fill-opacity=".55"
      ></path>
    </svg>
  )
}

export default CircularMask
