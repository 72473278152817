import { h } from 'preact'
import { useEffect, useState, useRef, useMemo } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { getPopupPosition } from './popup-position'
import { getBounds, createBounds } from './bounds'
import { useMedia } from '@sodra/use-media'
import { useDisableScroll } from './use-disable-scroll'

/**
 * Opens a FloatingSheet at the given position, or next to the given positionElement.
 */
export const FloatingSheet = ({
  position: positionProps,
  positionElement,
  padding = 10,
  offset = 10,
  onClose,
  placements = [
    'bottom-align-left',
    'bottom-align-right',
    'bottom-left',
    'bottom-right',
    'top-align-left',
    'top-align-right',
    'top-left',
    'top-right'
  ],
  positionFixed = false,
  disableScroll: disableScrollProps = false,
  children,
  ...style
}) => {
  // Keep reference to current element with focus
  const focusedElemBeforeOpen = useMemo(() => document.activeElement, [])

  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const [position, setPosition] = useState()
  const sheet = useRef()

  const close = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleOutsideMouseDown = () => {
    close()
  }

  const handleInsideMouseDown = (e) => {
    e.stopPropagation()
  }

  const handleOutsideTouchStart = () => {
    close()
  }

  const handleInsideTouchStart = (e) => {
    e.stopPropagation()
  }

  useDisableScroll('.bui-sheet', disableScrollProps)

  useEffect(() => {
    sheet.current.addEventListener('mousedown', handleInsideMouseDown)
    sheet.current.addEventListener('touchstart', handleInsideTouchStart, { passive: true })
    window.addEventListener('mousedown', handleOutsideMouseDown)
    window.addEventListener('touchstart', handleOutsideTouchStart, { passive: true })
    return () => {
      sheet.current.removeEventListener('mousedown', handleInsideMouseDown)
      sheet.current.removeEventListener('touchstart', handleInsideTouchStart)
      window.removeEventListener('mousedown', handleOutsideMouseDown)
      window.removeEventListener('touchstart', handleOutsideTouchStart)
      if (supportsHover) {
        // Give focus to element that had focus before the sheet was opened
        focusedElemBeforeOpen && focusedElemBeforeOpen.focus()
      }
    }
  }, [])

  useEffect(() => {
    // Get reference to all children that can be focused
    // Remove element if style.visibility = 'hidden'
    var focusableEls = sheet.current.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    )
    focusableEls = Array.prototype.slice.call(focusableEls)
    focusableEls = focusableEls.filter((el) => el.style.visibility !== 'hidden')
    const firstFocusableEl = focusableEls ? focusableEls[0] : undefined
    const lastFocusableEl = focusableEls ? focusableEls[focusableEls.length - 1] : undefined
    if (supportsHover) {
      // Give focus to first focusable child
      // Note: skip if any of the children already has focus
      if (!focusableEls.find((el) => el === document.activeElement)) {
        firstFocusableEl && firstFocusableEl.focus()
      }
    }
    //
    const handleKeyDown = (e) => {
      // TAB
      if (e.keyCode === 9) {
        if (!firstFocusableEl && !lastFocusableEl) {
          // Close sheet on tab if sheet has no focusable children
          e.preventDefault()
          close()
        } else if (firstFocusableEl === lastFocusableEl) {
          // Prevent tabbing outside sheet if there is only one
          // focusable child
          e.preventDefault()
        } else {
          if (e.shiftKey) {
            if (e.target === firstFocusableEl) {
              e.preventDefault()
              lastFocusableEl && lastFocusableEl.focus()
            }
          } else {
            if (e.target === lastFocusableEl) {
              e.preventDefault()
              firstFocusableEl && firstFocusableEl.focus()
            }
          }
        }
      }
      // ESCAPE
      if (e.keyCode === 27) {
        close()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [children])

  const getPosition = () => {
    let generatorBounds
    if (positionProps) {
      generatorBounds = createBounds({
        x: positionProps.x,
        y: positionProps.y
      })
    } else if (positionElement) {
      generatorBounds = getBounds(positionElement)
    } else {
      throw new Error('Unable to position FloatingSheet. position or positionElement must be set.')
    }
    const bounds = sheet.current.getBoundingClientRect()
    return getPopupPosition({
      generatorBounds,
      popupBounds: bounds,
      placements,
      padding,
      offset
    })
  }

  useEffect(() => {
    if (sheet.current) {
      setPosition(getPosition())
    }
  }, [positionProps, positionElement, sheet.current])

  return (
    <Block
      class="bui bui-sheet"
      position={positionFixed ? 'fixed' : 'absolute'}
      zIndex="3"
      top={position && position.top}
      left={position && position.left}
      width={position && position.width}
      height={position && position.height}
      background="var(--surface-floating)"
      boxShadow="0 2px 6px var(--box-shadow-color)"
      borderRadius="3px"
      overflowY="auto"
      overflowX="auto"
      opacity={position ? '1' : '0'}
      transition="opacity .36s cubic-bezier(0, 0, 0.2, 1)"
      willChange="opacity"
      props={{
        ref: sheet
      }}
      {...style}
    >
      {children}
    </Block>
  )
}
