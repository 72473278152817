import { h } from 'preact'
import { connect } from 'unistore/preact'
import { goBack } from '@sodra/prutt'
import { BasicDialog } from '@sodra/bongo-ui'

import { removeAgencyVoice } from '../../actions'
import { useState } from 'preact/hooks'

const RemoveVoice = ({ agency, userId, isRemovingVoice }) => {
  const [voice] = useState(agency.voices.find((user) => user.id === userId))

  const onClose = () => goBack(`/agency/${agency.id}`)

  const handleSubmit = async () => {
    if (await removeAgencyVoice(userId)) {
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Remove voice"
      onClose={onClose}
      primaryActionText="Remove"
      primaryActionLoading={isRemovingVoice}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Remove {voice.name} from agency?
    </BasicDialog>
  )
}

export default connect('agency, isRemovingVoice')(RemoveVoice)
