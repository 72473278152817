import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Block, Col, Row } from 'jsxstyle/preact'
import {
  AddIcon,
  BackArrowIcon,
  Button,
  H1,
  H2,
  IconButton,
  LinkIcon,
  MoreIcon,
  SpacerHorizontal,
  SpacerVertical
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { addAgencyVoice, fetchAgency } from '../../actions'

import Avatar from '../Avatar'
import PopupMenuIconButton from '../PopupMenuIconButton'
import Spinner from '../Spinner'

import AgencyBreadcrumbs from './AgencyBreadcrumbs'
import VoiceListing from './VoiceListing'
import InvitationListing from './InvitationListing'
import CreateVoice from './CreateVoice'
import EditDetails from './EditDetails'
import InviteVoice from './InviteVoice'
import ManageAccess from './ManageAccess'
import RemoveVoice from './RemoveVoice'
import DeleteVoiceInvitation from './DeleteVoiceInvitation'
import { useLocalStorageState } from '../../use-local-storage-state'
import CreateLink from './CreateLink'

const Agency = ({ agencyId, agency, currentUser, isUpdatingAgency }) => {
  const hasAccess = currentUser.isSuperadmin || currentUser.agencies.some((a) => a.id === agencyId)

  const [agencyType, setAgencyType] = useLocalStorageState('slob.agencyType', null)

  useEffect(() => {
    if (hasAccess) {
      fetchAgency(agencyId)
    }
  }, [agencyId, hasAccess])

  if (!hasAccess) {
    routeTo('/', true)
  }

  if (!agency || agency.id !== agencyId) {
    return <Spinner />
  }

  const createFreelancerVoice = async () => {
    if (
      await addAgencyVoice({
        email: currentUser.email
      })
    ) {
      routeTo(`/user/${currentUser.id}/`)
    }
  }

  return (
    <Fragment>
      <AgencyBreadcrumbs agency={agency} />
      <SpacerVertical />
      <Col alignItems="center" margin="auto" width="calc(100% - 40px)" maxWidth="800px">
        {currentUser.isSuperadmin && (
          <Button
            alignSelf="start"
            color="var(--on-surface)"
            icon={BackArrowIcon}
            onClick={() => goBack('/agencies')}
          >
            Back
          </Button>
        )}
        <SpacerVertical large />
        <Row alignItems="center">
          <Avatar size="100" src={agency.picture} name={agency.name} />
          <SpacerHorizontal />
          <Block>
            <H1 size="6">{agency.name}</H1>
            <SpacerVertical tiny />
            <Block fontSize="14px" color="var(--on-surface-light)">
              {agency.legalName}
            </Block>
          </Block>
          <SpacerHorizontal />
          <PopupMenuIconButton
            icon={MoreIcon}
            options={[
              {
                label: 'Edit details',
                onClick: () => routeTo(`/agency/${agency.id}/edit-details`)
              },
              {
                label: 'Manage access',
                onClick: () => routeTo(`/agency/${agency.id}/manage-access`)
              }
            ]}
          />
        </Row>
        <SpacerVertical large />

        {agency.voices.length === 0 && !agencyType && (
          <Col gap="24px">
            <H2 size="5">Which describes your company?</H2>
            <Col gap="16px" alignItems="center">
              <Button loading={isUpdatingAgency} onClick={() => createFreelancerVoice()} contained>
                I'm a freelancer
              </Button>
              <Button onClick={() => setAgencyType('agency')} contained>
                We're an agency
              </Button>
            </Col>
          </Col>
        )}

        {(agency.voices.length > 0 || agencyType === 'agency') && (
          <Block width="100%">
            <Row alignItems="center">
              <H2 size="4">Voices</H2>
              <Block flex="1" />
              <SpacerHorizontal small />
              {agency.link && (
                <Fragment>
                  <IconButton
                    flexShrink="0"
                    icon={LinkIcon}
                    onClick={() => routeTo(`/agency/${agency.id}/create-link`)}
                  />
                  <SpacerHorizontal small />
                </Fragment>
              )}
              <Button
                contained
                icon={AddIcon}
                onClick={() => routeTo(`/agency/${agency.id}/invite-voice`)}
              >
                Invite voice
              </Button>
              <SpacerHorizontal small />
              <Button onClick={() => routeTo(`/agency/${agency.id}/create-voice`)}>
                Create voice
              </Button>
            </Row>
            <SpacerVertical />
            <VoiceListing agency={agency} currentUser={currentUser} />
            {agency.voiceInvitations.length > 0 && (
              <Fragment>
                <SpacerVertical large />
                <H2 size="4">Invitations</H2>
                <SpacerVertical />
                <InvitationListing agency={agency} />
              </Fragment>
            )}
          </Block>
        )}
        <SpacerVertical huge />
      </Col>
      <Route path="/agency/:agencyId/manage-access" component={ManageAccess} />
      <Route path="/agency/:agencyId/edit-details" component={EditDetails} />
      <Route path="/agency/:agencyId/invite-voice" component={InviteVoice} />
      <Route path="/agency/:agencyId/create-voice" component={CreateVoice} />
      <Route path="/agency/:agencyId/create-link" component={CreateLink} />
      <Route path="/agency/:agencyId/remove-voice/:userId" component={RemoveVoice} />
      <Route
        path="/agency/:agencyId/delete-invitation/:invitationId"
        component={DeleteVoiceInvitation}
      />
    </Fragment>
  )
}

export default connect('currentUser, agency, isUpdatingAgency')(Agency)
