import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  AddIcon,
  Button,
  Checkbox,
  EditIcon,
  H2,
  NativeSelect,
  P,
  SelectChip,
  SpacerVertical,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { showSnackbar, updateUser } from '../../actions'

import { genderOptions, yearOfBirthOptions } from '../options'

import AddLanguage from './AddLanguage'
import Section from '../Section'
import AddMicrophone from './AddMicrophone'
import Avatar from '../Avatar'
import PictureDialog from '../PictureDialog'
import ErrorMessage from '../ErrorMessage'

type Props = {
  user: User
  currentUser: User
  isUpdatingUser: boolean
}

const VoiceProfile = ({ currentUser, user, isUpdatingUser }: Props) => {
  const [name, setName] = useState(user.name)
  const [picture, setPicture] = useState(user.picture)
  const [gender, setGender] = useState(user.gender || '')
  const [yearOfBirth, setYearOfBirth] = useState(user.yearOfBirth)
  const [languages, setLanguages] = useState(user.languages || [])
  const [videoGameCredentials, setVideoGameCredentials] = useState(user.videoGameCredentials)
  const [notes, setNotes] = useState(user.notes)

  const [nameError, setNameError] = useState('')
  const [genderError, setGenderError] = useState('')
  const [pictureError, setPictureError] = useState('')
  const [yearOfBirthError, setYearOfBirthError] = useState('')
  const [languageError, setLanguageError] = useState('')

  const addLanguage = (language: Language) => {
    setLanguages((languages) => [...languages, language])
  }

  const removeLanguage = (language: Language) => {
    setLanguages((languages) =>
      languages.filter((l) => l.language !== language.language || l.accent !== language.accent)
    )
  }

  useEffect(() => {
    const timeout = setTimeout(save, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [name, videoGameCredentials, languages, notes, gender, yearOfBirth])

  useEffect(() => {
    if (name && name.trim() !== '') {
      setNameError('')
    }
    if (gender && gender.trim() !== '') {
      setGenderError('')
    }
    if (yearOfBirth) {
      setYearOfBirthError('')
    }
    if (languages && languages.length > 0) {
      setLanguageError('')
    }
    if (picture) {
      setPictureError('')
    }
  }, [name, picture, gender, languages, yearOfBirth])

  const validateAndSave = async () => {
    let skipSave
    let errorId

    if (!picture) {
      setPictureError('Please select picture')
      errorId = 'voice-profile'
    } else {
      setPictureError('')
    }

    if (!name || name.trim() === '') {
      setNameError('Please enter name')
      errorId = 'voice-profile'
      skipSave = true
    } else {
      setNameError('')
    }

    if (!gender || gender.trim() === '') {
      setGenderError('Please select gender')
      errorId = 'voice-profile'
      skipSave = true
    } else {
      setGenderError('')
    }

    if (!yearOfBirth) {
      setYearOfBirthError('Please select year of birth')
      errorId = 'voice-profile'
      skipSave = true
    } else {
      setYearOfBirthError('')
    }

    if (!languages || languages.length === 0) {
      setLanguageError('Please select languages')
      errorId = 'voice-profile'
      skipSave = true
    } else {
      setLanguageError('')
    }

    if (errorId) {
      const el = document.querySelector(`#${errorId}`)
      el?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    if (skipSave) {
      return
    }

    return save()
  }

  const save = async () => {
    const params: Partial<User> = {
      name: name && name.trim() !== '' ? name : undefined,
      videoGameCredentials,
      languages,
      notes
    }
    if (gender) {
      params.gender = gender
    }
    if (yearOfBirth) {
      params.yearOfBirth = yearOfBirth
    }
    return updateUser(params)
  }

  const handleSave = async () => {
    if (await validateAndSave()) {
      showSnackbar('Voice profile saved')
    }
  }

  const closeDialog = () => goBack(`/user/${user.id}`)

  const updatePicture = async (url: string) => {
    await updateUser({ picture: url })
    setPicture(url)
    return true
  }

  const removePicture = async () => {
    await updateUser({ picture: '' })
    setPicture('')
    return true
  }

  return (
    <Section>
      <Block props={{ id: 'picture' }}>
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() => routeTo(`/user/${user.id}/picture`)}
          size={150}
          src={picture}
          name={name}
        />
        {pictureError && (
          <Block marginLeft="15px">
            <SpacerVertical small />
            <ErrorMessage>{pictureError}</ErrorMessage>
          </Block>
        )}
      </Block>
      <SpacerVertical />
      <TextField
        id="name"
        width="100%"
        label="Name"
        value={name}
        onChange={setName}
        errorText={nameError}
      />
      <SpacerVertical />

      <NativeSelect
        label="Gender"
        width="100%"
        value={gender}
        onChange={setGender}
        options={genderOptions}
        errorText={genderError}
        infoText="Please state the gender your voice is most often associated with when recording"
      />
      <SpacerVertical />
      <NativeSelect
        label="Year of birth"
        width="100%"
        value={yearOfBirth}
        onChange={(value: string) => setYearOfBirth(parseFloat(value))}
        options={yearOfBirthOptions}
        errorText={yearOfBirthError}
      />
      <SpacerVertical large />
      <H2 size="7">Languages</H2>
      <SpacerVertical />
      <Row alignItems="center" flexWrap="wrap" gap={10}>
        {languages.map((lang) => {
          let value = lang.language
          if (lang.accent) {
            value += ` (${lang.accent})`
          }
          return <SelectChip value={value} onClear={() => removeLanguage(lang)} />
        })}
        <SelectChip
          icon={AddIcon}
          label="Add language"
          onClick={() => routeTo(`/user/${user.id}/add-language`)}
        />
      </Row>
      {languageError && (
        <Block marginLeft="15px">
          <SpacerVertical small />
          <ErrorMessage>{languageError}</ErrorMessage>
        </Block>
      )}
      <SpacerVertical large />
      <H2 size="7">Credentials</H2>
      <SpacerVertical />
      <TextArea
        width="100%"
        label="Video game credentials (optional)"
        value={videoGameCredentials}
        onInput={setVideoGameCredentials}
      />
      {currentUser.isSuperadmin && (
        <Fragment>
          <SpacerVertical />
          <TextArea
            width="100%"
            label="Notes (only visible to super admins)"
            value={notes}
            onInput={setNotes}
          />
        </Fragment>
      )}
      <SpacerVertical />
      <Row alignItems="center" justifyContent="space-between">
        <Button contained loading={isUpdatingUser} onClick={handleSave}>
          Save
        </Button>
      </Row>
      <Route
        path="/user/:userId/add-language"
        render={(props: any) => (
          <AddLanguage {...props} onClose={closeDialog} onAdd={addLanguage} />
        )}
      />

      <Route
        path="/user/:userId/picture"
        render={() => (
          <PictureDialog
            title="User picture"
            url={picture}
            name={name}
            onUpdate={updatePicture}
            onRemove={removePicture}
            onClose={() => goBack(`/user/${user.id}/edit-details`)}
          />
        )}
      />
    </Section>
  )
}

export default connect('currentUser, user, isUpdatingUser')(VoiceProfile as any)
