import { h } from 'preact'
import { Block } from 'jsxstyle/preact'
import { Avatar as BongoAvatar, IconButton } from '@sodra/bongo-ui'

import { getCropperUrl } from '../get-cropper-url'

const Avatar = ({ size = 50, src, actionIcon, onActionClick, ...rest }) => {
  const cropperUrl = src ? getCropperUrl({ url: src, width: size, height: size }) : undefined
  if (actionIcon) {
    return (
      <Block position="relative" width={`${size}px`}>
        <BongoAvatar src={cropperUrl} size={size} {...rest} />
        <IconButton
          outlined
          backgroundColor="var(--surface)"
          color="var(--on-surface)"
          icon={actionIcon}
          position="absolute"
          right="0"
          bottom="0"
          onClick={onActionClick}
        />
      </Block>
    )
  }
  return <BongoAvatar src={cropperUrl} size={size} {...rest} />
}

export default Avatar
