import { Checkbox, H2, P, SpacerVertical } from '@sodra/bongo-ui'
import { useMedia } from '@sodra/use-media'
import { Block, Grid } from 'jsxstyle/preact'
import { Fragment, h, JSX } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { updateUser } from '../../actions'
import Section from '../Section'

type Props = {
  user: User
}

export function Rates({ user }: Props) {
  const isNarrow = useMedia(['(max-width: 600px)'], [true], false)

  const [equityRate, setEquityRate] = useState<EquityRate[]>(user.equityRate ?? [])

  const handleEquityRateChange = (type: EquityRate) => {
    setEquityRate((previous) => {
      if (previous.includes(type)) {
        return previous.filter((val) => val !== type)
      } else {
        return [...previous, type]
      }
    })
  }

  const save = async () => {
    const params: Partial<User> = {
      equityRate
    }
    return updateUser(params)
  }

  useEffect(() => {
    const timeout = setTimeout(save, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [equityRate])

  const ColumnHeader = ({
    textAlign = 'center',
    children
  }: {
    textAlign?: TextAlignProperty
    children: JSX.Element[] | JSX.Element | string
  }) => (
    <Block fontSize="14px" lineHeight="15px" color="var(--on-surface-light)" textAlign={textAlign}>
      {children}
    </Block>
  )

  return (
    <Section>
      <P>
        Compensation levels are set according to the budget of the game production and the
        compensations are in line with the recommendations from the British actors’ union{' '}
        <a href="https://www.equity.org.uk/" target="_blank">
          Equity
        </a>
        .
      </P>
      <SpacerVertical />
      <P>
        Please make sure to opt out of any segments that you do not wish to get job offerings from.
      </P>
      <SpacerVertical large />
      {!isNarrow && (
        <Grid gridTemplateColumns="1.5fr repeat(3, 1fr)" alignItems="center">
          <Block></Block>
          <ColumnHeader textAlign="left">Game budget</ColumnHeader>
          <ColumnHeader>Buyout compensation*</ColumnHeader>
          <ColumnHeader>Hourly compensation</ColumnHeader>

          <Checkbox
            onChange={(val: string) => handleEquityRateChange('standard')}
            label="Standard"
            checked={equityRate.includes('standard')}
            marginRight="10px"
          />
          <Block>More than €5M</Block>
          <Block justifySelf="center">€360</Block>
          <Block justifySelf="center">€360 / hour</Block>

          <Checkbox
            onChange={(val: string) => handleEquityRateChange('indie')}
            label="Indie"
            checked={equityRate.includes('indie')}
            marginRight="10px"
          />
          <Block>Between €0.5M–5M</Block>
          <Block justifySelf="center">€240</Block>
          <Block justifySelf="center">€240 / hour</Block>

          <Checkbox
            onChange={(val: string) => handleEquityRateChange('micro')}
            label="Micro"
            checked={equityRate.includes('micro')}
            marginRight="10px"
          />
          <Block>Less than €0.5M</Block>
          <Block justifySelf="center">€210</Block>
          <Block justifySelf="center">€210 / hour</Block>
        </Grid>
      )}

      {isNarrow && (
        <Fragment>
          <Grid
            gridTemplateRows="repeat(4, auto)"
            gridTemplateColumns="1fr 1fr"
            columnGap="10px"
            rowGap="10px"
            alignItems="center"
          >
            <Checkbox
              onChange={(val: string) => handleEquityRateChange('standard')}
              label="Standard"
              checked={equityRate.includes('standard')}
              margin="-10px 0"
              gridColumn="1/-1"
            />
            <ColumnHeader textAlign="right">Game budget</ColumnHeader>
            <Block>More than €5M</Block>
            <ColumnHeader textAlign="right">Buyout compensation*</ColumnHeader>
            <Block>€360</Block>
            <ColumnHeader textAlign="right">Hourly compensation</ColumnHeader>
            <Block>€360 / hour</Block>

            <Checkbox
              onChange={(val: string) => handleEquityRateChange('indie')}
              label="Indie"
              checked={equityRate.includes('indie')}
              margin="-10px 0"
              gridColumn="1/-1"
              marginTop="10px"
            />
            <ColumnHeader textAlign="right">Game budget</ColumnHeader>
            <Block>Between €0.5M–5M</Block>
            <ColumnHeader textAlign="right">Buyout compensation*</ColumnHeader>
            <Block>€240</Block>
            <ColumnHeader textAlign="right">Hourly compensation</ColumnHeader>
            <Block>€240 / hour</Block>

            <Checkbox
              onChange={(val: string) => handleEquityRateChange('micro')}
              label="Micro"
              checked={equityRate.includes('micro')}
              margin="-10px 0"
              gridColumn="1/-1"
              marginTop="10px"
            />
            <ColumnHeader textAlign="right">Game budget</ColumnHeader>
            <Block>Less than €0.5M</Block>
            <ColumnHeader textAlign="right">Buyout compensation*</ColumnHeader>
            <Block>€210</Block>
            <ColumnHeader textAlign="right">Hourly compensation</ColumnHeader>
            <Block>€210 / hour</Block>
          </Grid>
          <SpacerVertical />
        </Fragment>
      )}

      <SpacerVertical />

      <P size="2" color="var(--on-surface-light)">
        *) The Buyout is a one-off compensation you receive per game title on top of the hourly
        compensation.
      </P>
    </Section>
  )
}
