import { h } from 'preact'
import { useState } from 'preact/hooks'
import { InlineBlock, Block, Row, Col } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'
import { DownIcon } from './icons'
import { Highlight } from './highlights'
import { Outline } from './Outline'
import { Ripples } from './Ripples'

import { useKeyPressedHighlight } from './use-key-pressed-highlight'

export const SelectButton = ({
  label,
  placeholder,
  selected,
  outline,
  text: typeText,
  onClick,
  clickOnSpacePressed = true,
  disabled,
  props,
  ...styles
}) => {
  const borderRadius = '3px'

  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [keyPressedHighlights, addKeyPressedHighlight] = useKeyPressedHighlight({ borderRadius })

  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const handleClick = (e) => {
    onClick && onClick()
  }

  const handleMouseEnter = (e) => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  const handleFocus = (e) => setFocus(true)

  const handleBlur = (e) => setFocus(false)

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      // Space
      e.preventDefault()
      e.stopPropagation()
      addKeyPressedHighlight()
      onClick && onClick()
    }
  }

  const Icon = (selected && selected.icon) || (placeholder && placeholder.icon)
  const text = (selected && selected.text) || label || (placeholder && placeholder.text)
  const visual = (selected && selected.visual) || (placeholder && placeholder.visual)
  const secondaryText =
    (selected && selected.secondaryText) || (placeholder && placeholder.secondaryText)

  return (
    <InlineBlock
      class={outline ? undefined : 'bui-show-keyboard-focus'}
      position="relative"
      outline="none"
      userSelect="none"
      WebkitUserSelect="none"
      WebkitTapHighlightColor="rgba(0, 0, 0, 0)"
      backgroundColor={!outline && !typeText ? 'var(--container-background)' : undefined}
      boxSizing="border-box"
      borderRadius={borderRadius}
      height={label ? '55px' : visual ? '50px' : '40px'}
      padding="0 32px 0 15px"
      cursor={!disabled ? 'pointer' : 'not-allowed'}
      props={{
        onMouseEnter: !disabled && supportsHover ? handleMouseEnter : null,
        onMouseLeave: !disabled && supportsHover ? handleMouseLeave : null,
        onFocus: !disabled ? handleFocus : null,
        onBlur: !disabled ? handleBlur : null,
        tabIndex: !disabled ? 0 : -1,
        onClick: !disabled ? handleClick : null,
        onKeyDown: !disabled && clickOnSpacePressed ? handleKeyDown : null,
        ...props
      }}
      {...styles}
    >
      {!outline && (hover || focus) && <Highlight borderRadius={borderRadius} opacity="0.15" />}
      {outline && (
        <Block>
          <Outline
            time={0}
            borderRadius={borderRadius}
            color={!disabled ? 'var(--container-outline)' : 'var(--container-outline-lighter)'}
          />
          {(hover || focus) && (
            <Outline borderRadius={borderRadius} color="var(--on-surface-light)" />
          )}
          {focus && <Outline borderRadius={borderRadius} color="var(--accent)" width="3px" />}
        </Block>
      )}
      {!disabled && keyPressedHighlights}
      <Ripples borderRadius={borderRadius} disabled={disabled} />
      <Row
        height="100%"
        alignItems="center"
        color={selected ? 'var(--on-surface)' : 'var(--on-surface-light)'}
        opacity={disabled ? 0.33 : 1}
      >
        {Icon && (
          <Block width="40px">
            <Icon />
          </Block>
        )}
        {visual && <Block width="40px">{visual}</Block>}
        <Col
          paddingTop={label ? '10px' : 0}
          opacity={selected || !label ? 1 : 0}
          transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
        >
          <Block>{text}</Block>
          {secondaryText && (
            <Block fontSize="14px" lineHeight="16px" color="var(--on-surface-light)">
              {secondaryText}
            </Block>
          )}
        </Col>
      </Row>
      {label && (
        <Block
          position="absolute"
          top="18px"
          left={visual ? '55px' : '15px'}
          fontSize="16px"
          lineHeight="18px"
          fontStyle={disabled ? 'italic' : 'normal'}
          color={'var(--on-surface-light)'}
          opacity={disabled ? 0.33 : 1}
          transition="transform 0.18s cubic-bezier(0, 0, .2, 1)"
          transform-origin="top left"
          transform={selected ? `scale(.75) translateY(${secondaryText ? -20 : -18}px)` : undefined}
        >
          {label}
        </Block>
      )}

      <Block
        position="absolute"
        right="5px"
        top="50%"
        marginTop="-12px"
        pointerEvents="none"
        opacity={disabled ? 0.33 : 1}
      >
        <DownIcon fill={disabled ? 'var(--on-surface-light)' : 'var(--on-surface)'} />
      </Block>
    </InlineBlock>
  )
}
