import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'
import { useEffect } from 'preact/hooks'
import { H1, P } from '@sodra/bongo-ui'
import { Redirect, Route, Switch } from '@sodra/prutt'
import { Block, Col, Row } from 'jsxstyle/preact'

import { useLocalStorageState } from '../use-local-storage-state'

import Agencies from './Agencies'
import Agency from './Agency'
import User from './User'
import MyAccount from './MyAccount'
import AllVoices from './Users'
import { useMedia } from '@sodra/use-media'
import SessionPage from './SessionPage'
import Header from './Header'

import prisoner from '../prisoner.svg'

const LoggedIn = ({ currentUser }) => {
  const [defaultAgencyId, setDefaultAgencyId] = useLocalStorageState(
    `speechless:${currentUser.id}:agencyId`,
    currentUser.agencies[0]?.id
  )
  const isNarrow = useMedia(['(max-width: 600px)'], [true], false)

  useEffect(() => {
    if (
      currentUser.agencies.length > 0 &&
      !currentUser.agencies.some((a) => a.id === defaultAgencyId)
    ) {
      setDefaultAgencyId(currentUser.agencies[0].id)
    }
  }, [currentUser])

  const headerVisible = !window.location.pathname.startsWith('/session')

  // if (currentUser.isSuperadmin) {
  //   return (
  //     <Block position="fixed" left="0" right="0" bottom="0" top="0" background="#534853"
  //       display="grid" placeItems="center"
  //     >
  //       <Col alignItems="center">
  //         <Row><img style={{ height: "300px", width: "300px" }} src={prisoner} /></Row>
  //         <Row>
  //           <Col gap="10px" >
  //             <H1 size="4">Hello superadmin!</H1>
  //             <P>This application is discontinued,<br />please use the admin panel:</P>
  //             <P><a href="https://admin.speechless.games">Speechless admin</a></P>
  //           </Col>
  //         </Row>
  //       </Col>
  //     </Block>
  //   )
  // }

  return (
    <Fragment>
      {headerVisible && <Header setDefaultAgencyId={setDefaultAgencyId} />}

      <Block marginTop={headerVisible ? '70px' : undefined}>
        <Switch>
          {currentUser.isSuperadmin && <Route path="/agencies" component={Agencies} />}
          {currentUser.isSuperadmin && <Route path="/voices" component={AllVoices} />}
          <Route path="/agency/:agencyId" component={Agency} />
          <Route path="/session/:userId/:dialogue" component={SessionPage} />
          <Route path="/user/:userId" component={User} />
          <Route path="/account" component={MyAccount} />
          {currentUser.isSuperadmin && <Redirect to="/agencies" />}
          {currentUser.agencies.length > 0 && <Redirect to={`/agency/${defaultAgencyId}`} />}
          {currentUser.agency && <Redirect to={`/user/${currentUser.id}`} />}
          <Redirect to={`/user/${currentUser.id}`} />
        </Switch>
      </Block>
    </Fragment>
  )
}

export default connect('currentUser')(LoggedIn)
