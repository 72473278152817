import { useState, useEffect } from 'preact/hooks'

export const useHasScroll = () => {
  const [hasScroll, setHasScroll] = useState(window.scrollY > 0)

  useEffect(() => {
    setHasScroll(window.scrollY > 0)
    const handleScroll = () => {
      setHasScroll(window.scrollY > 0)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return hasScroll
}
