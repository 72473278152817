import { h, cloneElement } from 'preact'
import { useState, useEffect } from 'preact/hooks'

import { history, routeTo } from './history'
import { exec, test } from './match'

export { routeTo, goBack, history } from './history'

const renderComponent = (component, render, props) => {
  if (component) {
    return h(component, props)
  }
  if (render) {
    return render(props)
  }
  return null
}

export const Router = ({ children }) => {
  const [location, setLocation] = useState(history.location)
  useEffect(() => {
    setLocation(history.location)
    return history.listen(setLocation)
  }, [])
  return cloneElement(children)
}

export const Route = ({ path, exact, component, render }) => {
  const state = history.location.state || {}
  if (!path) {
    return renderComponent(component, render, state)
  }
  const params = exec(path, exact)
  if (params) {
    return renderComponent(component, render, { ...state, ...params })
  }
  return null
}

export const Switch = ({ children }) => {
  const arr = Array.isArray(children) ? children : [children]
  for (let child of arr) {
    if (!child) {
      continue
    }
    const path = child.props.path
    const exact = child.props.exact
    if (!path || test(path, exact)) {
      return cloneElement(child)
    }
  }
  return null
}

export const Redirect = ({ to }) => {
  useEffect(() => {
    routeTo(to, true)
  }, [])
  return null
}
