import { h } from 'preact'
import { Block } from 'jsxstyle/preact'

const pSizes = ['1', '2']
export const P = ({ children, size = '1', props, ...styles }) => {
  if (!pSizes.includes(size)) {
    throw new Error(`Invalid paragraph size ${size}. Must be one of ${pSizes}`)
  }
  return (
    <Block component="p" class={`body-${size}`} margin="0" padding="0" props={props} {...styles}>
      {children}
    </Block>
  )
}

export const Body1 = (props) => <P size="1" {...props} />

export const Body2 = (props) => <P size="2" {...props} />

const headingLevels = ['1', '2', '3', '4', '5', '6', '7']
const headingSizes = ['1', '2', '3', '4', '5', '6', '7']
const H = ({ children, level = '1', size = '6', props, ...styles }) => {
  if (!headingLevels.includes(level)) {
    throw new Error(`Invalid heading level ${level}. Must be one of ${headingLevels}`)
  }
  if (!headingSizes.includes(size)) {
    throw new Error(`Invalid heading size ${size}. Must be one of ${headingSizes}`)
  }
  return (
    <Block
      component={`h${level}`}
      class={`heading-${size}`}
      margin="0"
      padding="0"
      props={props}
      {...styles}
    >
      {children}
    </Block>
  )
}

export const H1 = ({ size = '1', ...rest }) => {
  return <H level="1" size={size} {...rest} />
}

export const H2 = ({ size = '2', ...rest }) => {
  return <H level="2" size={size} {...rest} />
}

export const H3 = ({ size = '3', ...rest }) => {
  return <H level="3" size={size} {...rest} />
}

export const H4 = ({ size = '4', ...rest }) => {
  return <H level="4" size={size} {...rest} />
}

export const H5 = ({ size = '5', ...rest }) => {
  return <H level="5" size={size} {...rest} />
}

export const H6 = ({ size = '6', ...rest }) => {
  return <H level="6" size={size} {...rest} />
}

export const H7 = ({ size = '7', ...rest }) => {
  return <H level="7" size={size} {...rest} />
}
