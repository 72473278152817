import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'

import { clearError } from '../actions'

const Error = ({ error }) => {
  if (error) {
    return (
      <BasicDialog
        title="Oooops!"
        onClose={clearError}
        action1Text="Close"
        onAction1Click={clearError}
      >
        {error.message}
      </BasicDialog>
    )
  }
}

export default connect('error')(Error)
