import { h } from 'preact'
import { getBackgroundColor, getBackgroundOpacity, getColor } from './progress-colors'
import './ProgressCircular.css'

const ProgressCircularInderetminate = ({ size, color: colorProp }) => {
  const color = getColor({ colorProp })
  return (
    <svg
      class="bui"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="spinner" stroke={color}>
        <circle class="loading" fill="none" stroke-width="4" cx="24" cy="24" r="20"></circle>
      </g>
    </svg>
  )
}

const ProgressCircularDeterminate = ({
  size,
  color: colorProp,
  backgroundColor: backgroundColorProp,
  backgroundOpacity: backgroundOpacityProp,
  value = 0
}) => {
  const color = getColor({ colorProp })
  const backgroundColor = getBackgroundColor({ colorProp, backgroundColorProp })
  const backgroundOpacity = getBackgroundOpacity({
    colorProp,
    backgroundColorProp,
    backgroundOpacityProp
  })

  const radius = 20
  const circumference = radius * 2 * Math.PI
  const filled = (value / 1) * circumference
  const gap = circumference - filled
  const dashArray = `${filled} ${gap}`
  return (
    <svg
      class="bui"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill="none"
        stroke={backgroundColor}
        stroke-opacity={backgroundOpacity}
        stroke-width="4"
        cx="24"
        cy="24"
        r={radius}
      ></circle>
      <circle
        fill="none"
        stroke={color}
        stroke-width="4"
        stroke-dasharray={`${dashArray}`}
        stroke-dashoffset="0"
        stroke-linecap="butt"
        cx="24"
        cy="24"
        r={radius}
        transform="translate(24, 24) rotate(-90) translate(-24 -24)"
      ></circle>
    </svg>
  )
}

export const ProgressCircular = ({
  size = 48,
  color,
  backgroundColor,
  backgroundOpacity,
  value
}) => {
  if (value >= 0) {
    return (
      <ProgressCircularDeterminate
        size={size}
        color={color}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        value={value}
      />
    )
  } else {
    return <ProgressCircularInderetminate size={size} color={color} />
  }
}
