import { h, cloneElement } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'

export const Snackbar = ({
  children,
  bottomOffset = '10px',
  leftOffset = '10px',
  rightOffset = '10px'
}) => {
  const [activeSnackbar, setActiveSnackbar] = useState()

  const dismissActiveSnackbar = () => {
    setActiveSnackbar(
      cloneElement(children[0], {
        onClosed: () => {
          setActiveSnackbar(undefined)
          children[0].props.onClosed()
        },
        dismiss: true
      })
    )
  }

  useEffect(() => {
    if (!children || (children.length === 0 && activeSnackbar)) {
      // Dismiss active snackbar
      dismissActiveSnackbar()
    }
    if (children && children.length > 0) {
      if (activeSnackbar && children[0].props.dismiss) {
        // Dismiss current active snackbar
        dismissActiveSnackbar()
      }
      if (!activeSnackbar) {
        setActiveSnackbar(
          cloneElement(children[0], {
            onClosed: () => {
              setActiveSnackbar(undefined)
              children[0].props.onClosed()
            }
          })
        )
      }
    }
  }, [children])

  return (
    <Block class="bui" position="fixed" bottom={bottomOffset} left={leftOffset} right={rightOffset}>
      {activeSnackbar}
    </Block>
  )
}
