import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'

export const useSuggestIndex = (el, items, onSelect, onClose) => {
  const [index, setIndex] = useState(-1)

  useEffect(() => {
    if (el && items && items.length > 0) {
      const handleKeyDown = (e) => {
        if (e.key === 'Escape' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          onClose()
        }
        if (e.key === 'ArrowDown') {
          e.preventDefault()
          setIndex((index) => (index === items.length - 1 ? 0 : index + 1))
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault()
          setIndex((index) => {
            if (index <= 0) {
              return items.length - 1
            }
            return index - 1
          })
        }
        if (e.key === 'Enter' && index >= 0) {
          e.preventDefault()
          onSelect(items[index], index)
        }
      }

      el.addEventListener('keydown', handleKeyDown)
      return () => {
        el.removeEventListener('keydown', handleKeyDown)
      }
    } else {
      setIndex(-1)
    }
  }, [el, items, onSelect, onClose, index])

  return index
}
