import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'

import { Timer, useTimer } from './Timer'
import { Waveform } from './Waveform'

import { colors } from '../../colors'

type Props = {
  stream: MediaStream | null
  mediaRecorderState: string | null
  waveFormHeight?: string
  flex?: string
}

const WaveformAndTimer = ({
  stream,
  mediaRecorderState,
  waveFormHeight = '100px',
  flex
}: Props) => {
  const timer = useTimer()

  // Timer
  useEffect(() => {
    if (mediaRecorderState === 'recording') {
      timer.start()
      return () => {
        timer.stop()
      }
    }
  }, [mediaRecorderState])

  const isRecording = mediaRecorderState === 'recording'
  const timerColor = isRecording ? colors['--error'] : colors['--on-surface']

  return (
    <Row alignItems="center" gap={10} flex={flex}>
      <Block flex="1">
        {stream && (
          <Waveform
            color={colors['--error']}
            height={waveFormHeight}
            active={isRecording}
            stream={stream}
          />
        )}
      </Block>
      <Timer millis={timer.millis} color={timerColor} />
    </Row>
  )
}

export default WaveformAndTimer
