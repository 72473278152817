import { h, Fragment } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'

import { Highlight } from './highlights'
import { BorderBottom } from './BorderBottom'
import { InputInfo } from './InputInfo'
import { InputError } from './InputError'
import { CharacterCounter } from './CharacterCounter'
import { Outline } from './Outline'
import { getUniqueId } from './get-unique-id'
import { SpacerHorizontal } from './spacers'

import { useSupportsHover } from './use-supports-hover'
import { useSuggestIndex } from './use-suggest-index'
import { Suggest } from './Suggest'
import { useCursorPosition } from './use-cursor-position'
import { useSubmitOnEnter } from './use-submit-on-enter'
import { useAddNewlineOnAltEnter } from './use-add-newline-on-alt-enter'

export const TextArea = ({
  label,
  placeholder = '',
  value = '',
  autofocus,
  onInput,
  onChange,
  onEnterSubmit,
  disabled = false,
  readonly,
  infoText,
  errorText,
  outlined,
  showCharacterCounter = false,
  maxCharacters,
  autoHeight,
  minRows,
  maxRows,
  onFocus,
  onBlur,
  suggest,
  onSuggestSelect,
  onSuggestClose,
  textAreaRef,
  props,
  ...style
}) => {
  const outerEl = useRef()
  const textArea = useRef()
  const [focus, setFocus] = useState(false)
  const [hover, setHover] = useState(false)
  const [ariaDescribedById, setAriaDescribedById] = useState()
  const [resizeTimeout, setResizeTimeout] = useState()

  const setRef = (el) => {
    if (textAreaRef) {
      if (typeof textAreaRef === 'function') {
        textAreaRef(el)
      } else {
        textAreaRef.current = el
      }
    }
    textArea.current = el
  }

  const handleSuggestSelect = (params) => {
    onSuggestSelect(params)
    // Make sure text area gets focus after suggest select
    // so the user can continue typing
    // This is needed when clicking/tapping a suggest item
    if (textArea.current) {
      textArea.current.focus()
    }
  }

  const suggestIndex = useSuggestIndex(
    textArea.current,
    suggest,
    handleSuggestSelect,
    onSuggestClose
  )
  const caretPos =
    suggest && suggest.length > 0 && textArea.current ? textArea.current.selectionStart : undefined
  const cursorPosition = useCursorPosition(textArea.current, caretPos)

  const supportsHover = useSupportsHover()
  const handleAddNewLineOnEnter = (value, e) => {
    if (autoHeight) {
      setNeedsResize(true)
    }
    if (onInput) {
      onInput(value, e)
    }
  }
  useAddNewlineOnAltEnter(
    textArea.current,
    handleAddNewLineOnEnter,
    supportsHover && suggestIndex < 0
  )
  const handleEnterSubmit = (value) => {
    setNeedsResize(true)
    onEnterSubmit(value)
  }
  useSubmitOnEnter(
    textArea.current,
    onEnterSubmit ? handleEnterSubmit : undefined,
    supportsHover && suggestIndex < 0
  )

  useEffect(() => {
    if (autofocus) {
      textArea.current.focus()
    }
  }, [])

  useEffect(() => {
    if (autoHeight) {
      window.addEventListener('resize', handleWindowResize)
      return () => {
        window.removeEventListener('resize', handleWindowResize)
      }
    }
  }, [autoHeight])

  const [needsResize, setNeedsResize] = useState(autoHeight)
  setNeedsResize(autoHeight && textArea.current && value && value !== textArea.current.value)
  useEffect(() => {
    if (needsResize) {
      resize()
      setNeedsResize(false)
    }
  }, [needsResize])

  useEffect(() => {
    if (infoText || errorText) {
      setAriaDescribedById(getUniqueId('bui-aria-describedby'))
    }
  }, [infoText, errorText])

  const resize = () => {
    if (textArea.current) {
      textArea.current.style.height = '1px'
      if (label) {
        textArea.current.style.height = `${textArea.current.scrollHeight - 10}px`
      } else {
        textArea.current.style.height = `${textArea.current.scrollHeight - 17 - 17}px`
      }
    }
  }

  const handleInput = (e) => {
    if (autoHeight) {
      resize()
    }
    if (onInput) {
      onInput(e.target.value, e)
    }
  }

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value, e)
    }
  }

  const handleFocus = (e) => {
    setFocus(true)
    if (onFocus) {
      onFocus(e)
    }
  }

  const handleBlur = (e) => {
    setFocus(false)
    if (onBlur) {
      onBlur(e)
    }
  }

  const handleWindowResize = (e) => {
    if (resizeTimeout) {
      clearTimeout(resizeTimeout)
    }
    setResizeTimeout(
      setTimeout(() => {
        resize()
      }, 360)
    )
  }

  let minHeight
  if (minRows) {
    minHeight = minRows * 20 + 'px'
  } else if (!autoHeight) {
    if (maxRows) {
      const maxHeight = maxRows * 20
      minHeight = maxHeight < 150 ? maxHeight + 'px' : '150px'
    } else {
      minHeight = '150px'
    }
  }
  const maxHeight = maxRows ? maxRows * 20 + 'px' : undefined
  const borderRadius = '3px'
  const numCharacters = value ? value.length : 0

  let suggestPosition
  if (cursorPosition && outerEl.current) {
    const bounds = outerEl.current.getBoundingClientRect()
    suggestPosition = {
      x: bounds.x + cursorPosition.x,
      y: bounds.y + cursorPosition.y - textArea.current.scrollTop
    }
  }

  return (
    <Block component="label" position="relative" class="bui" {...style}>
      <Col height="100%" opacity={disabled ? 0.33 : 1}>
        <Block
          position="relative"
          height="100%"
          overflow="hidden"
          borderRadius="3px"
          cursor={!disabled && !readonly ? 'text' : 'default'}
          backgroundColor={!outlined && !readonly ? 'var(--container-background)' : undefined}
          outline={focus && readonly ? '3px solid var(--accent)' : undefined}
          props={{
            ref: outerEl
          }}
        >
          {!outlined && (hover || focus) && !readonly && (
            <Highlight borderRadius={borderRadius} opacity="0.15" />
          )}
          {outlined && !readonly && (
            <Block>
              <Outline time={0} borderRadius={borderRadius} />
              {(hover || focus) && (
                <Outline borderRadius={borderRadius} color="var(--on-surface-light)" />
              )}
              {focus && <Outline borderRadius={borderRadius} color="var(--accent)" width="3px" />}
            </Block>
          )}
          <Block
            position="absolute"
            zIndex="1"
            top="18px"
            left="15px"
            fontSize="16px"
            lineHeight="18px"
            fontStyle="normal"
            color={
              focus
                ? 'var(--accent-text)'
                : errorText
                ? 'var(--error-text)'
                : 'var(--on-surface-light)'
            }
            transition="transform .18s cubic-bezier(0, 0, .2, 1)"
            transform-origin="top left"
            transform={focus || value ? 'scale(.75) translateY(-16px)' : ''}
          >
            {label}
          </Block>
          <Block
            boxSizing="unset"
            flex="1"
            component="textarea"
            width="calc(100% - 30px)"
            height="calc(100% - 36px)"
            minHeight={minHeight}
            maxHeight={maxHeight}
            resize="none"
            border="none"
            color="var(--on-surface)"
            placeholderColor="var(--on-surface-light)"
            background="none"
            outline="none"
            fontSize="16px"
            lineHeight="20px"
            padding={label ? '0 15px 10px' : '17px 15px 17px'}
            marginTop={label ? '24px' : 0}
            props={{
              ref: setRef,
              value,
              disabled,
              readonly,
              onFocus: handleFocus,
              onBlur: handleBlur,
              onMouseEnter: supportsHover ? () => setHover(true) : undefined,
              onMouseLeave: supportsHover ? () => setHover(false) : undefined,
              onChange: handleChange,
              onInput: handleInput,
              placeholder: !label || (label && focus) ? placeholder : '',
              'aria-describedby': ariaDescribedById
            }}
            {...props}
          />
          {!disabled && !readonly && (
            <Fragment>
              <BorderBottom color="var(--container-outline)" opacity={hover ? '1' : '0'} />
              <BorderBottom width="3px" color="var(--accent)" opacity={focus ? '1' : '0'} />
            </Fragment>
          )}
        </Block>
        {suggest && suggest.length > 0 && cursorPosition && (
          <Suggest
            items={suggest}
            selectedIndex={suggestIndex}
            onSelect={handleSuggestSelect}
            visible={focus}
            onClose={onSuggestClose}
            position={suggestPosition}
          />
        )}
        <Row padding="0 15px">
          <Block flex="1">
            {!errorText && <InputInfo id={ariaDescribedById}>{infoText}</InputInfo>}
            {errorText && <InputError id={ariaDescribedById}>{errorText}</InputError>}
          </Block>
          {(infoText || errorText) && showCharacterCounter && <SpacerHorizontal />}
          {showCharacterCounter && <CharacterCounter count={numCharacters} max={maxCharacters} />}
        </Row>
      </Col>
    </Block>
  )
}
