import { h, Fragment } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { useMedia } from '@sodra/use-media'
import { useKeyPressedHighlight } from './use-key-pressed-highlight'
import { InlineBlock, Block, Row } from 'jsxstyle/preact'
import { CircularHighlight } from './highlights'
import { Outline } from './Outline'
import { Ripples } from './Ripples'
import { Tooltip } from './Tooltip'
import { Badge } from './Badge'

const getInitials = (name) => {
  if (name) {
    return name
      .split(' ')
      .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
      .join('')
  }
}

export const Avatar = ({
  src,
  name,
  icon: Icon,
  iconColor = 'var(--on-surface)',
  iconBackground = 'var(--surface)',
  iconBackgroundOpacity = 0.8,
  tooltipText,
  badge = false,
  disabled = false,
  onClick,
  focusable = true,
  delayClick = false,
  small,
  large,
  huge,
  size: sizeProp,
  props,
  ...style
}) => {
  const avatar = useRef()

  const borderRadius = '50%'

  const [focus, setFocus] = useState(false)
  const [hover, setHover] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [keyPressedHighlights, addKeyPressedHighlight] = useKeyPressedHighlight({
    color: 'white',
    borderRadius
  })

  const supportsHover = useMedia(['(hover: hover)'], [true], false)
  const clickable = !disabled && onClick

  const [renderBadge, setRenderBadge] = useState(badge)

  useEffect(() => {
    if (badge) {
      setRenderBadge(true)
    }
  }, [badge])

  useEffect(() => {
    if (focus || hover) {
      setShowTooltip(true)
    } else if (!focus && !hover) {
      setShowTooltip(false)
    }
  }, [focus, hover])

  const removeBadge = () => {
    setRenderBadge(badge)
  }

  const handleClick = (e) => {
    if (e) {
      e.stopPropagation()
    }

    setTimeout(
      () => {
        if (onClick) {
          onClick({ type: 'pointer', x: e.clientX, y: e.clientY, target: e.target })
        }
        setFocus(false) // Remove focus after clicking/tapping
        setShowTooltip(false)
      },
      delayClick ? 180 : 0
    )
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault()
      e.stopPropagation()
      addKeyPressedHighlight()
      onClick && onClick({ type: 'keyboard', target: avatar.current })
      setShowTooltip(false)
    }
  }

  const handleMouseOver = (e) => setHover(true)

  const handleMouseOut = (e) => setHover(false)

  const handleFocus = (e) => setFocus(true)

  const handleBlur = (e) => setFocus(false)

  const size = sizeProp ? sizeProp : small ? 24 : huge ? 80 : large ? 60 : 40
  const iconSize = sizeProp ? size * 0.4 : small ? 16 : huge ? 32 : large ? 24 : 24
  const fontSize = sizeProp
    ? `${size * 0.4}px`
    : small
    ? '11px'
    : huge
    ? '32px'
    : large
    ? '24px'
    : '16px'
  const lineHeight = sizeProp
    ? `${size * 0.4}px`
    : small
    ? '11px'
    : huge
    ? '32px'
    : large
    ? '24px'
    : '16px'
  const letterSpacing = sizeProp
    ? `${size * 0.025}px`
    : small
    ? '0.5px'
    : huge
    ? '2px'
    : large
    ? '1.5px'
    : '1px'
  const marginTop = `-${0.05 * size}px`

  const imgStyle = {
    display: 'block',
    border: 'none',
    borderRadius,
    width: size + 'px',
    height: size + 'px',
    ...style
  }

  return (
    <InlineBlock
      class="bui"
      position="relative"
      outline="none"
      userSelect="none"
      WebkitUserSelect="none"
      WebkitTapHighlightColor="rgba(0, 0, 0, 0)"
      pointerEvents={!clickable ? 'none' : 'auto'}
      cursor={clickable ? 'pointer' : undefined}
      verticalAlign="bottom"
      props={{
        ref: avatar,
        onClick: clickable ? handleClick : null,
        onKeyDown: clickable ? handleKeyDown : null,
        onMouseOver: clickable && supportsHover ? handleMouseOver : null,
        onMouseOut: clickable && supportsHover ? handleMouseOut : null,
        onFocus: clickable ? handleFocus : null,
        onBlur: clickable ? handleBlur : null,
        tabIndex: focusable && clickable ? 0 : -1,
        ...props
      }}
      {...style}
    >
      <Block position="relative" borderRadius={borderRadius} overflow="hidden">
        <Block
          fontSize={fontSize}
          lineHeight={lineHeight}
          letterSpacing={letterSpacing}
          borderRadius={borderRadius}
          width={`${size}px`}
          height={`${size}px`}
          filter={disabled ? 'grayscale(1) opacity(0.3)' : ''}
          backgroundColor={!src ? 'var(--selection-control)' : 'none'}
          color={!src ? 'var(--on-selection-control)' : 'auto'}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {src && (
            <img alt={`Avatar of ${name}`} crossorigin="anonymous" src={src} style={imgStyle} />
          )}
          {!src && <Block marginTop={marginTop}>{getInitials(name)}</Block>}
        </Block>
        {Icon && (
          <Fragment>
            <Block
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              backgroundColor={iconBackground}
              opacity={iconBackgroundOpacity}
            />
            <Row
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              alignItems="center"
              justifyContent="center"
            >
              <Icon fill={iconColor} size={iconSize} />
            </Row>
          </Fragment>
        )}
      </Block>
      {hover && <CircularHighlight color="white" />}
      {keyPressedHighlights}
      <Ripples borderRadius={borderRadius} color="white" disabled={!clickable} />
      {focus && (
        <Outline margin="-5px" color="var(--accent)" borderRadius={borderRadius} width="3px" />
      )}
      {renderBadge && <Badge top="-6px" right="-4px" visible={badge} onExitDone={removeBadge} />}
      {tooltipText && <Tooltip visible={showTooltip} generator={avatar} text={tooltipText} />}
    </InlineBlock>
  )
}
