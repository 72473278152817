import { h } from 'preact'
import { useRef, useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'

const CornerHandle = ({ corner = 'top-right', zoomIn, zoomOut }) => {
  const elem = useRef()

  const [focus, setFocus] = useState(false)

  const handleFocus = () => setFocus(true)

  const handleBlur = () => setFocus(false)

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowRight') {
      e.stopPropagation()
      switch (corner) {
        case 'top-left':
          zoomIn('bottom-right')
          break
        case 'top-right':
          zoomOut('bottom-left')
          break
        case 'bottom-left':
          zoomIn('top-right')
          break
        case 'bottom-right':
          zoomOut('top-left')
          break
      }
    }
    if (e.key === 'ArrowLeft') {
      e.stopPropagation()
      switch (corner) {
        case 'top-left':
          zoomOut('bottom-right')
          break
        case 'top-right':
          zoomIn('bottom-left')
          break
        case 'bottom-left':
          zoomOut('top-right')
          break
        case 'bottom-right':
          zoomIn('top-left')
          break
      }
    }
    if (e.key === 'ArrowUp') {
      e.stopPropagation()
      switch (corner) {
        case 'top-left':
          zoomOut('bottom-right')
          break
        case 'top-right':
          zoomOut('bottom-left')
          break
        case 'bottom-left':
          zoomIn('top-right')
          break
        case 'bottom-right':
          zoomIn('top-left')
          break
      }
    }
    if (e.key === 'ArrowDown') {
      e.stopPropagation()
      switch (corner) {
        case 'top-left':
          zoomIn('bottom-right')
          break
        case 'top-right':
          zoomIn('bottom-left')
          break
        case 'bottom-left':
          zoomOut('top-right')
          break
        case 'bottom-right':
          zoomOut('top-left')
          break
      }
    }
  }

  let top, left, right, bottom
  let hlTop, hlLeft, hlRight, hlBottom
  let shdwPos

  switch (corner) {
    case 'top-left':
      top = '2px'
      left = '2px'
      hlTop = '-15px'
      hlRight = '-3px'
      shdwPos = '-5px -5px'
      break
    case 'top-right':
      top = '2px'
      right = '2px'
      hlTop = '-15px'
      hlLeft = '-3px'
      shdwPos = '5px -5px'
      break
    case 'bottom-left':
      bottom = '2px'
      left = '2px'
      hlBottom = '-15px'
      hlRight = '-3px'
      shdwPos = '-5px 5px'
      break
    case 'bottom-right':
      bottom = '2px'
      right = '2px'
      hlBottom = '-15px'
      hlLeft = '-3px'
      shdwPos = '5px 5px'
      break
  }

  return (
    <Block
      position="absolute"
      top={top}
      right={right}
      left={left}
      bottom={bottom}
      outline="none"
      userSelect="none"
      props={{
        ref: elem,
        tabIndex: 0,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onKeyDown: handleKeyDown
      }}
    >
      {focus && (
        <Block
          position="absolute"
          top={hlTop}
          right={hlRight}
          left={hlLeft}
          bottom={hlBottom}
          outline="none"
          width="42px"
          height="42px"
          borderRadius="21px"
          background="rgba(255, 255, 255, 0.25)"
          userSelect="none"
        />
      )}
      <Block boxShadow={`${shdwPos} #fff`} width="24px" height="24px" userSelect="none" />
    </Block>
  )
}

export default CornerHandle
