import { h } from 'preact'
import { BasicDialog, Body1, SpacerVertical } from '@sodra/bongo-ui'

type Props = {
  onGrant: () => void
  onCancel: () => void
}

const AudioPermissionDialog = ({ onGrant, onCancel }: Props) => {
  const grantAccess = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((res) => onGrant())
      .catch((err) => {
        console.log('Unable to get access to microphone', err)
        onCancel()
      })
  }
  return (
    <BasicDialog
      title="Grant access to microphone"
      primaryActionText="Grant access"
      onPrimaryActionClick={() => grantAccess()}
      action1Text="Cancel"
      onAction1Click={onCancel}
      onClose={onCancel}
    >
      <SpacerVertical tiny />
      <Body1>You need to grant access to your microphone to be able to record audio.</Body1>
      <SpacerVertical tiny />
    </BasicDialog>
  )
}

export default AudioPermissionDialog
