import { h } from 'preact'
import { useState } from 'preact/hooks'
import {
  Dialog,
  SpacerVertical,
  EditIcon,
  DeleteIcon,
  CloseIcon,
  BackArrowIcon,
  ProgressCircular
} from '@sodra/bongo-ui'
import { useMedia } from '@sodra/use-media'
import { ImageCropper } from '@sodra/image-cropper'

import { Block, Col } from 'jsxstyle/preact'
import Avatar from '../Avatar'
import { UploadImage } from './UploadImage'
import { uploadCroppedImage } from '../../actions'

const PictureDialog = ({
  title: titleProps = 'Picture',
  url: urlProps,
  name,
  onUpdate,
  onRemove,
  onClose
}) => {
  const hasPicture = urlProps

  const isNarrow = useMedia(['(max-width: 600px)'], [true], false)

  const [hideDialog, setHideDialog] = useState(false)
  const [step, setStep] = useState(hasPicture ? 'show' : 'upload') // show, upload, crop
  const [url, setUrl] = useState() // The URL of the uploaded image
  const [crop, setCrop] = useState()

  const [isUpdating, setIsUpdating] = useState(false)

  const updatePicture = async ({ url, crop }) => {
    setIsUpdating(true)
    try {
      const imageUrl = await uploadCroppedImage({ url, crop })
      if (await onUpdate(imageUrl)) {
        setIsUpdating(false)
        setHideDialog(true)
      }
    } finally {
      setIsUpdating(false)
    }
  }

  const removePicture = async () => {
    if (await onRemove()) {
      setHideDialog(true)
    }
  }

  let title = titleProps
  let titleIcon
  let onTitleIconClick
  let actions

  if (step === 'show') {
    titleIcon = CloseIcon
    onTitleIconClick = () => setHideDialog(true)
    actions = [{ text: 'Edit', icon: EditIcon, onClick: () => setStep('upload') }]
    if (onRemove) {
      actions.push({
        text: 'Remove',
        icon: DeleteIcon,
        onClick: () => removePicture(),
        disabled: !urlProps
      })
    }
  } else if (step === 'upload') {
    title = hasPicture ? 'Change picture' : 'Upload picture'
    titleIcon = hasPicture ? BackArrowIcon : CloseIcon
    onTitleIconClick = hasPicture ? () => setStep('show') : () => setHideDialog(true)
  } else if (step === 'crop') {
    title = 'Crop'
    titleIcon = BackArrowIcon
    onTitleIconClick = () => setStep('upload')
    actions = [
      {
        text: 'Save',
        onClick: () => {
          updatePicture({ url, crop })
        },
        disabled: isUpdating
      },
      { text: 'Cancel', onClick: () => setHideDialog(true) }
    ]
  }

  const handleImageUploaded = (url) => {
    setUrl(url)
    setStep('crop')
  }

  return (
    <Dialog
      title={title}
      titleIcon={titleIcon}
      onTitleIconClick={onTitleIconClick}
      onClose={onClose}
      actions={actions}
      large={step === 'upload' || step === 'crop'}
      full={isNarrow}
      overflow={step === 'crop'}
      disableAutofocus
      hideDialog={hideDialog}
      dismissable={false}
    >
      {step === 'show' && (
        <Col alignItems="center">
          <SpacerVertical />
          <Avatar size={240} src={urlProps} name={name} />
          <SpacerVertical />
        </Col>
      )}
      {step === 'upload' && <UploadImage onImageUploaded={handleImageUploaded} />}
      {step === 'crop' && (
        <Block position="relative" width="100%" height="100%">
          <ImageCropper
            url={url}
            aspectRatio={1}
            useCircularMask
            margin={isNarrow ? 50 : 100}
            onChange={setCrop}
            flex="1"
            height="100%"
            width="100%"
          />
          <Col
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            background="var(--modal-background)"
            pointerEvents={isUpdating ? undefined : 'none'}
            opacity={isUpdating ? 1 : 0}
            transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
            whillChange="opacity"
            justifyContent="center"
            alignItems="center"
          >
            {isUpdating && <ProgressCircular />}
          </Col>
        </Block>
      )}
    </Dialog>
  )
}

export default PictureDialog
