import { h, Fragment } from 'preact'
import { useRef, useState, useEffect } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import { Button } from './Button'
import { useMedia } from '@sodra/use-media'
import { SpacerHorizontal } from './spacers'

export const SnackbarItem = ({
  text,
  actionText,
  onAction,
  onClosed,
  dismissText,
  dismissIcon,
  dismiss: dismissProp = false,
  visibleTime = 4 // Seconds
}) => {
  const mobile = useMedia(['(max-width: 600px)'], [true], false)
  const [showing, setShowing] = useState(false)
  const isMounted = useRef(true)
  const [dismiss, setDismiss] = useState(dismissProp)

  const handleDismiss = () => {
    setDismiss(true)
  }

  useEffect(() => {
    if (dismissProp) {
      setDismiss(true)
    }
  }, [dismissProp])

  useEffect(() => {
    let triggerHideTimeout
    let triggerClosedTimeout

    const hide = () => {
      if (isMounted.current) {
        setShowing(false)
        triggerClosedTimeout = setTimeout(() => {
          if (isMounted.current && onClosed) {
            onClosed()
          }
        }, 600)
      }
    }

    const show = () => {
      setShowing(true)
      triggerHideTimeout = setTimeout(hide, visibleTime * 1000)
    }

    if (showing && dismiss) {
      hide()
    } else {
      show()
    }

    return () => {
      if (triggerHideTimeout) {
        clearTimeout(triggerHideTimeout)
      }
      if (triggerClosedTimeout) {
        clearTimeout(triggerClosedTimeout)
      }
    }
  }, [dismiss])

  return (
    <Row
      width={mobile ? '100%' : undefined}
      flex="1"
      alignItems="center"
      fontSize="16px"
      lineHeight="18px"
      textAlign="left"
      borderRadius="3px"
      backgroundColor="var(--inverted-surface)"
      color="var(--on-inverted-surface)"
      zIndex="5"
      boxShadow="0 2px 6px var(--box-shadow-color)"
      position="absolute"
      opacity={showing ? 1 : 0}
      bottom={showing ? 0 : '-100px'}
      transition="bottom .36s cubic-bezier(0,0,.2,1), opacity .36s cubic-bezier(0,0,.2,1)"
      whillChange="opacity,bottom"
    >
      <Block flex="1" padding="16px 24px">
        {text}
      </Block>
      {actionText && (
        <Fragment>
          <SpacerHorizontal tiny />
          <Button text onClick={onAction} color="var(--accent-on-inverted-surface)">
            {actionText}
          </Button>
          <SpacerHorizontal tiny />
        </Fragment>
      )}
      {dismissText && (
        <Fragment>
          <SpacerHorizontal tiny />
          <Button
            text
            onClick={handleDismiss}
            icon={dismissIcon}
            color="var(--accent-on-inverted-surface)"
          >
            {dismissText}
          </Button>
          <SpacerHorizontal tiny />
        </Fragment>
      )}
      {!dismissText && dismissIcon && (
        <Fragment>
          <SpacerHorizontal tiny />
          <IconButton
            onClick={handleDismiss}
            icon={CloseIcon}
            color="var(--accent-on-inverted-surface)"
          />
          <SpacerHorizontal tiny />
        </Fragment>
      )}
    </Row>
  )
}
