import { h } from 'preact'
import { useRef, useState, useEffect, useMemo } from 'preact/hooks'
import { InlineBlock, Block } from 'jsxstyle/preact'
import { SelectMenu } from './SelectMenu'
import { List } from './List'
import { ListItem } from './ListItem'
import { SpacerVertical } from './spacers'
import { SelectButton } from './SelectButton'
import { InputInfo } from './InputInfo'
import { InputError } from './InputError'
import { getUniqueId } from './get-unique-id'

const fixString = (str) => removeDiacritics(str.toLowerCase())

const removeDiacritics = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const Select = ({
  label,
  placeholder,
  value,
  options = [],
  actionText,
  actionIcon,
  onAction,
  infoText,
  errorText,
  disabled,
  onChange,
  outline,
  text,
  ...styles
}) => {
  const [initialIndex, setInitialIndex] = useState()
  const [selectedIndex, setSelectedIndex] = useState()
  const [activeListItemIndex, setActiveListItemIndex] = useState()
  const [menuVisible, setMenuVisible] = useState(false)
  const select = useRef()
  const list = useRef()
  const [ariaDescribedById, setAriaDescribedById] = useState()
  const selectButtonId = useMemo(() => getUniqueId('bui-select'), [])
  const listId = useMemo(() => getUniqueId('bui-select-list'), [])

  const [query, setQuery] = useState('')

  useEffect(() => {
    if (infoText || errorText) {
      setAriaDescribedById(getUniqueId('bui-aria-describedby'))
    }
  }, [infoText, errorText])

  const getIndex = (value) => options.findIndex((option) => option.value === value)

  useEffect(() => {
    const index = getIndex(value)
    setInitialIndex(index)
    setSelectedIndex(index)
  }, [options, value])

  useEffect(() => {
    list.current && list.current.focus()
  }, [menuVisible])

  const handleClick = (e) => {
    // Open menu
    setMenuVisible(true)
  }

  const handleActionClick = (e) => {
    onAction && onAction()
    setMenuVisible(false)
  }

  const queryTimeout = useRef()

  useEffect(() => {
    if (query && query.length > 0) {
      if (queryTimeout.current) {
        clearTimeout(queryTimeout.current)
      }
      queryTimeout.current = setTimeout(() => {
        setQuery('')
      }, 1000)
      const option = options.find((option) => fixString(option.text).startsWith(query))
      if (option) {
        selectOption(option)
      }
    }
  }, [query])

  const handleKeyDown = (e) => {
    const isCharacter = e.key.length === 1
    if (e.keyCode === 8) {
      // Backspace
      setQuery((query) => query.slice(0, query.length - 1))
      e.stopPropagation()
    } else if (e.keyCode === 13) {
      // Enter
      setQuery('')
    } else if (e.keyCode === 27) {
      // Escape
      setSelectedIndex(initialIndex)
      setQuery('')
      setMenuVisible(false)
      e.stopPropagation()
    } else if (isCharacter) {
      setQuery((query) => query + e.key)
      e.stopPropagation()
    }
  }

  const selectOption = (option, close = false) => {
    const index = getIndex(option.value)
    if (index !== selectedIndex) {
      setSelectedIndex(getIndex(option.value))
    }
    if (close) {
      if (onChange && index !== initialIndex) {
        onChange(option.value)
      }
      setMenuVisible(false)
      select.current && select.current.focus()
    }
  }

  const selected = options[selectedIndex]

  return (
    <InlineBlock
      class="bui"
      position="relative"
      {...styles}
      props={{
        onKeyDown: handleKeyDown
      }}
    >
      <SelectButton
        label={label}
        placeholder={placeholder}
        selected={selected}
        onClick={handleClick}
        clickOnSpacePressed={!query}
        outline={outline}
        text={text}
        disabled={disabled}
        width="100%"
        props={{
          ref: select,
          onClick: !disabled ? handleClick : null,
          role: 'button',
          id: selectButtonId,
          'aria-label': selected ? `${label}, ${selected.text}` : label,
          'aria-haspopup': 'listbox',
          'aria-owns': listId,
          'aria-expanded': menuVisible,
          'aria-describedby': ariaDescribedById
        }}
      />
      <Block padding="0 15px">
        {!errorText && <InputInfo id={ariaDescribedById}>{infoText}</InputInfo>}
        {errorText && <InputError id={ariaDescribedById}>{errorText}</InputError>}
      </Block>
      {menuVisible && (
        <SelectMenu
          onClose={() => {
            setSelectedIndex(initialIndex)
            setMenuVisible(false)
          }}
          positionElement={select.current}
        >
          <SpacerVertical tiny />
          <List
            delayClick
            initialFocusIndex={selectedIndex >= 0 ? selectedIndex : 0}
            onListItemFocusChange={(index) => setActiveListItemIndex(index)}
            selectOnSpacePressed={!query}
            props={{
              ref: list,
              role: 'listbox',
              id: listId,
              'aria-labelledby': selectButtonId,
              'aria-activedescendant':
                activeListItemIndex >= 0 ? `${listId}-${activeListItemIndex}` : undefined
            }}
          >
            {options.map((option, index) => {
              return (
                <ListItem
                  key={option.value}
                  icon={option.icon}
                  visual={option.visual}
                  leftWidth="40px"
                  text={option.text}
                  secondaryText={option.secondaryText}
                  disabled={option.disabled}
                  onClick={() => selectOption(option, true)}
                  onFocus={() => selectOption(option)}
                  preventTextWrap
                  props={{
                    role: 'option',
                    id: `${listId}-${index}`,
                    'aria-selected': index === selectedIndex ? true : false
                  }}
                />
              )
            })}
            {actionText && (
              <ListItem
                key="--action--"
                leftWidth="40px"
                icon={actionIcon}
                text={actionText}
                onClick={handleActionClick}
                preventTextWrap
              />
            )}
          </List>
          <SpacerVertical tiny />
        </SelectMenu>
      )}
    </InlineBlock>
  )
}
