import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { Select } from '@sodra/bongo-ui'

type AudioDeviceSelectorProps = {
  deviceId?: string | null
  onChange: (deviceId: string) => void
}

type DeviceOptions = {
  text: string
  value: string
}
const AudioDeviceSelector = ({ deviceId, onChange }: AudioDeviceSelectorProps) => {
  const [options, setOptions] = useState<DeviceOptions[]>([])

  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    return <Block>enumerateDevices() not supported</Block>
  }

  useEffect(() => {
    const updateOptions = async () => {
      const options: DeviceOptions[] = []
      let selected
      await navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          devices.forEach(function (device) {
            if (device.kind === 'audioinput') {
              options.push({ value: device.deviceId, text: device.label })
              if (deviceId && deviceId === device.deviceId) {
                selected = deviceId
              }
            }
          })
        })
        .catch(function (err) {
          console.log(err.name + ': ' + err.message)
        })
      setOptions(options)
      if (options.length > 0 && selected !== deviceId) {
        onChange(selected ? selected : options[0].value)
      }
    }

    updateOptions()
    navigator.mediaDevices.addEventListener('devicechange', updateOptions)
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', updateOptions)
    }
  }, [])

  return (
    <Select
      text
      options={options}
      value={deviceId}
      onChange={onChange}
      placeholder={{ text: 'Select audio input' }}
      zIndex="3"
    />
  )
}

export default AudioDeviceSelector
