import { h } from 'preact'
import { connect } from 'unistore/preact'
import { goBack } from '@sodra/prutt'
import { BasicDialog } from '@sodra/bongo-ui'

import { deleteAudio, showSnackbar } from '../../actions'

const DeleteAudio = ({ user, audio, isDeletingAudio }) => {
  const onClose = () => goBack(`/user/${user.id}`)

  const handleDelete = async () => {
    if (await deleteAudio(audio.id)) {
      showSnackbar('Audio sample deleted')
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Delete audio sample"
      primaryActionText="Delete"
      primaryActionLoading={isDeletingAudio}
      onPrimaryActionClick={handleDelete}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Are you sure?
    </BasicDialog>
  )
}

export default connect('user, isDeletingAudio')(DeleteAudio)
