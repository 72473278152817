import { h } from 'preact'
import { connect } from 'unistore/preact'
import { useEffect, useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { Route, Router, Switch } from '@sodra/prutt'

import { get } from '../api'
import { store } from '../store'

import Spinner from './Spinner'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import AcceptVoiceInvitation from './AcceptVoiceInvitation'
import AcceptUserInvitation from './AcceptUserInvitation'
import AcceptLinkInvitation from './AcceptLinkInvitation'
import NotLoggedIn from './NotLoggedIn'
import LoggedIn from './LoggedIn'

import Player from './Player'
import Snackbar from './Snackbar'
import Error from './Error'

import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const App = ({ currentUser }) => {
  const [isFetchingUser, setIsFetchingUser] = useState(true)

  const fetchUser = async () => {
    setIsFetchingUser(true)
    try {
      const { data: currentUser } = await get('/me')
      store.setState({ currentUser })
    } catch (err) {
      console.log(err.message)
    } finally {
      setIsFetchingUser(false)
    }
  }

  useEffect(fetchUser, [])

  useEffect(() => {
    const handleResize = () => {
      store.setState({ isNarrow: window.innerWidth < 800 })
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (isFetchingUser) {
    console.log('Fetching current user...')
    return <Spinner />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Block>
        <Router>
          <Switch>
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/accept-voice-invitation" component={AcceptVoiceInvitation} />
            <Route path="/accept-user-invitation" component={AcceptUserInvitation} />
            <Route path="/link-invitation/:slug" component={AcceptLinkInvitation} />
            {!currentUser && <NotLoggedIn />}
            <LoggedIn />
          </Switch>
        </Router>
        <Player />
        <Snackbar />
        <Error />
      </Block>
    </QueryClientProvider>
  )
}

export default connect('currentUser')(App)
