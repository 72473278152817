import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'

export const useIsInViewport = (elem) => {
  const [isInViewport, setIsInViewport] = useState(false)
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      setIsInViewport(entry.intersectionRatio > 0)
    })
  }
  useEffect(() => {
    if (elem) {
      const observer = new IntersectionObserver(handleIntersection, { threshold: 0 })
      observer.observe(elem)
      return () => {
        observer.unobserve(elem)
      }
    }
  }, [elem])
  return isInViewport
}
