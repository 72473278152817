import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'
import { Block, Row } from 'jsxstyle/preact'
import { goBack } from '@sodra/prutt'
import {
  BasicDialog,
  ContentCopyIcon,
  DeleteIcon,
  IconButton,
  SpacerHorizontal,
  SpacerVertical
} from '@sodra/bongo-ui'
import { createLink, deleteLink } from '../../actions'

const CreateLink = ({ agency, isCreatingLink, isDeletingLink }) => {
  const onClose = () => goBack(`/agency/${agency.id}`)

  return (
    <BasicDialog
      disableAutofocus
      title="Create link to share"
      onClose={onClose}
      primaryActionText={agency.link ? '' : 'Create link'}
      primaryActionLoading={isCreatingLink}
      onPrimaryActionClick={createLink}
      secondaryActionText={agency.link ? 'Close' : 'Cancel'}
      onSecondaryActionClick={onClose}
    >
      <SpacerVertical />
      {agency.link && (
        <Fragment>
          <Block flex="1">
            Anyone with the can link register as a voice artist. Delete the link to disable this
            feature. You can always create a new link later.
          </Block>
          <SpacerVertical />
          <Row alignItems="center">
            <Block
              component="a"
              flex="1"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              props={{ href: agency.link, target: '_blank' }}
            >
              {agency.link}
            </Block>
            <SpacerHorizontal />
            <IconButton
              icon={ContentCopyIcon}
              onClick={() => {
                navigator.clipboard.writeText(agency.link)
              }}
            />
            <IconButton icon={DeleteIcon} loading={isDeletingLink} onClick={deleteLink} />
          </Row>
        </Fragment>
      )}
      {!agency.link && <Block flex="1">Let anyone with the link register as a voice artist</Block>}
      <SpacerVertical />
    </BasicDialog>
  )
}

export default connect('agency, isCreatingLink, isDeletingLink')(CreateLink)
