import { h, Fragment, cloneElement } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { Col, Block } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'

const DropIndicator = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(true)
  }, [])

  return (
    <Block
      background="var(--accent)"
      height={visible ? '5px' : '0px'}
      transition="height .18s cubic-bezier(0, 0, .2, 1)"
      will-change="height"
    />
  )
}

const useDidMount = () => {
  const [didMount, setDidMount] = useState(false)
  useEffect(() => setDidMount(true), [])
  return didMount
}

/*
  autofocus - gives focus to the List automatically when being mounted
  delayClick - wait for ripple animation before triggering click
*/
export const List = ({
  children,
  divider,
  initialFocusIndex = 0,
  roundedCorners = false,
  autofocus,
  delayClick,
  onListItemFocusChange,
  roving = true,
  selectOnSpacePressed = true,
  onMoveItem,
  props,
  ...style
}) => {
  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const listRef = useRef()
  const didMount = useDidMount()
  const [hasFocus, setHasFocus] = useState(autofocus)
  const [focusIndex, setFocusIndex] = useState(initialFocusIndex)
  const focusableItem = useRef()
  const prevChildren = useRef()

  const dragActive = supportsHover
  const [dragIndex, setDragIndex] = useState(-1)
  const [overIndex, setOverIndex] = useState(-1)

  const childrenArray = Array.isArray(children) ? children : [children]

  const items = childrenArray
    .reduce((acc, val) => acc.concat(val), [])
    .filter((item) => item !== null && !!item)

  const focus = (index) => {
    setHasFocus(true)
    setFocusIndex(index)
  }

  const focusNext = () => {
    if (focusIndex < items.length - 1) {
      setHasFocus(true)
      setFocusIndex(focusIndex + 1)
    } else if (roving && focusIndex === items.length - 1) {
      setHasFocus(true)
      setFocusIndex(0)
    }
  }

  const focusPrevious = () => {
    if (focusIndex > 0) {
      setHasFocus(true)
      setFocusIndex(focusIndex - 1)
    } else if (roving && focusIndex === 0) {
      setHasFocus(true)
      setFocusIndex(items.length - 1)
    }
  }

  useEffect(() => {
    if (didMount) {
      focus(initialFocusIndex)
    }
  }, [initialFocusIndex])

  useEffect(() => {
    // Move focus when focusIndex updates
    // Don’t give focus if component has not finished mounting
    if (didMount && hasFocus && focusableItem && focusableItem.current) {
      focusableItem.current.base.focus()
    }
    onListItemFocusChange && onListItemFocusChange(focusIndex)
  }, [focusIndex, hasFocus])

  useEffect(() => {
    if (listRef.current && dragActive && onMoveItem && dragIndex >= 0) {
      const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation() // stops the browser from redirecting.
        if (dragIndex >= 0 && overIndex >= 0) {
          if (dragIndex > overIndex) {
            onMoveItem(dragIndex, overIndex)
          } else {
            onMoveItem(dragIndex, overIndex - 1)
          }
        }
      }

      const el = listRef.current
      el.addEventListener('drop', handleDrop)
      return () => {
        el.removeEventListener('drop', handleDrop)
      }
    }
  }, [listRef.current, dragActive, dragIndex, overIndex, onMoveItem])

  useEffect(() => {
    let newItems = true

    const childrenArray = Array.isArray(children) ? children : [children]

    // Check if previous list items are the same as the new list items
    if (prevChildren.current) {
      const prev = prevChildren.current
      if (prev.length === childrenArray.length) {
        let i = 0
        let same = true
        while (same && i < prev.length - 1) {
          same = prev[i] && childrenArray[i] && prev[i].key === childrenArray[i].key
          i += 1
        }
        newItems = !same
      }
    } else {
      newItems = false
    }

    // Reset focus index if the list of items updates
    if (newItems) {
      setFocusIndex(0)
    }

    prevChildren.current = childrenArray
  }, [children])

  useEffect(() => {
    // Autofocus on mount
    if (autofocus && focusableItem && focusableItem.current) {
      focusableItem.current.base.focus()
    }
  }, [])

  const dropIndicatorAfter = overIndex > items.length - 1 ? <DropIndicator /> : undefined

  return (
    <Col
      component="ul"
      class="bui"
      margin="0"
      padding="0"
      props={{
        ref: listRef,
        role: 'list',
        ...props
      }}
      {...style}
    >
      {items.map((item, index) => {
        const focusable = index === focusIndex
        const itemWithProps = cloneElement(item, {
          focusSelf: () => focus(index),
          focusNext,
          focusPrevious,
          ref: focusable ? focusableItem : undefined,
          listOnBlur: () => {
            setHasFocus(false)
          },
          onDrag:
            dragActive && onMoveItem
              ? {
                  onDragStart: () => setDragIndex(index),
                  onDragEnd: () => {
                    setDragIndex(-1)
                    setOverIndex(-1)
                  },
                  onDragEnter: (pos = 0) => setOverIndex(index + pos),
                  onDragOver: (pos = 0) => setOverIndex(index + pos)
                  //onDragLeave: () => {},
                }
              : undefined,
          onMove:
            !dragActive && onMoveItem
              ? {
                  onMoveUp: index > 0 ? () => onMoveItem(index, index - 1) : undefined,
                  onMoveDown:
                    index < items.length - 1 ? () => onMoveItem(index, index + 1) : undefined
                }
              : undefined,
          focusable,
          roundedCorners,
          delayClick,
          selectOnSpacePressed
        })

        const dropIndicator = overIndex === index ? <DropIndicator /> : undefined
        let dividerElem = divider && index < items.length - 1 ? divider : undefined
        return (
          <Fragment>
            {dropIndicator}
            {itemWithProps}
            {dividerElem}
          </Fragment>
        )
      })}
      {dropIndicatorAfter}
    </Col>
  )
}
