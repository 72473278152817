import { h } from 'preact'
import { InfoRoundIcon, SpacerHorizontal, Body1 } from '@sodra/bongo-ui'

const ErrorMessage = ({ children, ...style }) => (
  <Body1 color="var(--error)" {...style}>
    <InfoRoundIcon fill="var(--error)" size={20} marginTop="-2px" />
    <SpacerHorizontal tiny />
    {children}
  </Body1>
)

export default ErrorMessage
