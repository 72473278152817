import { h } from 'preact'
import { Block } from 'jsxstyle/preact'
import { AudioBufferVisualizer } from './AudioBufferVisualizer'
// import { TrimKnobs } from './TrimKnobs'
import { useState } from 'preact/hooks'
import { AudioSelect } from './AudioSelect'
import { ScrollControl } from './ScrollControl'

type Props = {
  audioBuffer: AudioBuffer
  currentTime: number
  start: number
  setStart: (start: number) => void
  end: number
  setEnd: (end: number) => void
}

export function AudioEditor({ audioBuffer, currentTime, start, setStart, end, setEnd }: Props) {
  const [pos, setPos] = useState(0)
  const [zoom, setZoom] = useState(1)

  return (
    <ScrollControl pos={pos} setPos={setPos} zoom={zoom} setZoom={setZoom}>
      <Block position="relative" flex="1">
        <AudioBufferVisualizer
          audioBuffer={audioBuffer}
          pos={pos}
          zoom={zoom}
          setPos={setPos}
          currentTime={currentTime}
          start={start}
          end={end}
          height="91px"
        />
        <AudioSelect
          audioBuffer={audioBuffer}
          startTime={start}
          onChangeStart={setStart}
          endTime={end}
          onChangeEnd={setEnd}
          pos={pos}
          zoom={zoom}
          disabled={false}
        />
      </Block>
    </ScrollControl>
  )
}
