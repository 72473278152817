import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'

export const usePermissionState = (api: PermissionName | 'microphone') => {
  let a: PermissionDescriptor
  const [permissionState, setPermissionState] = useState<PermissionState | null>(null)
  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: api as PermissionName }).then(function (permissionStatus) {
        setPermissionState(permissionStatus.state)
        permissionStatus.onchange = function () {
          setPermissionState(this.state)
        }
      })
    } else {
      setPermissionState('denied')
    }
  }, [api])
  return permissionState
}
