import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Row } from 'jsxstyle/preact'
import { goBack, Route, routeTo } from '@sodra/prutt'
import { AddIcon, NativeSelect, SelectButton, SelectChip, SpacerVertical } from '@sodra/bongo-ui'

import { accentOptions, genderSoundsLikeOptions, ageSoundsLikeOptions } from '../options'
import { showSnackbar, updateAudio } from '../../actions'

import LargeDialog from '../LargeDialog'

import SelectType from './SelectType'
import SelectTone from './SelectTone'
import { getName, SelectLanguage } from '../SelectLanguage'

const EditAudio = ({ user, audio, isUpdatingAudio }) => {
  const [language, setLanguage] = useState(audio.language)
  const [accent, setAccent] = useState(audio.accent)
  const [soundsLike, setSoundsLike] = useState(audio.soundsLike)
  const [gender, setGender] = useState(audio.gender)
  const [types, setTypes] = useState(audio.types)
  const [tones, setTones] = useState(audio.tones)

  const [showSelectLanguage, setShowSelectLanguage] = useState(false)

  const addType = (type) => {
    setTypes((types) => (types.includes(type) ? types : [...types, type]))
  }

  const removeType = (type) => {
    setTypes((types) => types.filter((t) => t !== type))
  }

  const addTone = (tone) => {
    setTones((tones) => (tones.includes(tone) ? tones : [...tones, tone]))
  }

  const removeTone = (tone) => {
    setTones((tones) => tones.filter((t) => t !== tone))
  }

  const onClose = () => goBack(`/user/${user.id}`)
  const closeDialog = () => goBack(`/user/${user.id}/edit-audio/${audio.id}`)

  const handleUpdate = async () => {
    if (await updateAudio(audio.id, { language, accent, soundsLike, gender, types, tones })) {
      showSnackbar('Audio sample updated')
      onClose()
    }
  }

  return (
    <LargeDialog
      title="Edit audio"
      actions={[
        {
          id: 'save',
          text: 'Save',
          loading: isUpdatingAudio,
          onClick: handleUpdate,
          type: 'contained'
        },
        { id: 'cancel', text: 'Cancel', onClick: onClose }
      ]}
      onClose={onClose}
    >
      <SpacerVertical />
      <SelectButton
        width="100%"
        label="Language"
        selected={language ? { text: language } : undefined}
        onClick={() => setShowSelectLanguage(true)}
      />
      <SpacerVertical />
      <NativeSelect
        width="100%"
        label="Accent"
        options={accentOptions}
        value={accent}
        onChange={setAccent}
      />
      <SpacerVertical />
      <NativeSelect
        width="100%"
        label="Age – Sounds like"
        options={ageSoundsLikeOptions}
        value={soundsLike}
        onChange={setSoundsLike}
      />
      <SpacerVertical />
      <NativeSelect
        width="100%"
        label="Gender – Sounds like"
        options={genderSoundsLikeOptions}
        value={gender}
        onChange={setGender}
      />
      <SpacerVertical />
      <Row alignItems="center" flexWrap="wrap">
        {types.map((type) => {
          return (
            <SelectChip
              marginRight="5px"
              marginBottom="5px"
              value={type}
              onClear={() => removeType(type)}
            />
          )
        })}
        {types.length < 3 && (
          <SelectChip
            label="Type"
            icon={AddIcon}
            onClick={() => routeTo(`/user/${user.id}/edit-audio/${audio.id}/select-type`)}
          />
        )}
      </Row>
      <SpacerVertical />
      <Row alignItems="center" flexWrap="wrap">
        {tones.map((tone) => {
          return (
            <SelectChip
              marginRight="5px"
              marginBottom="5px"
              value={tone}
              onClear={() => removeTone(tone)}
            />
          )
        })}
        {tones.length < 10 && (
          <SelectChip
            label="Tone"
            icon={AddIcon}
            onClick={() => routeTo(`/user/${user.id}/edit-audio/${audio.id}/select-tone`)}
          />
        )}
      </Row>
      {showSelectLanguage && (
        <SelectLanguage
          onClose={() => {
            setShowSelectLanguage(false)
          }}
          onSelect={(language) => {
            setLanguage(getName(language))
          }}
        />
      )}
      <Route
        path="/user/:userId/edit-audio/:audioId/select-type"
        render={() => <SelectType onSelect={addType} onClose={closeDialog} />}
      />
      <Route
        path="/user/:userId/edit-audio/:audioId/select-tone"
        render={() => <SelectTone onSelect={addTone} onClose={closeDialog} />}
      />
    </LargeDialog>
  )
}

export default connect('user, isUpdatingAudio')(EditAudio)
