import { h } from 'preact'
import { connect } from 'unistore/preact'
import { Block } from 'jsxstyle/preact'
import { Button, ErrorIcon, PauseIcon, PlayIcon, ProgressCircular } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import { useLocalStorageState } from '../../use-local-storage-state'

import { usePagedItems } from '../use-paged-items'
import Listing from '../Listing'
import { togglePlay } from '../../actions'
import Section from '../Section'

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

const getName = (audio) => {
  if (audio.isStudioQualityDemo) {
    return 'Studio quality demo'
  }
  return audio.types.sort().join(' · ')
}

const getDescription = (audio) => {
  if (audio.isStudioQualityDemo) {
    return ''
  }
  let description = audio.language
  if (audio.accent) {
    description += ` (${audio.accent})`
  }
  if (audio.gender && audio.gender !== 'neither') {
    description += ` · ${capitalize(audio.gender)}`
  }
  if (audio.soundsLike) {
    description += ` · ${capitalize(audio.soundsLike.replace(/-/g, ' '))}`
  }
  for (let tone of audio.tones) {
    description += ` · ${tone}`
  }
  return description
}

const AudioSamples = ({ user, player }) => {
  const [page, setPage] = useLocalStorageState(`speechless:${user.id}:audioSamples:page`, 1)

  const { items: audio, pages } = usePagedItems({
    items: user.audio,
    page,
    setPage
  })

  if (audio.length === 0) {
    return <Section color="var(--on-surface-lighter)">No voice demos</Section>
  }

  return (
    <Listing
      page={page}
      pages={pages}
      setPage={setPage}
      items={audio.map((audio) => {
        const isPlaying = player.status === 'playing' && player.uri === audio.processedUri
        let icon
        if (audio.error) {
          icon = <ErrorIcon />
        } else if (!audio.processedUri) {
          icon = <ProgressCircular size="20" />
        } else if (isPlaying) {
          icon = <PauseIcon fill="var(--accent)" />
        } else {
          icon = <PlayIcon fill="var(--on-surface)" />
        }
        return {
          data: audio,
          icon,
          title: getName(audio),
          titleColor: isPlaying ? 'var(--accent)' : 'var(--on-surface)',
          description: getDescription(audio)
        }
      })}
      popupMenuOptions={[
        {
          label: 'Edit',
          onClick: (audio) => routeTo(`/user/${user.id}/edit-audio/${audio.id}`)
        },
        {
          label: 'Delete',
          onClick: (audio) => routeTo(`/user/${user.id}/delete-audio/${audio.id}`)
        }
      ]}
      onClick={(audio) => togglePlay(audio.processedUri)}
    />
  )
}

export default connect('user, player')(AudioSamples)
