import { Fragment, h } from 'preact'
import { ChevronRightIcon } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { routeTo } from '@sodra/prutt'

const Breadcrumbs = ({ links, ...props }) => {
  return (
    <Row alignItems="center" padding="20px" {...props}>
      {links.map((link, index) => {
        return (
          <Fragment>
            {index > 0 && <ChevronRightIcon fill="var(--on-surface-lighter)" />}
            <Block
              color={link.path ? 'var(--on-surface-light)' : 'var(--on-surface-lighter)'}
              cursor={link.path ? 'pointer' : ''}
              props={{
                onClick: link.path ? () => routeTo(link.path) : undefined
              }}
            >
              {link.label}
            </Block>
          </Fragment>
        )
      })}
    </Row>
  )
}

export default Breadcrumbs
