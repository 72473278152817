import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { goBack, routeTo } from '@sodra/prutt'
import { BasicDialog, Button, LinkIcon, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { createVoiceInvitation } from '../../actions'

const InviteVoice = ({ agency, isCreatingInvitation }) => {
  const [email, setEmail] = useState('')

  const onClose = () => goBack(`/agency/${agency.id}`)

  const handleSubmit = async () => {
    if (await createVoiceInvitation({ email })) {
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Invite voice by email"
      onClose={onClose}
      primaryActionText="Send invitation"
      primaryActionLoading={isCreatingInvitation}
      onPrimaryActionClick={handleSubmit}
      secondaryActionText="Cancel"
      onSecondaryActionClick={onClose}
    >
      <SpacerVertical />
      <TextField width="100%" label="Email" value={email} onInput={setEmail} />
      <SpacerVertical />
      <Button icon={LinkIcon} onClick={() => routeTo(`/agency/${agency.id}/create-link`, true)}>
        Create link to share
      </Button>
    </BasicDialog>
  )
}

export default connect('agency, isCreatingInvitation')(InviteVoice)
