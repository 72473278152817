import { useMemo } from "preact/hooks"
import { createCanvas } from "./create-canvas"
import { drawUsingClipPath } from "./draw"
import { drawSmoothedChunkedWaveform } from "./drawWaveForm"

type Props = {
    canvas: HTMLCanvasElement | null
    audioBuffer: AudioBuffer
    color?: string
    progressColor?: string
    startTime: number
    endTime: number
    currentTime: number
    zoom?: number
    pos?: number
  }

export const useDrawAudioBuffer = ({canvas, audioBuffer, color = '#eee', progressColor = '#fff', startTime, endTime, currentTime, zoom = 1, pos = 0}: Props) => {
  if (!canvas || !audioBuffer) return

  const width = canvas.width
  const height = canvas.height

  const waveformImage = useMemo(() => {
    const offlineCanvas = createCanvas(width, height)
    const offlineCtx = offlineCanvas.getContext('2d')
    if (!offlineCtx) return
    drawSmoothedChunkedWaveform({
      ctx: offlineCtx,
      audioBuffer,
      color,
      zoom,
      position: pos,
      x: 0,
      y: 0,
      width,
      height
    })

    return offlineCanvas

  }, [canvas, audioBuffer, color, zoom, pos])

  if (!waveformImage) return

  const ctx = canvas.getContext('2d')
  if (!ctx) return

  ctx.clearRect(0, 0, width, height)

  drawUsingClipPath({
    ctx,
    waveformImage,
    currentTime,
    startTime,
    endTime,
    duration: audioBuffer.duration,
    pos,
    zoom,
    color: progressColor
  })

  ctx.save()
  ctx.restore()
}

/**
 * NOTE: Keep this function to be able to compare fast/slow rendering.
 * Can be removed later on.
 */
export const drawAudioBuffer = ({canvas, audioBuffer, color = '#eee', progressColor = '#fff', startTime, endTime, currentTime, zoom = 1, pos = 0}: Props) => {
    if (!canvas) return

    const width = canvas.width
    const height = canvas.height

    const offlineCanvas = createCanvas(width, height)
    const offlineCtx = offlineCanvas.getContext('2d')
    if (!offlineCtx) return
    drawSmoothedChunkedWaveform({
      ctx: offlineCtx,
      audioBuffer,
      color,
      zoom,
      position: pos,
      x: 0,
      y: 0,
      width,
      height
    })

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    ctx.clearRect(0, 0, width, height)

    drawUsingClipPath({
      ctx,
      waveformImage: offlineCanvas,
      currentTime,
      startTime,
      endTime,
      duration: audioBuffer.duration,
      pos,
      zoom,
      color: progressColor
    })

    ctx.save()
    ctx.restore()
  }