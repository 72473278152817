import { store } from '../store'

const setPlayer = (params) => {
  const player = store.getState().player
  store.setState({ player: { ...player, ...params } })
}

export const play = (uri) => {
  setPlayer({ uri, status: 'playing' })
}

export const pause = () => {
  setPlayer({ status: 'paused' })
}

export const stop = () => {
  setPlayer({ status: 'stopped' })
}

export const togglePlay = (uri) => {
  const player = store.getState().player
  if (uri && (player.status !== 'playing' || uri !== player.uri)) {
    play(uri)
  } else {
    stop()
  }
}
