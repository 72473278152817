import { Button, DividerHorizontal, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { showSnackbar, updateUser } from '../../actions'
import Section from '../Section'

type Props = {
  currentUser: User
  user: User
  isUpdatingUser: boolean
}

const AccountSettings = ({ currentUser, user, isUpdatingUser }: Props) => {
  const [email, setEmail] = useState(user.email)

  useEffect(() => {
    const timeout = setTimeout(save, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [email])

  const save = async () => {
    const params: Partial<User> = {
      email
    }
    return updateUser(params)
  }

  const handleSubmit = async () => {
    if (await save()) {
      showSnackbar('Email saved')
    }
  }

  return (
    <Section>
      <Form onSubmit={handleSubmit}>
        <TextField label="Email" type="email" value={email} onChange={setEmail} width="100%" />
        <SpacerVertical />
        <Button contained loading={isUpdatingUser} onClick={handleSubmit}>
          Save
        </Button>
      </Form>
      <SpacerVertical />
      <DividerHorizontal />
      <SpacerVertical />
      <Button onClick={() => routeTo(`/user/${user.id}/change-password`)}>Change password</Button>
    </Section>
  )
}

export default connect('currentUser, user, isUpdatingUser')(AccountSettings as any)
