import { h, Fragment, cloneElement } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { Col } from 'jsxstyle/preact'
import { TextField } from './TextField'
import { SearchIcon } from './icons'
import { Divider } from './dividers'
import { SpacerVertical } from './spacers'

export const SearchList = ({
  label,
  placeholder,
  query,
  onQueryUpdate,
  icon,
  loading,
  onSelect,
  children,
  roundedCorners = false,
  autofocus,
  delayClick,
  floating = false,
  listMaxHeight,
  listMargin,
  roving = true,
  suggest,
  suggestMode,
  onSuggestSelect,
  onSuggestClose,
  props,
  ...style
}) => {
  const textFieldElem = useRef()
  const selectedListItemElem = useRef()
  const [focus, setFocus] = useState(autofocus)
  const [renderList, setRenderList] = useState(!floating)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const prevChildren = useRef()

  const items = children
    .reduce((acc, val) => acc.concat(val), [])
    .filter((item) => item !== null && !!item)

  const select = (index) => {
    setSelectedIndex(index)
  }

  const selectNext = () => {
    if (selectedIndex < items.length - 1) {
      setSelectedIndex(selectedIndex + 1)
    } else if (roving && selectedIndex === items.length - 1) {
      setSelectedIndex(0)
    }
  }

  const selectPrevious = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1)
    } else if (selectedIndex === -1) {
      setSelectedIndex(items.length - 1)
    } else if (roving && selectedIndex === 0) {
      setSelectedIndex(items.length - 1)
    }
  }

  useEffect(() => {
    if (selectedListItemElem.current && selectedListItemElem.current.base) {
      selectedListItemElem.current.base.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    }
  }, [selectedListItemElem.current, selectedIndex])

  useEffect(() => {
    let newItems = true

    // Check if previous list items are the same as the new list items
    if (prevChildren.current) {
      const prev = prevChildren.current
      if (prev.length === children.length) {
        let i = 0
        let same = true
        while (same && i < prev.length - 1) {
          same = prev[i] && children[i] && prev[i].key === children[i].key
          i += 1
        }
        newItems = !same
      }
    }

    if (newItems) {
      setSelectedIndex(-1)
    }

    prevChildren.current = children
  }, [children])

  useEffect(() => {
    if (floating) {
      if (!focus) {
        setTimeout(() => setRenderList(false), 360)
      } else {
        setRenderList(true)
      }
    }
  }, [focus, floating])

  const handleFocus = () => setFocus(true)

  const handleBlur = () => {
    setFocus(false)
    setTimeout(() => select(-1), 360)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      // Escape
      select(-1)
    }
    if (e.keyCode === 40) {
      // Arrow down
      e.preventDefault()
      selectNext()
    }
    if (e.keyCode === 38) {
      // Arrow up
      e.preventDefault()
      selectPrevious()
    }
    if (e.keyCode === 13) {
      // Enter
      e.preventDefault()
      if (selectedIndex >= 0) {
        onSelect(prevChildren.current[selectedIndex].props.value)
        textFieldElem.current && textFieldElem.current.blur()
      } else {
        select(0)
      }
    }
  }

  const handleInput = (value, e) => {
    if (value !== query) {
      onQueryUpdate(value, e)
    }
  }

  const handleChange = (value, e) => {
    if (value !== query) {
      onQueryUpdate(value, e)
    }
  }

  const handleSuggestSelect = (value) => {
    if (onSuggestSelect) {
      onSuggestSelect(value, textFieldElem.current)
    }
  }

  return (
    <Col {...style} position="relative">
      <TextField
        icon={icon || SearchIcon}
        loading={loading}
        type="text"
        clearable
        label={label}
        placeholder={placeholder}
        value={query}
        onInput={handleInput}
        onChange={handleChange}
        onKeyDown={items && items.length > 0 ? handleKeyDown : undefined}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autocomplete="off"
        autofocus={autofocus}
        width="100%"
        flexShrink="0"
        inputRef={textFieldElem}
        suggest={suggest}
        suggestMode={suggestMode}
        onSuggestSelect={handleSuggestSelect}
        onSuggestClose={onSuggestClose}
      />
      {!floating && <SpacerVertical tiny />}
      {renderList && (
        <Col
          position={floating ? 'absolute' : undefined}
          top={floating ? '59px' : undefined}
          background={floating ? 'var(--surface-floating)' : undefined}
          boxShadow={floating ? '0 1px 3px var(--box-shadow-color)' : undefined}
          flex="1"
          component="ul"
          class="bui bui-show-scroll"
          minWidth="100%"
          maxHeight={listMaxHeight || undefined}
          overflowY="auto"
          margin={listMargin || '0'}
          padding="0"
          props={{
            role: 'list',
            ...props
          }}
        >
          {items.map((item, index) => {
            const itemWithProps = cloneElement(item, {
              key: index,
              selected: index === selectedIndex,
              ref: index === selectedIndex ? selectedListItemElem : undefined,
              roundedCorners,
              delayClick
            })
            if (index < items.length - 1) {
              return (
                <Fragment>
                  {itemWithProps}
                  <Divider />
                </Fragment>
              )
            }
            return itemWithProps
          })}
        </Col>
      )}
    </Col>
  )
}
