import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Row, Block } from 'jsxstyle/preact'
import {
  H2,
  NativeSelect,
  SearchIcon,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { routeTo, Switch } from '@sodra/prutt'

import { get } from '../../api'
import { useLocalStorageState } from '../../use-local-storage-state.js'

import Listing from '../Listing'
import { ApproveButton } from '../ApproveButton'
import { OnboardingProgress } from '../OnboardingProgress'

const pageSize = 10

const AllVoices = ({ currentUser }) => {
  const [page, setPage] = useLocalStorageState(`speechless:agencies:page`, 1)
  const [pages, setPages] = useState(page)
  const [query, setQuery] = useLocalStorageState(`speechless:agencies:query`, '')
  const [voiceStatus, setVoiceStatus] = useLocalStorageState(
    `speechless:agencies:voiceStatus`,
    'all'
  )

  const [allUsers, setAllUsers] = useState([])
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    get(`/users`, { isVoice: true, page, pageSize, query, voiceStatus }).then(
      ({ data: users, meta }) => {
        setPages(Math.ceil(meta.total / pageSize))
        setAllUsers(
          users.sort((a, b) => {
            return a.name?.localeCompare(b.name)
          })
        )
      }
    )
  }, [counter, page, query, voiceStatus])

  useEffect(() => {
    // If current page is larger than the number of pages (can happen when changing filtering), send to user to the last page.
    if (pages < 1) {
      setPage(1)
      return
    }
    if (page > pages) {
      setPage(pages)
    }
  }, [pages])

  const refetch = () => setCounter(counter + 1)

  return (
    <Block margin="auto" padding="20px" maxWidth="800px">
      <SpacerVertical large />
      <Row alignItems="center">
        <H2 size="4">Voices</H2>
        <Block flex="1" />
        {/* <Button contained icon={AddIcon} onClick={() => routeTo(`/agencies/add`)}>
          Add
        </Button> */}
      </Row>
      <SpacerVertical />
      <TextField
        autocomplete="off"
        simple
        clearable
        width="100%"
        icon={SearchIcon}
        label="Search"
        value={query}
        onInput={setQuery}
        onChange={setQuery}
      />
      <SpacerVertical />
      <NativeSelect
        label="Show"
        value={voiceStatus}
        onChange={setVoiceStatus}
        options={[
          { value: 'all', label: 'All voices' },
          { value: 'approved', label: 'Approved voices' },
          { value: 'notApproved', label: 'Not approved voices' }
        ]}
      />
      <SpacerVertical />
      <Listing
        page={page}
        pages={pages}
        setPage={setPage}
        items={allUsers.map((user) => {
          return {
            data: user,
            picture: user.picture,
            title: user.name,
            text: (
              <Row alignItems="center">
                <Block>{user.name || user.email}</Block>
                <Block flex="1" />
                {currentUser.isSuperadmin && (
                  <Fragment>
                    <OnboardingProgress progress={user.onboardingProgress} />
                    <SpacerHorizontal />
                  </Fragment>
                )}
                <ApproveButton user={user} onChange={refetch} />
              </Row>
            )
          }
        })}
        // popupMenuOptions={[
        // {
        //   label: 'Edit',
        //   onClick: (agency) => routeTo(`/agency/${agency.id}/settings-details`)
        // },
        // {
        //   label: 'Delete',
        //   onClick: (agency) => routeTo(`/agencies/delete/${agency.id}`)
        // }
        // ]}
        onClick={(user) => routeTo(`/user/${user.id}`)}
      />
      <Switch>
        {/* <Route
          path="/agencies/add"
          render={(props) => <AddAgency {...props} onClose={closeDialog} onAdd={refetch} />}
        />
        <Route
          path="/agencies/delete/:agencyId"
          render={({ agencyId }) => {
            const agency = agencies.find((agency) => agency.id === agencyId)
            return <DeleteAgency agency={agency} onClose={closeDialog} onDelete={refetch} />
          }}
        /> */}
      </Switch>
    </Block>
  )
}

export default connect('isNarrow, currentUser')(AllVoices)
