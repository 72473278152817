import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'
import { useEffect } from 'preact/hooks'

import { Route } from '@sodra/prutt'
import { fetchUser, getLineAudio } from '../../actions'
import Spinner from '../Spinner'
import { Session } from '../Session/Session'
import { bar, battle, studioQualityDemo } from './exampleDialogues'
import { useQuery } from 'react-query'
import { Block } from 'jsxstyle/preact'

type Props = {
  userId: string
  user: User
  dialogue: string
  characterId: string
}

const SessionPage = ({ userId, user, dialogue, characterId }: Props) => {
  useEffect(() => {
    document.body.classList.add('bui-show-scroll')
    return () => {
      document.body.classList.remove('bui-show-scroll')
    }
  }, [])

  useEffect(() => {
    fetchUser(userId)
  }, [userId])

  const lineAudioQuery = useQuery<LineAudio[]>('line-audio', getLineAudio, {
    enabled: !!user
  })

  if (!user || user.id !== userId || !lineAudioQuery.data) {
    return <Spinner />
  }

  const lineAudio = lineAudioQuery.data

  return (
    <Fragment>
      <Block>
        <Route
          path="/session/:userId/studio-quality-demo"
          render={() => (
            <Session
              activeCharacterId={user.id}
              user={user}
              dialogue={studioQualityDemo(user)}
              lineAudio={lineAudio}
            />
          )}
        />
        <Route
          path="/session/:userId/bar-scene-kim"
          render={() => (
            <Session user={user} dialogue={bar} activeCharacterId="kim" lineAudio={lineAudio} />
          )}
        />
        <Route
          path="/session/:userId/bar-scene-robin"
          render={() => (
            <Session user={user} dialogue={bar} activeCharacterId="robin" lineAudio={lineAudio} />
          )}
        />
        <Route
          path="/session/:userId/battle-scene-radioman"
          render={() => (
            <Session
              user={user}
              dialogue={battle}
              activeCharacterId="radioman"
              lineAudio={lineAudio}
            />
          )}
        />
        <Route
          path="/session/:userId/battle-scene-leader"
          render={() => (
            <Session
              user={user}
              dialogue={battle}
              activeCharacterId="leader"
              lineAudio={lineAudio}
            />
          )}
        />
        <Route
          path="/session/:userId/battle-scene-airsupport"
          render={() => (
            <Session
              user={user}
              dialogue={battle}
              activeCharacterId="airsupport"
              lineAudio={lineAudio}
            />
          )}
        />
      </Block>
    </Fragment>
  )
}

export default connect('user')(SessionPage as any)
