import { h } from 'preact'
import { Block } from 'jsxstyle/preact'

const RuleOfThirds = ({ top, left, width, height, visible }) => {
  const dx = width / 3.0
  const dy = height / 3.0
  return (
    <Block
      ariaHidden="true"
      class="RuleOfThirds"
      style={`position: absolute; top: ${top}px; left: ${left}px`}
      transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
      whillChange="opacity"
      opacity={visible ? 1 : 0}
    >
      <svg
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        strokeWidth="1px"
        stroke="white"
      >
        <line x1={0} y1={dy} x2={width} y2={dy} />
        <line x1={0} y1={dy * 2} x2={width} y2={dy * 2} />
        <line x1={dx} y1={0} x2={dx} y2={height} />
        <line x1={dx * 2} y1={0} x2={dx * 2} y2={height} />
      </svg>
    </Block>
  )
}

export default RuleOfThirds
