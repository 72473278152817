import { useEffect } from 'preact/hooks'
import { useEffectSkipFirst } from '../use-effect-skip-first'

export const usePagedItems = ({ items = [], page = 1, pageSize = 10, setPage, query }) => {
  useEffectSkipFirst(() => {
    if (page !== 1 && setPage) {
      setPage(1)
    }
  }, [query])

  const index = (page - 1) * pageSize

  let filtered = items

  if (query) {
    const queryLowerCase = query.toLowerCase()
    filtered = items.filter((item) => {
      if (item.name && item.name.toLowerCase().includes(queryLowerCase)) return true
      if (item.email && item.email.toLowerCase().includes(queryLowerCase)) return true
      if (item.agency?.name.toLowerCase().includes(queryLowerCase)) return true
      return false
    })
  }

  const pages = Math.max(1, Math.ceil(filtered.length / pageSize))

  useEffect(() => {
    if (page > pages) {
      setPage(pages)
    }
  }, [page, pages])

  const sliced = filtered.slice(index, index + pageSize)

  return {
    items: sliced,
    pages
  }
}
