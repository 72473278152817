import { SpacerVertical } from '@sodra/bongo-ui'
import { h } from 'preact'
import { connect } from 'unistore/preact'

import Breadcrumbs from '../Breadcrumbs'

const getLinks = (user, currentUser) => {
  if (user.agency) {
    const isUser = currentUser.agencies.some((a) => a.id === user.agency.id)
    if (currentUser.isSuperadmin || isUser) {
      return [{ label: user.agency.name, path: `/agency/${user.agency.id}` }, { label: user.name }]
    }
  }
}

const UserBreadcrumbs = ({ user, currentUser, ...props }) => {
  const links = getLinks(user, currentUser)
  if (links) {
    return <Breadcrumbs links={links} {...props} />
  }
}

export default connect('currentUser')(UserBreadcrumbs)
