import { h } from 'preact'
import { useRef, useState } from 'preact/hooks'
import { Col } from 'jsxstyle/preact'
import { DropArea } from './DropArea'
import { AccountCircleIcon, Button, P } from '@sodra/bongo-ui'
import { post } from '../../api'

const getImageMetadata = (file) => {
  return new Promise((resolve) => {
    const image = new Image()
    const reader = new FileReader()
    reader.addEventListener('load', () => (image.src = reader.result))
    image.addEventListener('load', () => {
      resolve({ width: image.width, height: image.height, url: image.src })
    })
    reader.readAsDataURL(file)
  })
}

export const UploadImage = ({ onImageUploaded }) => {
  const fileInput = useRef()
  const [status, setStatus] = useState()

  const upload = async (file) => {
    setStatus('uploading')
    const { width, height } = await getImageMetadata(file)
    const {
      data: { signedUrl, uri }
    } = await post('/sign-url', { prefix: 'images-tmp', contentType: file.type })
    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })
    onImageUploaded(uri)
  }

  const handleDrop = (file) => {
    upload(file)
  }

  const handleFileInputChange = (e) => {
    const files = e.target.files
    const file = files[0]
    upload(file)
  }

  return (
    <Col alignItems="center" justifyContent="center" height="90%" position="relative">
      <DropArea onDrop={handleDrop} loading={status === 'uploading'}>
        <AccountCircleIcon size={264} fill="var(--on-surface-lighter)" />
        <P textAlign="center">
          Drop photo here
          <br />
          or
        </P>
        <Button onClick={() => fileInput.current.click()}>Choose file to upload</Button>
        <input
          type="file"
          ref={fileInput}
          accept="image/*"
          onChange={handleFileInputChange}
          style={{ display: 'none' }}
        />
      </DropArea>
    </Col>
  )
}
