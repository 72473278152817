import { Fragment, h } from 'preact'
import { routeTo } from '@sodra/prutt'

import { useLocalStorageState } from '../../use-local-storage-state'

import { usePagedItems } from '../use-paged-items'

import Listing from '../Listing'
import { Block } from 'jsxstyle/preact'
import { CheckmarkIcon, ProgressCircular, SearchIcon, SpacerHorizontal, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { Row } from 'jsxstyle'
import { ApproveButton } from '../ApproveButton'
import { OnboardingProgress } from '../OnboardingProgress'

const VoiceListing = ({ agency, currentUser }) => {
  const [page, setPage] = useLocalStorageState(`speechless:${agency.id}:voices:page`, 1)
  const [query, setQuery] = useLocalStorageState(`speechless:${agency.id}:voices:query`, '')

  const { items: voices, pages } = usePagedItems({
    items: agency.voices,
    page,
    query,
    setPage
  })

  return (
    <Fragment>
      <TextField
        autocomplete="off"
        simple
        clearable
        width="100%"
        icon={SearchIcon}
        label="Search"
        value={query}
        onInput={setQuery}
        onChange={setQuery}
      />
      <SpacerVertical />
      {voices.length === 0 && <Block color="var(--on-surface-lighter)">No voices found</Block>}
      <Listing
        page={page}
        pages={pages}
        setPage={setPage}
        items={voices.map((user) => ({
          data: user,
          picture: user.picture,
          title: user.name,
          text: (
            <Row alignItems="center">
              <Block>
                <Block>{user.name}</Block>
                <Block fontSize="14px" color="var(--on-surface-light)">
                  {user.email}
                </Block>
              </Block>
              <Block flex="1" />
              {currentUser.isSuperadmin && (
                <Fragment>
                  <OnboardingProgress progress={user.onboardingProgress} />
                  <SpacerHorizontal />
                </Fragment>
              )}
              {currentUser.isSuperadmin && <ApproveButton user={user} />}
            </Row>
          )
        }))}
        popupMenuOptions={[
          {
            label: 'Remove',
            onClick: (user) => routeTo(`/agency/${agency.id}/remove-voice/${user.id}`)
          }
        ]}
        onClick={(user) => routeTo(`/user/${user.id}`, { resetWindowScroll: true })}
      />
    </Fragment>
  )
}

export default VoiceListing
