import { Tooltip } from '@sodra/bongo-ui'
import { h } from 'preact'
import type { ComponentChildren } from "preact";

import { useRef, useState } from "preact/hooks"

type Props = {
    tooltipText: string
    children: ComponentChildren
}

export function TooltipWrapper({ tooltipText, children }: Props) {
    const ref = useRef<HTMLDivElement | null>(null)

    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <div
            ref={ref}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <Tooltip
                generator={ref}
                text={tooltipText}
                visible={showTooltip}
            />
            {children}
        </div>
    )
}