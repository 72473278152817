import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'

import CornerHandle from './CornerHandle'

const Area = ({ top, left, width, height, move, zoomIn, zoomOut }) => {
  const [focus, setFocus] = useState()

  const handleFocus = () => setFocus(true)

  const handleBlur = () => setFocus(false)

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowRight') {
      move(-10, 0)
    }
    if (e.key === 'ArrowLeft') {
      move(10, 0)
    }
    if (e.key === 'ArrowUp') {
      move(0, 10)
    }
    if (e.key === 'ArrowDown') {
      move(0, -10)
    }
    if (e.key === '+') {
      zoomIn()
    }
    if (e.key === '-') {
      zoomOut()
    }
  }

  const border = focus ? '2px solid white' : '2px solid rgba(255, 255, 255, 0.5)'

  return (
    <Block
      class="Area"
      position="absolute"
      top={`${top}px`}
      left={`${left}px`}
      width={`${width}px`}
      height={`${height}px`}
      border={border}
      boxSizing="border-box"
      outline="none"
      userSelect="none"
      props={{
        tabIndex: 0,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onKeyDown: handleKeyDown
      }}
    >
      <CornerHandle corner="top-left" zoomIn={zoomIn} zoomOut={zoomOut} />
      <CornerHandle corner="top-right" zoomIn={zoomIn} zoomOut={zoomOut} />
      <CornerHandle corner="bottom-left" zoomIn={zoomIn} zoomOut={zoomOut} />
      <CornerHandle corner="bottom-right" zoomIn={zoomIn} zoomOut={zoomOut} />
    </Block>
  )
}

export default Area
