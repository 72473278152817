import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Row, Block } from 'jsxstyle/preact'
import { AddIcon, Button, H2, SearchIcon, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { goBack, Route, routeTo, Switch } from '@sodra/prutt'

import { get } from '../../api'
import { useLocalStorageState } from '../../use-local-storage-state.js'

import { usePagedItems } from '../use-paged-items.js'
import Listing from '../Listing'

import AddAgency from './AddAgency.js'
import DeleteAgency from './DeleteAgency'

const Agencies = () => {
  const [page, setPage] = useLocalStorageState(`speechless:agencies:page`, 1)
  const [query, setQuery] = useLocalStorageState(`speechless:agencies:query`, '')
  const [allAgencies, setAllAgencies] = useState([])
  const [counter, setCounter] = useState(0)

  const refetch = () => setCounter((counter) => counter + 1)

  useEffect(() => {
    get(`/agencies`).then(({ data: agencies }) => setAllAgencies(agencies))
  }, [counter])

  const { items: agencies, pages } = usePagedItems({
    items: allAgencies,
    page,
    query,
    setPage
  })

  const closeDialog = () => goBack(`/agencies`)

  return (
    <Block margin="auto" padding="20px" maxWidth="800px">
      <SpacerVertical large />
      <Row alignItems="center">
        <H2 size="4">Agencies</H2>
        <Block flex="1" />
        <Button contained icon={AddIcon} onClick={() => routeTo(`/agencies/add`)}>
          Add
        </Button>
      </Row>
      <SpacerVertical />
      <TextField
        autocomplete="off"
        simple
        clearable
        width="100%"
        icon={SearchIcon}
        label="Search"
        value={query}
        onInput={setQuery}
        onChange={setQuery}
      />
      <SpacerVertical />
      <Listing
        page={page}
        pages={pages}
        setPage={setPage}
        items={agencies.map((agency) => {
          return {
            data: agency,
            picture: agency.picture,
            title: agency.name,
            description: `${agency.voices} voice${agency.voices !== 1 ? 's' : ''}`
          }
        })}
        popupMenuOptions={[
          {
            label: 'Edit',
            onClick: (agency) => routeTo(`/agency/${agency.id}/settings-details`)
          },
          {
            label: 'Delete',
            onClick: (agency) => routeTo(`/agencies/delete/${agency.id}`)
          }
        ]}
        onClick={(agency) => routeTo(`/agency/${agency.id}`)}
      />
      <Switch>
        <Route
          path="/agencies/add"
          render={(props) => <AddAgency {...props} onClose={closeDialog} onAdd={refetch} />}
        />
        <Route
          path="/agencies/delete/:agencyId"
          render={({ agencyId }) => {
            const agency = agencies.find((agency) => agency.id === agencyId)
            return <DeleteAgency agency={agency} onClose={closeDialog} onDelete={refetch} />
          }}
        />
      </Switch>
    </Block>
  )
}

export default connect('isNarrow')(Agencies)
