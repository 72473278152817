import { h } from 'preact'
import { useRef, useState, useEffect } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'
import { Ripple } from './Ripple'

let nextRippleId = 1

const getNextRippleId = () => {
  const id = nextRippleId
  nextRippleId += 1
  return id
}

export const Ripples = ({ time, color, borderRadius, disabled }) => {
  const [ripples, setRipples] = useState([])
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const elem = useRef(null)
  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const handleTouchStart = (e) => {
    // Ignore multi touch
    if (e.touches.length === 1) {
      const touch = e.touches[0]
      createRipple(touch.clientX, touch.clientY, e.target)
    }
  }

  const handleMouseDown = (e) => {
    createRipple(e.clientX, e.clientY, e.target)
  }

  const createRipple = (clientX, clientY, target) => {
    var rect = target.getBoundingClientRect()
    const x = clientX - rect.left
    const y = clientY - rect.top
    const width = rect.width
    const height = rect.height
    setRipples((ripples) => [...ripples, { id: getNextRippleId(), x, y, width, height }])
  }

  const handleTouchMove = (e) => {
    const touch = e.touches[0]
    if (e.target !== document.elementFromPoint(touch.pageX, touch.pageY)) {
      cancelRipple()
    }
  }

  const handleTouchCancel = (e) => {
    cancelRipple()
  }

  const handleTouchEnd = (e) => {
    e.preventDefault()
  }

  const cancelRipple = () => {
    setRipples([])
  }

  useEffect(() => {
    if (elem.current && !disabled) {
      const eventElem = elem.current
      eventElem.addEventListener('touchstart', handleTouchStart, { passive: true })
      eventElem.addEventListener('touchmove', handleTouchMove, { passive: true })
      eventElem.addEventListener('touchcancel', handleTouchCancel, { passive: true })
      //eventElem.addEventListener('touchend', handleTouchEnd, { passive: false })
      return () => {
        eventElem.removeEventListener('touchstart', handleTouchStart)
        eventElem.removeEventListener('touchmove', handleTouchMove)
        eventElem.removeEventListener('touchcancel', handleTouchCancel)
        //eventElem.removeEventListener('touchend', handleTouchEnd)
      }
    }
  }, [elem.current, disabled])

  useEffect(() => {
    if (elem.current && !disabled) {
      const eventElem = elem.current
      supportsHover && eventElem.addEventListener('mousedown', handleMouseDown, { passive: true })
      return () => {
        supportsHover && eventElem.removeEventListener('mousedown', handleMouseDown)
      }
    }
  }, [elem.current, supportsHover, disabled])

  const rippleDone = () => {
    if (isMounted.current) {
      setRipples((ripples) => ripples.slice(1, ripples.length))
    }
  }

  /* zIndex is needed to prevent bug in Safari */
  return (
    <Block
      position="absolute"
      overflow="hidden"
      zIndex="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      borderRadius={borderRadius}
      props={{ ref: elem }}
    >
      {ripples.map((ripple) => (
        <Ripple key={ripple.id} {...ripple} time={time} color={color} onDone={rippleDone} />
      ))}
    </Block>
  )
}
