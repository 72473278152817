import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'

export const Outline = ({ time = 200, color, width, margin, borderRadius }) => {
  const [animating, setAnimating] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setAnimating(true)
    }, 0)
  }, [])

  return (
    <Block
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      borderRadius={borderRadius}
      borderWidth={width || '1px'}
      borderStyle="solid"
      borderColor={color || 'var(--container-outline)'}
      margin={margin}
      opacity={animating ? '1' : '0'}
      transition={`opacity ${time}ms ease-in-out`}
      pointerEvents="none"
    ></Block>
  )
}
