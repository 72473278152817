import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Col } from 'jsxstyle/preact'
import { Button, EditIcon, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import {
  removeCurrentUserPicture,
  showSnackbar,
  updateCurrentUser,
  updateCurrentUserPicture
} from '../actions'

import Avatar from './Avatar'
import ChangePassword from './ChangePassword'
import PictureDialog from './PictureDialog'

const MyAccount = ({ currentUser, isUpdatingCurrentUser }) => {
  const [name, setName] = useState(currentUser.name)
  const [email, setEmail] = useState(currentUser.email)

  if (currentUser.agency) {
    routeTo(`/user/${currentUser.id}`, true)
  }

  const handleSave = async () => {
    if (await updateCurrentUser({ name, email })) {
      showSnackbar('User details saved')
    }
  }

  const updatePicture = async (url) => {
    if (await updateCurrentUserPicture(url)) {
      showSnackbar('Picture saved')
      return true
    }
  }

  const removePicture = async () => {
    if (await removeCurrentUserPicture()) {
      return true
    }
  }

  return (
    <Col alignItems="flex-start" margin="auto" width="calc(100% - 40px)" maxWidth="500px">
      <SpacerVertical large />
      <Col alignItems="center">
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() => routeTo('/account/change-picture')}
          size="150"
          src={currentUser.picture}
          name={name}
        />
      </Col>
      <SpacerVertical />
      <TextField width="100%" label="Name" value={name} onInput={setName} />
      <SpacerVertical />
      <TextField width="100%" label="Email" value={email} onInput={setEmail} />
      <SpacerVertical />
      <Button contained onClick={handleSave} loading={isUpdatingCurrentUser}>
        Save
      </Button>
      <SpacerVertical large />
      <Button marginLeft="-15px" onClick={() => routeTo('/account/change-password')}>
        Change password
      </Button>
      <Route path="/account/change-password" component={ChangePassword} />
      <Route
        path="/account/change-picture"
        render={() => (
          <PictureDialog
            title="Profile picture"
            url={currentUser.picture}
            name={currentUser.name}
            onUpdate={updatePicture}
            onRemove={removePicture}
            onClose={() => goBack('/account')}
          />
        )}
      />
    </Col>
  )
}

export default connect('currentUser, isUpdatingCurrentUser')(MyAccount)
