import { h } from 'preact'
import { useState } from 'preact/hooks'
import { BasicDialog, NativeSelect, SelectButton, SpacerVertical } from '@sodra/bongo-ui'

import { accentOptions } from '../options'
import { SelectLanguage } from '../SelectLanguage'
import { getName } from '../SelectLanguage/languages'

type Props = {
  onAdd: (language: Language) => void
  onClose: () => void
}
const AddLanguage = ({ onAdd, onClose }: Props) => {
  const [language, setLanguage] = useState('English')
  const [accent, setAccent] = useState('')
  const [showSelectLanguage, setShowSelectLanguage] = useState<boolean>(false)

  const handleSave = () => {
    if (onAdd) {
      onAdd({ language, accent })
    }
    onClose()
  }

  return (
    <BasicDialog
      title="Add language"
      primaryActionText="Add"
      onPrimaryActionClick={handleSave}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <SpacerVertical />
      <SelectButton
        width="100%"
        label="Language"
        selected={language ? { text: language } : undefined}
        onClick={() => setShowSelectLanguage(true)}
      />
      <SpacerVertical />
      <NativeSelect
        width="100%"
        label="Accent (optional)"
        value={accent}
        onChange={setAccent}
        options={accentOptions}
      />
      <SpacerVertical large />

      {showSelectLanguage && (
        <SelectLanguage
          onClose={() => {
            setShowSelectLanguage(false)
          }}
          onSelect={(language) => {
            setLanguage(getName(language))
          }}
        />
      )}
    </BasicDialog>
  )
}

export default AddLanguage
