import { h } from 'preact'
import { useState, useRef } from 'preact/hooks'
import { InlineBlock, Block, Row } from 'jsxstyle/preact'
import { useMedia } from '@sodra/use-media'
import { CloseIcon } from './icons'
import { Highlight } from './highlights'
import { Outline } from './Outline'
import { Ripples } from './Ripples'
import { IconButton } from './IconButton'

import { useKeyPressedHighlight } from './use-key-pressed-highlight'
import { Tooltip } from './Tooltip'

export const SelectChip = ({
  name = 'select',
  label,
  tooltipText,
  value,
  icon: Icon,
  visual,
  outline,
  onClick,
  delayClick = false,
  onClear,
  clearTooltipText,
  disabled,
  props,
  ...style
}) => {
  const borderRadius = '20px'
  const highlightColor = value ? 'var(--accent-highlight)' : undefined

  const elem = useRef()

  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [keyPressedHighlights, addKeyPressedHighlight] = useKeyPressedHighlight({
    color: highlightColor,
    borderRadius
  })

  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const handleClick = (e) => {
    e.stopPropagation()
    setTimeout(
      () => {
        setFocus(false)
        if (onClick) {
          onClick({ type: 'pointer', x: e.clientX, y: e.clientY, target: e.target })
        }
      },
      delayClick ? 180 : 0
    )
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      // Space
      e.preventDefault()
      e.stopPropagation()
      addKeyPressedHighlight()
      onClick && onClick({ type: 'keyboard', target: elem.current })
    }
  }

  const handleMouseEnter = (e) => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  const handleFocus = (e) => setFocus(true)

  const handleBlur = (e) => setFocus(false)

  const handleClear = (e) => {
    setTimeout(() => {
      setHover(false)
      if (e.type === 'keyboard') {
        // Give focus to select chip when using keyboard
        elem.current && elem.current.focus()
      } else {
        setFocus(false)
      }
      onClear && onClear()
    }, 180)
  }

  const text = value || label

  return (
    <InlineBlock
      class={outline && !value ? undefined : 'bui-show-keyboard-focus'}
      position="relative"
      outline="none"
      userSelect="none"
      WebkitUserSelect="none"
      WebkitTapHighlightColor="rgba(0, 0, 0, 0)"
      backgroundColor={
        value ? 'var(--accent-surface)' : !outline ? 'var(--container-background)' : undefined
      }
      boxSizing="border-box"
      borderRadius={borderRadius}
      height="40px"
      padding="0 15px 0 15px"
      cursor={!disabled ? 'pointer' : 'not-allowed'}
      props={{
        ref: elem,
        onMouseEnter: !disabled && supportsHover ? handleMouseEnter : null,
        onMouseLeave: !disabled && supportsHover ? handleMouseLeave : null,
        onFocus: !disabled ? handleFocus : null,
        onBlur: !disabled ? handleBlur : null,
        tabIndex: !disabled ? 0 : -1,
        onClick: !disabled ? handleClick : null,
        onKeyDown: !disabled ? handleKeyDown : null,
        role: 'button',
        name,
        ...props
      }}
      {...style}
    >
      {(!outline || value) && (
        <Block>
          {(hover || focus) && (
            <Highlight color={highlightColor} borderRadius={borderRadius} opacity="0.15" />
          )}
          {focus && <Highlight color={highlightColor} borderRadius={borderRadius} />}
        </Block>
      )}
      {outline && !value && (
        <Block>
          <Outline
            time={0}
            borderRadius={borderRadius}
            color={!disabled ? 'var(--container-outline)' : 'var(--container-outline-lighter)'}
          />
          {(hover || focus) && (
            <Outline borderRadius={borderRadius} color="var(--on-surface-light)" />
          )}
          {focus && <Outline borderRadius={borderRadius} color="var(--accent)" width="3px" />}
        </Block>
      )}
      {!disabled && keyPressedHighlights}
      <Ripples color={highlightColor} borderRadius={borderRadius} disabled={disabled} />
      <Row
        height="100%"
        alignItems="center"
        color={value ? 'var(--on-accent)' : 'var(--on-surface-light)'}
        opacity={disabled ? 0.33 : 1}
      >
        {Icon && (
          <Block marginLeft="-7px" marginRight="5px">
            <Icon fill={value ? 'var(--on-accent)' : 'var(--on-surface-light)'} />
          </Block>
        )}
        {visual && (
          <Block marginLeft="-10px" marginRight="5px">
            {visual}
          </Block>
        )}
        <Block>{text}</Block>
        {value && (
          <IconButton
            text
            small
            marginRight="-10px"
            icon={CloseIcon}
            color="var(--on-accent)"
            highlightColor="var(--accent-highlight)"
            onClick={handleClear}
            tooltipText={clearTooltipText}
          />
        )}
      </Row>
      {tooltipText && <Tooltip visible={focus || hover} generator={elem} text={tooltipText} />}
    </InlineBlock>
  )
}
