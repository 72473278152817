import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'
import { goBack, Route, routeTo } from '@sodra/prutt'
import { AddIcon, CloseIcon, Divider, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'

import { deleteUserInvitation, removeAgencyUser } from '../../actions'

import Avatar from '../Avatar'
import LargeDialog from '../LargeDialog'

import InviteUser from './InviteUser'
import Section from '../Section'

export const getNameFromEmail = (email = '') => {
  const name = email
    .substring(0, email.indexOf('@'))
    .replace(/\W+/g, ' ')
    .split(' ')
    .slice(0, 2)
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(' ')
  return name
}

const ManageAccess = ({ agency, isDeletingInvitation, isRemovingUser }) => {
  const onClose = () => goBack(`/agency/${agency.id}`)

  return (
    <LargeDialog
      title="Agency administrators"
      actions={[{ text: 'Close', onClick: onClose }]}
      secondaryActions={[
        {
          icon: AddIcon,
          text: 'Add',
          onClick: () => routeTo(`/agency/${agency.id}/manage-access/invite-user`)
        }
      ]}
      onClose={onClose}
    >
      <List>
        {agency.users.map((user) => {
          return (
            <ListItem
              visual={<Avatar size={40} src={user.picture} name={user.name} />}
              text={user.name}
              secondaryText={user.email}
              actionIcon={CloseIcon}
              actionLoading={isRemovingUser === user.id}
              onActionClick={() => removeAgencyUser(user.id)}
            />
          )
        })}
      </List>
      {agency.users.length > 0 && agency.userInvitations.length > 0 && (
        <Fragment>
          <SpacerVertical />
          <Divider />
          <SpacerVertical />
        </Fragment>
      )}
      {agency.userInvitations.length > 0 && (
        <List>
          {agency.userInvitations.map((invitation) => {
            return (
              <ListItem
                visual={<Avatar size={40} name={getNameFromEmail(invitation.email)} />}
                text={invitation.email}
                secondaryText="Pending"
                actionIcon={CloseIcon}
                actionLoading={isDeletingInvitation === invitation.id}
                onActionClick={() => deleteUserInvitation(invitation.id)}
              />
            )
          })}
        </List>
      )}
      <Route path="/agency/:agencyId/manage-access/invite-user" component={InviteUser} />
    </LargeDialog>
  )
}

export default connect('agency, isRemovingUser, isDeletingInvitation')(ManageAccess)
