import { h } from 'preact'
import { useEffect } from 'preact/hooks'

export const useAddNewlineOnAltEnter = (inputEl, onInput, enabled = true) => {
  useEffect(() => {
    if (!inputEl || !enabled) {
      return
    }

    const handleKeyDown = (e) => {
      // NOTE:
      // * Safari adds a newline character by default when
      // pressing alt+enter, we need to prevent default
      // * Chrome doesn’t add a newline character by default,
      // we need to add it "manually"
      if (e.altKey && e.key === 'Enter' && enabled) {
        e.preventDefault()
        // Add newline
        const index = e.target.selectionStart
        const newValue = e.target.value.slice(0, index) + '\n' + e.target.value.slice(index)
        e.target.value = newValue
        e.target.selectionStart = index + 1
        e.target.selectionEnd = index + 1
        if (onInput) {
          onInput(newValue, e)
        }
      }
    }

    inputEl.addEventListener('keydown', handleKeyDown)
    return () => {
      inputEl.removeEventListener('keydown', handleKeyDown)
    }
  }, [inputEl, onInput, enabled])
}
