import { h } from 'preact'

const Mask = ({ top, left, width, height }) => {
  const aspectRatio = width / height
  let ww
  let hh
  if (width > height) {
    ww = 1
    hh = 1 / aspectRatio
  } else {
    hh = 1
    ww = 1 * aspectRatio
  }

  return (
    <svg
      viewBox={`0 0 ${ww} ${hh}`}
      width={width}
      height={height}
      overflow="visible"
      style={`position: absolute; top: ${top}px; left: ${left}px`}
    >
      <path
        d={`M -40 -40 L -40 41 L 41 41 L 41 -40 Z M 0 0 L ${ww} 0 L ${ww} ${hh} L 0 ${hh} Z `}
        fill="#202124 "
        fill-rule="evenodd"
        fill-opacity=".66"
      ></path>
    </svg>
  )
}

export default Mask
