import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'

type Props = {
  time?: number
  color?: string
  width?: string
  margin?: string
  borderRadius: string
}

export const Outline = ({
  time = 200,
  color = 'var(--container-outline)',
  width = '1px',
  margin = '0px',
  borderRadius
}: Props) => {
  const [animating, setAnimating] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setAnimating(true)
    }, 0)
  }, [])

  return (
    <Block
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      borderRadius={borderRadius}
      borderWidth={width}
      borderStyle="solid"
      borderColor={color}
      margin={margin}
      opacity={animating ? '1' : '0'}
      transition={`opacity ${time}ms ease-in-out`}
      pointerEvents="none"
    ></Block>
  )
}
