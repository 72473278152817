import { Button, IconButton, PlayIcon, StopIcon } from '@sodra/bongo-ui'
import { useMedia } from '@sodra/use-media'
import { Block, Row } from 'jsxstyle/preact'
import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { colors } from '../../colors'
import { getItem } from '../../storage'
import { useLocalStorageState } from '../../use-local-storage-state'
import AudioDeviceSelector from '../AudioRecorder/AudioDeviceSelector'
import { AudioEditor } from '../AudioRecorder/AudioEditor'
import AudioPermissionDialog from '../AudioRecorder/AudioPermissionDialog'
import { useMediaRecorder } from '../AudioRecorder/use-media-recorder'
import { useAudioBufferPlayer } from '../AudioRecorder/useAudioBufferPlayer'
import { usePermissionState } from '../AudioRecorder/usePermissionState'
import { Waveform } from '../AudioRecorder/Waveform'
import WaveformAndTimer from '../AudioRecorder/WaveformAndTimer'
import { AudioFileSelect } from './AudioFileSelect'
import { RecordButton } from './RecordButton'
import { useTrimAudioBufferDebounced } from './useTrimAudioBufferDebounced'

const LOAD_DEMO_AUDIO = false

type Props = {
  onChange: (trimmedAudioBuffer: AudioBuffer | null) => void
  onAssign?: () => void
}

export function Recorder({ onChange, onAssign }: Props) {
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null)
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null)

  const permissionState = usePermissionState('microphone')
  const [isAudioAllowed, setIsAudioAllowed] = useLocalStorageState('slob.isAudioAllowed', false)
  const [showAudioPermissionDialog, setShowAudioPermissionDialog] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState<string | null>(null)
  const [stream, setStream] = useState<MediaStream | null>(null)

  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)

  useEffect(() => {
    const audioContext = new AudioContext()
    setAudioContext(audioContext)
  }, [])

  useEffect(() => {
    if (permissionState === 'granted') {
      setIsAudioAllowed(true)
    }
    if (permissionState === 'prompt' || permissionState === 'denied') {
      setIsAudioAllowed(false)
    }
  }, [permissionState])

  useEffect(() => {
    if (audioBuffer) {
      setEnd(audioBuffer.duration)
    }
  }, [audioBuffer])

  useTrimAudioBufferDebounced({
    audioBuffer,
    start,
    end,
    onChange,
    delay: 200
  })

  useEffect(() => {
    if (LOAD_DEMO_AUDIO) {
      const loadAudio = () => {
        fetch(
          'https://storage.googleapis.com/voicemachine/audio/40640ca5-ef18-4f56-ac14-4233a2c7841b.m4a'
        ).then((response) => {
          response
            .arrayBuffer()
            .then((buffer) =>
              new AudioContext().decodeAudioData(buffer).then((data) => setAudioBuffer(data))
            )
        })
      }

      loadAudio()
    }
  }, [])

  if (!isAudioAllowed || permissionState === 'prompt') {
    return (
      <Row minHeight="120px" alignItems="center" justifyContent="center">
        {showAudioPermissionDialog && (
          <AudioPermissionDialog
            onGrant={() => {
              setIsAudioAllowed(true)
            }}
            onCancel={() => {
              setShowAudioPermissionDialog(false)
            }}
          />
        )}
        <Button onClick={() => setShowAudioPermissionDialog(true)} contained>
          Allow microphone access to start recording
        </Button>
      </Row>
    )
  }

  if (!selectedDevice && selectedDevice !== '') {
    return (
      <Row minHeight="120px" alignItems="center" justifyContent="center">
        <AudioDeviceSelector deviceId={selectedDevice} onChange={setSelectedDevice} />
      </Row>
    )
  }

  useEffect(() => {
    let streamToClose: MediaStream
    navigator.mediaDevices
      .getUserMedia({ audio: { deviceId: selectedDevice }, video: false })
      .then((stream) => {
        streamToClose = stream
        setStream(stream)
      })
      .catch(function (e) {
        console.log(e)
      })
    return () => {
      if (streamToClose) {
        setStream(null)
        streamToClose.getTracks().forEach((track) => track.stop())
      }
    }
  }, [selectedDevice])

  const {
    currentTime,
    play,
    stop,
    state: playerState
  } = useAudioBufferPlayer({
    registerKeyboardEvent: true,
    audioBuffer,
    start,
    end
  })

  const onDone = async (blob: Blob) => {
    if (!audioContext) return
    const arrayBuffer = await blob.arrayBuffer()
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
    setAudioBuffer(audioBuffer)
  }

  const [mediaRecorderState, setMediaRecorderState] = useState<string | null>(null)
  const mediaRecorder = useMediaRecorder({ stream, onStateChange: setMediaRecorderState, onDone })

  const isRecording = mediaRecorderState === 'recording'

  const toggleRecording = () => {
    if (!mediaRecorder) return
    if (isRecording) {
      mediaRecorder.stop()
    } else {
      if (audioBuffer) {
        clearRecording()
      }
      mediaRecorder.start()
    }
  }

  const togglePlaying = () => {
    if (playerState === 'playing') {
      stop()
    } else {
      play()
    }
  }

  const clearRecording = () => {
    setAudioBuffer(null)
    setStart(0)
    setEnd(0)
  }

  return (
    <Block background="var(--surface-floating)">
      <Row justifySelf="start" padding="10px 10px 0" gap={10}>
        <AudioDeviceSelector deviceId={selectedDevice} onChange={setSelectedDevice} />
        {stream && (
          <Waveform color={colors['--error']} height="40px" width="100px" stream={stream} />
        )}
      </Row>
      <Row alignItems="center" height="120px" gap={10} padding="0 20px">
        <AudioFileSelect onChange={(buffer) => setAudioBuffer(buffer)} />
        <RecordButton state={isRecording ? 'recording' : 'idle'} onChange={toggleRecording} />
        {!audioBuffer && audioContext && (
          <WaveformAndTimer stream={stream} mediaRecorderState={mediaRecorderState} flex="1" />
        )}
        {audioBuffer && (
          <Fragment>
            <IconButton
              icon={playerState !== 'playing' ? PlayIcon : StopIcon}
              outlined
              color="var(--on-surface)"
              onClick={togglePlaying}
            />
            <AudioEditor
              audioBuffer={audioBuffer}
              currentTime={currentTime}
              start={start}
              setStart={setStart}
              end={end}
              setEnd={setEnd}
            />
            <Button tooltipText="Hotkey: a" disabled={!onAssign} tiny onClick={onAssign}>
              Assign to
              <br />
              selected line
            </Button>
          </Fragment>
        )}
      </Row>
    </Block>
  )

  return null
}
