import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Block, InlineBlock } from 'jsxstyle/preact'

import { routeTo } from '@sodra/prutt'
import { Button, Form, P, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { acceptLinkInvitation, fetchLinkInvitation } from '../actions'

import LandingPage from './LandingPage'
import Spinner from './Spinner'
import Avatar from './Avatar'

const AcceptLinkInvitation = ({ currentUser, slug, isAcceptingInvitation }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [link, setLink] = useState()
  const [linkError, setLinkError] = useState()

  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  useEffect(() => {
    fetchLinkInvitation(slug).then(setLink).catch(setLinkError)
  }, [])

  if (linkError) {
    return (
      <LandingPage action="login">
        <Block textAlign="center">
          <Block component="h1" fontSize="14px" lineHeight="36px" fontWeight="normal">
            No invitation found. Maybe it was recalled by the agency owner?
          </Block>
        </Block>
      </LandingPage>
    )
  }

  if (!link) {
    return <Spinner />
  }

  const handleSubmit = async () => {
    setEmailError('')
    setPasswordError('')

    if (!currentUser) {
      let hasError = false
      if (email.trim() === '') {
        setEmailError('Please enter email')
        hasError = true
      }
      if (password.trim() === '') {
        setPasswordError('Please enter password')
        hasError = true
      }
      if (hasError) {
        return
      }
    }

    if (await acceptLinkInvitation(slug, { userId: currentUser?.id, email, password })) {
      routeTo('/')
    }
  }

  return (
    <LandingPage action="login">
      <Block textAlign="center">
        <Block component="h1" fontSize="14px" lineHeight="36px" fontWeight="normal">
          Join
          <br />
          <InlineBlock fontSize="34px" lineHeight="36px">
            {link.agency.name}
          </InlineBlock>
        </Block>
        {link.agency.picture && (
          <Avatar src={link.agency.picture} name={link.agency.name} size={90} />
        )}
      </Block>
      <SpacerVertical large />
      {currentUser && (
        <Block textAlign="center">
          <Button contained onClick={handleSubmit} loading={isAcceptingInvitation}>
            Accept invitation
          </Button>
        </Block>
      )}
      {!currentUser && (
        <Form onSubmit={handleSubmit}>
          <TextField
            width="100%"
            autocomplete="off"
            name="new-email"
            label="Email"
            value={email}
            onInput={setEmail}
            errorText={emailError}
          />
          <SpacerVertical />
          <TextField
            width="100%"
            type="password"
            autocomplete="new-password"
            name="new-password"
            label="Set password"
            value={password}
            onInput={setPassword}
            errorText={passwordError}
          />
          <SpacerVertical />
          <P>
            By signing up you agree to the Speechless{' '}
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1ev23IgNfPYeorSRqyTG0izEa_cyuF9GI/view"
            >
              privacy policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1S-kb4kAdd6OurqWKytzSoWR0i-xcg-JW/view"
            >
              artist terms and conditions
            </a>
            .
          </P>
          <SpacerVertical large />
          <Button contained type="submit" loading={isAcceptingInvitation}>
            Continue
          </Button>
          <SpacerVertical />
        </Form>
      )}
    </LandingPage>
  )
}

export default connect('currentUser, isAcceptingInvitation')(AcceptLinkInvitation)
