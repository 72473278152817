export const getBackgroundColor = (type = 'outlined') => {
  if (type === 'contained') {
    return 'var(--accent-surface)'
  }
  if (type === 'error') {
    return 'var(--error)'
  }
  //return 'var(--surface)'
}

export const getColor = (type = 'outlined') => {
  if (type === 'contained') {
    return 'var(--on-accent)'
  }
  if (type === 'error') {
    return 'var(--on-error)'
  }
  return 'var(--accent-text)'
}

export const getBorderColor = (type = 'outlined') => {
  if (type === 'contained') {
    return 'var(--on-accent)'
  }
  if (type === 'error') {
    return 'var(--on-error)'
  }
  return 'var(--accent)'
}

export const getIconSize = ({ small, tiny }) => {
  if (small || tiny) {
    return 20
  }
  return 24
}

export const getLoadingSize = ({ small, tiny }) => {
  if (small || tiny) {
    return 16
  }
  return 20
}

export const getBorder = ({ type = 'outlined', color = 'var(--accent)', loading, disabled }) => {
  if (type === 'outlined') {
    if (disabled || loading) {
      return 'solid 1px var(--container-outline-lighter)'
    }
    return `solid 1px ${color}`
  }
  return 'none'
}

export const getFontSize = ({ large, small, tiny, capitalized }) => {
  let fontSize = 16
  if (large) {
    fontSize = 18
  }
  if (small) {
    fontSize = 14
  }
  if (tiny) {
    fontSize = 12
  }
  if (capitalized) {
    fontSize = fontSize - 3
  }
  return `${fontSize}px`
}

export const getLineHeight = ({ large, small, tiny, capitalized }) => {
  let lineHeight = 17
  if (large) {
    lineHeight = 29
  }
  if (small) {
    lineHeight = 12
  }
  if (tiny) {
    lineHeight = 10
  }
  if (capitalized) {
    lineHeight -= 3
  }
  return `${lineHeight}px`
}

export const getHeight = ({ large, small, tiny }) => {
  if (large) {
    return '50px'
  }
  if (small || tiny) {
    return '30px'
  }
  return '40px'
}

export const getPadding = ({ type, large, small, tiny }) => {
  let padding = 15
  if (large) {
    padding = 25
  }
  if (small) {
    padding = 10
  }
  if (tiny) {
    padding = 7
  }
  if (type === 'outlined') {
    // Remove 1px to make room for 1px border
    padding -= 1
  }
  return `0 ${padding}px`
}
