
type OscilloScopeOptions = {
    ctx: CanvasRenderingContext2D
    color?: string
    lineWidth?: number
    x: number
    y: number
    width: number
    height: number
    analyser: AnalyserNode
    dataArray: Uint8Array
}

export function drawOscilloscope({
    ctx,
    x, y,
    width, height,
    lineWidth = 1,
    color = 'white',
    analyser,
    dataArray
}: OscilloScopeOptions) {

    ctx.save()
    ctx.translate(x, y)

    ctx.lineWidth = lineWidth * window.devicePixelRatio
    ctx.strokeStyle = color

    ctx.beginPath()
    const bufferLength = analyser.frequencyBinCount

    let sliceWidth = (width * 1.0) / bufferLength
    let x1 = 0

    for (let i = 0; i < bufferLength; i++) {
        let v = dataArray[i] / 128.0
        let y1 = (v * height) / 2

        if (i === 0) {
            ctx.moveTo(x1, y1)
        } else {
            ctx.lineTo(x1, y1)
        }

        x1 += sliceWidth
    }

    ctx.lineTo(width, height / 2)
    ctx.stroke()

    ctx.restore()
}