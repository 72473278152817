import { h } from 'preact'

import { useEffect, useState, useRef } from 'preact/hooks'
import { Col } from 'jsxstyle/preact'
import { Highlight, ProgressCircular } from '@sodra/bongo-ui'

export const DropArea = ({ children, onDrop, loading }) => {
  const [numDragOver, setNumDragOver] = useState(0)

  const dropRef = useRef()

  const handleDragEnter = (e) => {
    e.preventDefault()
    setNumDragOver((num) => num + 1)
    // TODO: Check image file?
    // if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
    //   console.log('files', e.dataTransfer.files)
    // }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setNumDragOver((num) => num - 1)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDragEnd = (e) => {
    e.preventDefault()
    setNumDragOver(0)
  }

  const fileMimeTypes = ['image/jpeg', 'image/png']
  const isImageFile = (file) => {
    return fileMimeTypes.includes(file.type)
  }

  const getImageFile = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (isImageFile(files[i])) {
        return files[i]
      }
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setNumDragOver(0)
    const file = getImageFile(e.dataTransfer.files)
    if (file) {
      onDrop(file)
    }
  }

  useEffect(() => {
    if (dropRef.current) {
      const elem = dropRef.current
      elem.addEventListener('dragenter', handleDragEnter)
      elem.addEventListener('dragleave', handleDragLeave)
      elem.addEventListener('dragover', handleDragOver)
      elem.addEventListener('dragend', handleDragEnd)
      elem.addEventListener('drop', handleDrop)
      return () => {
        elem.removeEventListener('dragenter', handleDragEnter)
        elem.removeEventListener('dragleave', handleDragLeave)
        elem.removeEventListener('dragover', handleDragOver)
        elem.removeEventListener('dragend', handleDragEnd)
        elem.removeEventListener('drop', handleDrop)
      }
    }
  }, [dropRef.current])

  const dragOver = numDragOver > 0

  return (
    <Col
      class="DropArea"
      position="relative"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      border={dragOver > 0 ? '2px solid var(--accent)' : undefined}
      borderRadius="6px"
      props={{
        ref: dropRef
      }}
    >
      {children}
      {(dragOver > 0 || loading) && <Highlight />}
      <Col
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        alignItems="center"
        justifyContent="center"
        background="var(--modal-background)"
        opacity={dragOver > 0 || loading ? 0.8 : 0}
        transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
        whillChange="opacity"
        pointerEvents="none"
      >
        {loading && <ProgressCircular />}
      </Col>
    </Col>
  )
}
