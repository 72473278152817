import { store } from '../store'
import { get, patch, post, del } from '../api'

export const fetchAgency = async (agencyId) => {
  store.setState({ isFetchingAgency: true })
  try {
    const { data: agency } = await get(`/agencies/${agencyId}`)
    store.setState({ agency, isFetchingAgency: false })
    return agency
  } catch (error) {
    store.setState({ error, isFetchingAgency: false })
  }
}

export const createAgency = async (params) => {
  store.setState({ isCreatingAgency: true })
  try {
    const { data: agency } = await post(`/agencies`, params)
    store.setState({ agency, isCreatingAgency: false })
    return agency
  } catch (error) {
    store.setState({ error, isCreatingAgency: false })
  }
}

export const updateAgency = async (params) => {
  const agencyId = store.getState().agency.id
  store.setState({ isUpdatingAgency: true })
  try {
    const { data: agency } = await patch(`/agencies/${agencyId}`, params)
    store.setState({ agency, isUpdatingAgency: false })
    return agency
  } catch (error) {
    store.setState({ error, isUpdatingAgency: false })
  }
}

export const deleteAgency = async (agencyId) => {
  store.setState({ isDeletingAgency: true })
  try {
    await del(`/agencies/${agencyId}`)
    store.setState({ isDeletingAgency: false })
    if (store.getState().agency?.id === agencyId) {
      store.setState({ agency: undefined })
    }
    return true
  } catch (error) {
    store.setState({ error, isDeletingAgency: false })
  }
}

export const addAgencyVoice = async (params) => {
  const agencyId = store.getState().agency.id
  store.setState({ isUpdatingAgency: true })
  try {
    const { data: agency } = await post(`/agencies/${agencyId}/add-voice`, params)
    store.setState({ agency, isUpdatingAgency: false })
    return agency
  } catch (error) {
    store.setState({ error, isUpdatingAgency: false })
  }
}

export const createAgencyVoice = async (params) => {
  const agencyId = store.getState().agency.id
  store.setState({ isCreatingVoice: true })
  try {
    const {
      data: { user, agency }
    } = await post(`/agencies/${agencyId}/voices`, params)
    store.setState({ agency, isCreatingVoice: false })
    return user
  } catch (error) {
    store.setState({ error, isCreatingVoice: false })
  }
}

export const removeAgencyVoice = async (userId) => {
  const agencyId = store.getState().agency.id
  store.setState({ isRemovingVoice: userId })
  try {
    const { data: agency } = await del(`/agencies/${agencyId}/voices/${userId}`)
    store.setState({ agency, isRemovingVoice: undefined })
    return agency
  } catch (error) {
    store.setState({ error, isRemovingVoice: undefined })
  }
}

export const createLink = async () => {
  const agencyId = store.getState().agency.id
  store.setState({ isCreatingLink: true })
  try {
    const { data: agency } = await post(`/agencies/${agencyId}/link`)
    store.setState({ agency, isCreatingLink: false })
    return agency
  } catch (error) {
    store.setState({ error, isCreatingLink: false })
  }
}

export const deleteLink = async () => {
  const agencyId = store.getState().agency.id
  store.setState({ isDeletingLink: true })
  try {
    const { data: agency } = await del(`/agencies/${agencyId}/link`)
    store.setState({ agency, isDeletingLink: false })
    return agency
  } catch (error) {
    store.setState({ error, isDeletingLink: false })
  }
}

export const createUserInvitation = async (params) => {
  const agencyId = store.getState().agency.id
  store.setState({ isCreatingInvitation: true })
  try {
    const { data: agency } = await post(`/agencies/${agencyId}/user-invitations`, params)
    store.setState({ agency, isCreatingInvitation: false })
    return agency
  } catch (error) {
    store.setState({ error, isCreatingInvitation: false })
  }
}

export const createVoiceInvitation = async (params) => {
  const agencyId = store.getState().agency.id
  store.setState({ isCreatingInvitation: true })
  try {
    const { data: agency } = await post(`/agencies/${agencyId}/voice-invitations`, params)
    store.setState({ agency, isCreatingInvitation: false })
    return agency
  } catch (error) {
    store.setState({ error, isCreatingInvitation: false })
  }
}

export const deleteUserInvitation = async (invitationId) => {
  const agencyId = store.getState().agency.id
  store.setState({ isDeletingInvitation: invitationId })
  try {
    const { data: agency } = await del(`/agencies/${agencyId}/user-invitations/${invitationId}`)
    store.setState({ agency, isDeletingInvitation: undefined })
    return agency
  } catch (error) {
    store.setState({ error, isDeletingInvitation: undefined })
  }
}

export const deleteVoiceInvitation = async (invitationId) => {
  const agencyId = store.getState().agency.id
  store.setState({ isDeletingInvitation: invitationId })
  try {
    const { data: agency } = await del(`/agencies/${agencyId}/voice-invitations/${invitationId}`)
    store.setState({ agency, isDeletingInvitation: undefined })
    return agency
  } catch (error) {
    store.setState({ error, isDeletingInvitation: undefined })
  }
}

export const removeAgencyUser = async (userId) => {
  const agencyId = store.getState().agency.id
  store.setState({ isRemovingUser: userId })
  try {
    const { data: agency } = await del(`/agencies/${agencyId}/users/${userId}`)
    store.setState({ agency, isRemovingUser: false })
    return agency
  } catch (error) {
    store.setState({ error, isRemovingUser: false })
  }
}
