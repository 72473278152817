import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { Block, Col, Grid, Inline, Row } from 'jsxstyle/preact'
import {
  AddIcon,
  BackArrowIcon,
  Button,
  CheckmarkIcon,
  FaceIcon,
  H2,
  H3,
  Link,
  P,
  PlayIcon,
  SpacerVertical,
  VoiceIcon,
  VoiceOverIcon
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { fetchUser, getLineAudio } from '../../actions'

import Spinner from '../Spinner'

import UserBreadcrumbs from './UserBreadcrumbs'
import AudioSamples from './AudioSamples'
import UploadAudio from './UploadAudio'
import EditAudio from './EditAudio'
import DeleteAudio from './DeleteAudio'
import VoiceProfile from './VoiceProfile'
import ChangePassword from './ChangePassword'
import Section from '../Section'
import { bar, battle, studioQualityDemo } from '../SessionPage/exampleDialogues'
import { useQuery } from 'react-query'
import { getDialogueStats } from '../Session/getDialogueStats'

import './User.css'
import { SuccessDialog } from './SuccessDialog'
import AccountSettings from './AccountSettings'
import { Rates } from './Rates'
import { Equipment } from './Equipment'

function useFirstRender() {
  const ref = useRef(true)
  const firstRender = ref.current
  ref.current = false
  return firstRender
}

type Props = {
  userId: string
  user: User
  currentUser: User
  isUpdatingUser: boolean
}

const UserPage = ({ userId, user, currentUser, isUpdatingUser }: Props) => {
  useEffect(() => {
    fetchUser(userId)
  }, [userId])

  const lineAudioQuery = useQuery<LineAudio[]>(`line-audio-${userId}`, getLineAudio, {
    enabled: !!user && user.id === userId,
    refetchOnWindowFocus: true
  })

  if (!user || user.id !== userId || !lineAudioQuery.data) {
    return <Spinner />
  }

  const isUser = currentUser.agencies?.some((a: any) => a.id === user.agency?.id) || false
  const hasAccess = currentUser.isSuperadmin || isUser || currentUser.id === userId

  if (!hasAccess) {
    return routeTo('/', true)
  }

  return (
    <UserProfile
      lineAudio={lineAudioQuery.data}
      isUser={isUser}
      user={user}
      currentUser={currentUser}
      userId={userId}
      isUpdatingUser={isUpdatingUser}
    />
  )
}

type UserProfileProps = {
  isUser: boolean
  user: User
  currentUser: User
  userId: string
  lineAudio: LineAudio[]
  isUpdatingUser: boolean
}

function UserProfile({
  isUser,
  user,
  currentUser,
  userId,
  lineAudio,
  isUpdatingUser
}: UserProfileProps) {
  const firstRender = useFirstRender()
  const [isProfileDone, setIsProfileDone] = useState<boolean>(false)
  const [canShowSuccessDialog, setCanShowSuccessDialog] = useState<boolean>(true)

  const backUrl =
    isUser && user.agency
      ? `/agency/${user.agency.id}`
      : currentUser.isSuperadmin
      ? `/agencies`
      : ''

  const backLabel = 'Back'

  const studioQualityStats = getDialogueStats(lineAudio, user.id, studioQualityDemo(user))
  const barSceneKimStats = getDialogueStats(lineAudio, 'kim', bar)
  const barSceneRobinStats = getDialogueStats(lineAudio, 'robin', bar)

  const battleSceneStats = ['radioman', 'leader', 'airsupport']
    .map((characterId) => {
      const stats = getDialogueStats(lineAudio, characterId, battle)
      return { characterId, stats }
    })
    .reduce((obj, { characterId, stats }) => {
      obj[characterId] = stats
      return obj
    }, {} as { [characterId: string]: DialogueStats })

  const isVoiceProfileComplete =
    user.name &&
    user.name.trim() !== '' &&
    user.picture &&
    typeof user.yearOfBirth !== 'undefined' &&
    user.languages &&
    user.languages.length > 0 &&
    typeof user.gender !== 'undefined'

  const steps = [
    {
      id: 'voice-profile',
      label: 'Enter voice profile details',
      isCompleted: isVoiceProfileComplete
    },
    {
      id: 'audio-sample',
      label: 'Upload at least one voice demo',
      isCompleted: user.audio && user.audio.length > 0
    },
    {
      id: 'studio-quality-demo',
      label: 'Record studio quality demo',
      isCompleted: studioQualityStats.recorded
    },
    {
      id: 'example-scenes',
      label: 'Record bar scene',
      isCompleted: barSceneKimStats.recorded && barSceneRobinStats.recorded
    },
    {
      id: 'example-scenes',
      label: 'Record battle scene',
      isCompleted:
        battleSceneStats['radioman'].recorded &&
        battleSceneStats['leader'].recorded &&
        battleSceneStats['airsupport'].recorded
    }
  ]

  const complete = steps.every((step) => step.isCompleted)

  useEffect(() => {
    if (firstRender) {
      if (complete) {
        setCanShowSuccessDialog(false)
      }
      return
    }
    if (canShowSuccessDialog) {
      setIsProfileDone(complete)
    }
  }, [complete])

  const scrollToId = (id: string) => {
    document.querySelector(`#${id}`)?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const size = '5'

  return (
    <Fragment>
      {(isUser || currentUser.isSuperadmin) && <UserBreadcrumbs user={user} />}
      <SpacerVertical />
      <Col alignItems="center" margin="auto" width="calc(100% - 40px)" maxWidth="800px">
        {(isUser || currentUser.isSuperadmin) && backUrl && (
          <Button
            alignSelf="start"
            color="var(--on-surface)"
            icon={BackArrowIcon}
            onClick={() => goBack(backUrl)}
          >
            {backLabel}
          </Button>
        )}

        <SpacerVertical large />

        <Block width="100%">
          {user.id === userId && !complete && (
            <Block padding="20px" border="4px solid var(--accent)" borderRadius="5px">
              <p>Please complete the following steps to finish your profile.</p>
              <Block component="ul" margin="0" padding="0">
                {steps.map((step) => (
                  <Block component="li" margin="0" padding="5px 0">
                    <CheckmarkIcon fill="var(--accent)" opacity={step.isCompleted ? 1 : 0} />{' '}
                    <Link
                      textDecoration={step.isCompleted ? 'line-through' : ''}
                      opacity={step.isCompleted ? 0.5 : 1}
                      onClick={() => scrollToId(step.id)}
                    >
                      {step.label}
                    </Link>
                  </Block>
                ))}
              </Block>
            </Block>
          )}

          {isProfileDone && canShowSuccessDialog && (
            <SuccessDialog onClose={() => setCanShowSuccessDialog(false)} />
          )}

          <article id="voice-profile">
            <SpacerVertical huge />
            <H2 size={size}>Voice profile</H2>
            <SpacerVertical />
            <VoiceProfile />
          </article>

          <article id="voice-rates">
            <SpacerVertical huge />
            <H2 size={size}>Compensation</H2>
            <SpacerVertical />
            <Rates user={user} />
          </article>

          <article id="equipment">
            <SpacerVertical huge />
            <H2 size={size}>Studio equipment</H2>
            <SpacerVertical />
            <Equipment user={user} isUpdatingUser={isUpdatingUser} />
          </article>

          <article id="audio-sample">
            <SpacerVertical huge />
            <Row alignItems="flex-end" justifyContent="space-between">
              <H2 size={size}>Voice demos</H2>
            </Row>
            <SpacerVertical />
            <Section>
              <Row gap="10px">
                <P>
                  Please upload one or several voice demos. Each demo should only represent one job
                  type. Do not upload mixed showreels.
                </P>
                <Button
                  alignSelf="end"
                  contained
                  icon={AddIcon}
                  onClick={() => routeTo(`/user/${user.id}/upload-audio`)}
                >
                  Add
                </Button>
              </Row>
              <SpacerVertical />
              <AudioSamples user={user} />
              <SpacerVertical />
            </Section>
          </article>

          <article id="studio-quality-demo">
            <SpacerVertical huge />
            <H2 size={size}>Studio quality demo</H2>
            <SpacerVertical />
            <Section>
              <Grid gridTemplateColumns="1fr auto" alignItems="center" gap="20px 50px">
                <P>
                  The studio quality demo is mandatory. It’s the customer’s (and our) reference to
                  what audio quality to expect when hiring you. The studio quality demo must be
                  recorded in your studio with your standard equipment.
                </P>
                <Button
                  contained={!studioQualityStats.recorded}
                  onClick={() => routeTo(`/session/${user.id}/studio-quality-demo`)}
                >
                  {studioQualityStats.recorded ? 'Open scene' : 'Open scene'}
                </Button>
              </Grid>
            </Section>
          </article>

          <article id="example-scenes">
            <SpacerVertical huge />
            <H2 size={size}>Example scenes</H2>
            <SpacerVertical />
            <P>
              In order to provide our clients with better tools to determine which voice is a good
              fit for their characters we’ve written a set of scenes that can be played seamlessly
              between different actors.
            </P>
            <SpacerVertical />
            <P>
              Make your own interpretation of each character and read one part at a time. When
              completed, your recording can be played together with other actors in our voice
              library. How neat?!
            </P>
            <SpacerVertical large />

            {/* The Bar */}
            <H3 size="6">&#8220;The Bar&#8221;</H3>
            <SpacerVertical small />
            <Section>
              <P>In this first scene we hear two people discussing pop culture while in a bar.</P>
              <SpacerVertical />
              <Grid
                gridTemplateColumns="auto 1fr auto"
                alignItems="center"
                gap="20px 50px"
                padding="0 60px"
              >
                <Inline>As Kim</Inline>
                <Inline letterSpacing="10px">
                  {barSceneKimStats.recordedLines}/{barSceneKimStats.totalLines}
                </Inline>
                <Button
                  contained={!barSceneKimStats.recorded}
                  onClick={() =>
                    routeTo(`/session/${user.id}/bar-scene-kim`, { resetWindowScroll: true })
                  }
                >
                  {barSceneKimStats.recorded ? 'Open scene' : 'Open scene'}
                </Button>
                <Inline>As Robin</Inline>
                <Inline letterSpacing="10px">
                  {barSceneRobinStats.recordedLines}/{barSceneRobinStats.totalLines}
                </Inline>
                <Button
                  contained={!barSceneRobinStats.recorded}
                  onClick={() =>
                    routeTo(`/session/${user.id}/bar-scene-robin`, { resetWindowScroll: true })
                  }
                >
                  {barSceneRobinStats.recorded ? 'Open scene' : 'Open scene'}
                </Button>
              </Grid>
            </Section>
            <SpacerVertical large />
            {/* The Battle */}
            <H3 size="6">&#8220;The Battle&#8221;</H3>
            <SpacerVertical small />
            <Section>
              <P>
                An{' '}
                <a
                  href="https://en.wikipedia.org/wiki/Long-range_reconnaissance_patrol"
                  target="_blank"
                >
                  LRRP
                </a>{' '}
                team has been discovered and is pinned down with bullets snapping overhead as they
                return fire.
              </P>
              <SpacerVertical />
              <P>Lines are to be delivered with high intensity and speed.</P>
              <SpacerVertical />
              <Grid
                gridTemplateColumns="auto 1fr auto"
                alignItems="center"
                gap="20px 50px"
                padding="0 60px"
              >
                <Inline>As Radioman</Inline>
                <Inline letterSpacing="10px">
                  {battleSceneStats['radioman'].recordedLines}/
                  {battleSceneStats['radioman'].totalLines}
                </Inline>
                <Button
                  contained={!battleSceneStats['radioman'].recorded}
                  onClick={() =>
                    routeTo(`/session/${user.id}/battle-scene-radioman`, {
                      resetWindowScroll: true
                    })
                  }
                >
                  {battleSceneStats['radioman'].recorded ? 'Open scene' : 'Open scene'}
                </Button>

                <Inline>As Leader</Inline>
                <Inline letterSpacing="10px">
                  {battleSceneStats['leader'].recordedLines}/{battleSceneStats['leader'].totalLines}
                </Inline>
                <Button
                  contained={!battleSceneStats['leader'].recorded}
                  onClick={() =>
                    routeTo(`/session/${user.id}/battle-scene-leader`, { resetWindowScroll: true })
                  }
                >
                  {battleSceneStats['leader'].recorded ? 'Open scene' : 'Open scene'}
                </Button>
                <Inline>As Air Support</Inline>
                <Inline letterSpacing="10px">
                  {battleSceneStats['airsupport'].recordedLines}/
                  {battleSceneStats['airsupport'].totalLines}
                </Inline>
                <Button
                  contained={!battleSceneStats['airsupport'].recorded}
                  onClick={() =>
                    routeTo(`/session/${user.id}/battle-scene-airsupport`, {
                      resetWindowScroll: true
                    })
                  }
                >
                  {battleSceneStats['airsupport'].recorded ? 'Open scene' : 'Open scene'}
                </Button>
              </Grid>
            </Section>
          </article>

          <SpacerVertical huge />

          <article id="account-settings">
            <H2 size={size}>Account settings</H2>
            <SpacerVertical />
            <AccountSettings />
          </article>

          <SpacerVertical huge />
        </Block>
      </Col>
      <Route path="/user/:userId/change-password" component={ChangePassword} />
      <Route path="/user/:userId/upload-audio" component={UploadAudio} />
      <Route
        path="/user/:userId/edit-audio/:audioId"
        render={(props: any) => {
          const audio = user.audio?.find((a: any) => a.id === props.audioId)
          return <EditAudio {...props} audio={audio} />
        }}
      />
      <Route
        path="/user/:userId/delete-audio/:audioId"
        render={(props: any) => {
          const audio = user.audio?.find((a: any) => a.id === props.audioId)
          return <DeleteAudio {...props} audio={audio} />
        }}
      />
    </Fragment>
  )
}

export default connect('user, currentUser, isUpdatingUser')(UserPage as any)
