import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { goBack, Route, routeTo } from '@sodra/prutt'
import { EditIcon, SpacerVertical, TextArea, TextField } from '@sodra/bongo-ui'

import { updateAgency } from '../../actions'

import Avatar from '../Avatar'
import LargeDialog from '../LargeDialog'
import { Col } from 'jsxstyle/preact'
import PictureDialog from '../PictureDialog'

const EditDetails = ({ agency, isUpdatingAgency }) => {
  const [name, setName] = useState(agency.name)
  const [legalName, setLegalName] = useState(agency.legalName)
  const [address, setAddress] = useState(agency.address)

  const [picture, setPicture] = useState(agency.picture)

  const onClose = () => goBack(`/agency/${agency.id}`)

  const handleSubmit = async () => {
    if (await updateAgency({ name, legalName, address })) {
      onClose()
    }
  }

  const updatePicture = async (url) => {
    await updateAgency({ picture: url })
    setPicture(url)
    return true
  }

  const removePicture = async () => {
    await updateAgency({ picture: '' })
    setPicture('')
    return true
  }

  return (
    <LargeDialog
      title="Edit details"
      actions={[
        { text: 'Save', type: 'contained', loading: isUpdatingAgency, onClick: handleSubmit },
        { text: 'Cancel', onClick: onClose }
      ]}
      onClose={onClose}
    >
      <Col alignItems="center">
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() => routeTo(`/agency/${agency.id}/edit-details/picture`)}
          size="150"
          src={picture}
          name={name}
        />
      </Col>
      <SpacerVertical />
      <TextField width="100%" label="Name" value={name} onInput={setName} onChange={setName} />
      <SpacerVertical />
      <TextField
        width="100%"
        label="Legal name"
        value={legalName}
        onInput={setLegalName}
        onChange={setLegalName}
      />
      <SpacerVertical />
      <TextArea width="100%" label="Address" value={address} onInput={setAddress} />
      <SpacerVertical />
      <Route
        path="/agency/:agencyId/edit-details/picture"
        render={() => (
          <PictureDialog
            title="Agency picture"
            url={picture}
            name={name}
            onUpdate={updatePicture}
            onRemove={removePicture}
            onClose={() => goBack(`/agency/${agency.id}/edit-details`)}
          />
        )}
      />
    </LargeDialog>
  )
}

export default connect('agency, isUpdatingAgency')(EditDetails)
