import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'

import { deleteAgency } from '../../actions'

const DeleteAgency = ({ agency, onClose, onDelete, isDeletingAgency }) => {
  const handleDelete = async () => {
    if (await deleteAgency(agency.id)) {
      if (onDelete) {
        onDelete()
      }
      onClose()
    }
  }

  if (!agency) {
    return null
  }

  return (
    <BasicDialog
      title="Delete agency"
      onClose={onClose}
      primaryActionText="Delete"
      primaryActionLoading={isDeletingAgency}
      onPrimaryActionClick={handleDelete}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Delete agency {agency.name}?
    </BasicDialog>
  )
}

export default connect('isDeletingAgency')(DeleteAgency)
