import { h } from 'preact'
import { BasicDialog, H2, P, PersonIcon, SpacerVertical } from '@sodra/bongo-ui'
import { Row } from 'jsxstyle/preact';

const princessUrl = new URL(
    './princess.webp',
    import.meta.url
);

type Props = {
    onClose: () => void
}

export function SuccessDialog({ onClose }: Props) {
    return (
        <BasicDialog
            disableAutofocus
            title="Voice profile complete!"
            primaryActionText="Close"
            onPrimaryActionClick={onClose}
            onClose={onClose}
        >
            <Row justifyContent="center" padding="20px">
                <img src={princessUrl.href} style={{ width: "80%" }} />
            </Row>
            <SpacerVertical large />
            <H2 size="5">Thank you!</H2>
            <SpacerVertical />
            <P>
                Your voice profile is now complete. We will listen to the example recordings you've provided and let you know when your profile is activated and you are ready to be assigned for work.
            </P>
            <SpacerVertical large />
        </BasicDialog>
    )
}