import { Fragment, h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { goBack } from '@sodra/prutt'
import { BasicDialog, Checkbox, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { updateCurrentUser } from '../actions'
import { useMedia } from '@sodra/use-media'

const ChangePassword = ({ currentUser, isUpdatingCurrentUser }) => {
  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const [hideDialog, setHideDialog] = useState(false)

  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const onClose = () => goBack('/account')

  const handleSubmit = async () => {
    if (await updateCurrentUser({ password })) {
      setHideDialog(true)
    }
  }

  return (
    <Fragment>
      <BasicDialog
        disableAutofocus={!supportsHover}
        title="Change password"
        primaryActionText="Save"
        primaryActionLoading={isUpdatingCurrentUser}
        onPrimaryActionClick={handleSubmit}
        action1Text="Cancel"
        onAction1Click={() => setHideDialog(true)}
        onClose={onClose}
        hideDialog={hideDialog}
      >
        <Form onSubmit={handleSubmit}>
          <input
            type="email"
            label="username"
            value={currentUser.email}
            style={{ display: 'none' }}
            autocomplete="username"
            disabled
          />
          <TextField
            width="100%"
            type={!showPassword ? 'password' : 'text'}
            name="new-password"
            label="New password"
            value={password}
            onInput={setPassword}
            autocomplete="new-password"
          />
          <SpacerVertical small />
          <Checkbox label="Show password" checked={showPassword} onChange={setShowPassword} />
          <SpacerVertical />
          <input type="submit" style={{ position: 'absolute', visibility: 'hidden' }} />
        </Form>
      </BasicDialog>
    </Fragment>
  )
}

export default connect('currentUser, isUpdatingCurrentUser')(ChangePassword)
