import { h } from 'preact'
import { BasicDialog, List, ListIcon, ListItem, SpacerVertical } from '@sodra/bongo-ui'

const types = [
  'Dialogue',
  'Projected',
  'Shouting',
  'Warcry',
  'Acting',
  'Announcer',
  'Character voice',
  'E-sports',
  'News anchor',
  'Newsreader',
  'Presenter',
  'Radio commercial',
  'Reporter',
  'Singing',
  'Tutorial',
  'TV commercial',
  'Creature',
  'Non-human',
  'Alien',
  'Whispering',
  'Trailer'
]

const SelectType = ({ onSelect, onClose }) => {
  const selectType = (type) => {
    if (onSelect) {
      onSelect(type)
    }
    onClose()
  }

  return (
    <BasicDialog title="Select type" onClose={onClose} action1Text="Cancel">
      <List>
        {types.sort().map((type) => {
          return <ListItem text={type} onClick={() => selectType(type)} />
        })}
      </List>
    </BasicDialog>
  )
}

export default SelectType
