import { h } from 'preact'
import { Block } from 'jsxstyle/preact'

const numberFormat = new Intl.NumberFormat()
export const NumberRenderer = (data) => {
  if (data === undefined || data === null) {
    return ''
  }
  return (
    <Block textAlign="right" fontFamily="monospace">
      {numberFormat.format(data)}
    </Block>
  )
}

export default NumberRenderer
