import { h } from 'preact'
import { BasicDialog, List, ListItem } from '@sodra/bongo-ui'

const tones = [
  'Light',
  'Dark',
  'Raspy',
  'Soft',
  'Accusing',
  'Aggressive',
  'Authoritative',
  'Believable',
  'Calm',
  'Caring',
  'Cartoon',
  'Childish',
  'Comic',
  'Confident',
  'Conversational',
  'Corporate',
  'Deep',
  'Educational',
  'Energetic',
  'Engaging',
  'Exciting',
  'Explainatory',
  'Fast',
  'Friendly',
  'Frightening',
  'Fun',
  'Happy',
  'Hard',
  'Instructional',
  'Joyful',
  'Laid back',
  'Lively',
  'Narrative',
  'Natural',
  'Neutral',
  'Poetic',
  'Positive',
  'Relaxed',
  'Reliable',
  'Robotic',
  'Romantic',
  'Sarcastic',
  'Slow',
  'Soothing',
  'Storytelling',
  'Suspicious',
  'Threatening',
  'Tough',
  'Trustworthy',
  'Upbeat',
  'Warm',
  'Mysterious',
  'Scheming',
  'Nervous'
]
const SelectTone = ({ onSelect, onClose }) => {
  const selectTone = (tone) => {
    if (onSelect) {
      onSelect(tone)
    }
    onClose()
  }

  return (
    <BasicDialog
      title="Select tone"
      onClose={onClose}
      action1Text="Cancel"
      onAction1Click={onClose}
    >
      <List>
        {tones.sort().map((tone) => {
          return <ListItem text={tone} onClick={() => selectTone(tone)} />
        })}
      </List>
    </BasicDialog>
  )
}

export default SelectTone
