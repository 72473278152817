import { store } from '../store'
import { get, patch, post, del } from '../api'

export const fetchUser = async (userId) => {
  store.setState({ isFetchingUser: true })
  try {
    const { data: user } = await get(`/users/${userId}`)
    store.setState({ user, isFetchingUser: false })
    return user
  } catch (error) {
    store.setState({ error, isFetchingUser: false })
  }
}

export const createUser = async (params) => {
  store.setState({ isCreatingUser: true })
  try {
    const { data: user } = await post(`/users`, params)
    if (params.login) {
      store.setState({ currentUser: user, isCreatingUser: false })
    } else {
      store.setState({ user, isCreatingUser: false })
    }
    return user
  } catch (error) {
    store.setState({ error, isCreatingUser: false })
  }
}

export const updateUser = async (params) => {
  const userId = store.getState().user.id
  store.setState({ isUpdatingUser: true })
  try {
    const { data: user } = await patch(`/users/${userId}`, params)
    if (user.id === store.getState().currentUser.id) {
      store.setState({ currentUser: user })
    }
    store.setState({ user, isUpdatingUser: false })
    return user
  } catch (error) {
    store.setState({ error, isUpdatingUser: false })
  }
}

export const updateCurrentUser = async (params) => {
  const userId = store.getState().currentUser.id
  store.setState({ isUpdatingCurrentUser: true })
  try {
    const { data: currentUser } = await patch(`/users/${userId}`, params)
    store.setState({ currentUser, isUpdatingCurrentUser: false })
    return true
  } catch (error) {
    store.setState({ error, isUpdatingCurrentUser: false })
  }
}

export const updateCurrentUserPicture = async (url) => {
  const userId = store.getState().currentUser.id
  store.setState({ isUpdatingCurrentUserPicture: true })
  try {
    const { data: currentUser } = await patch(`/users/${userId}`, { picture: url })
    store.setState({ currentUser, isUpdatingCurrentUserPicture: false })
    return true
  } catch (error) {
    store.setState({ error, isUpdatingCurrentUserPicture: false })
  }
}

export const removeCurrentUserPicture = async () => {
  const userId = store.getState().currentUser.id
  store.setState({ isUpdatingCurrentUserPicture: true })
  try {
    const { data: currentUser } = await patch(`/users/${userId}`, { picture: '' })
    store.setState({ currentUser, isUpdatingCurrentUserPicture: false })
    return true
  } catch (error) {
    store.setState({ error, isUpdatingCurrentUserPicture: false })
  }
}

export const deleteUser = async (userId) => {
  store.setState({ isDeletingUser: true })
  try {
    await del(`/users/${userId}`)
    store.setState({ isDeletingUser: false })
    if (store.getState().user?.id === userId) {
      store.setState({ user: undefined })
    }
    return true
  } catch (error) {
    store.setState({ error, isDeletingUser: false })
  }
}
