import { h } from 'preact'
import { routeTo } from '@sodra/prutt'

import { useLocalStorageState } from '../../use-local-storage-state'

import { usePagedItems } from '../use-paged-items'

import Listing from '../Listing'

const InvitationListing = ({ agency }) => {
  const [page, setPage] = useLocalStorageState(`speechless:${agency.id}:voice-invitations:page`, 1)
  const [query, setQuery] = useLocalStorageState(
    `speechless:${agency.id}:voice-invitations:query`,
    ''
  )

  const { items: invitations, pages } = usePagedItems({
    items: agency.voiceInvitations,
    page,
    query,
    setPage
  })

  return (
    <Listing
      page={page}
      pages={pages}
      setPage={setPage}
      items={invitations.map((invitation) => ({
        data: invitation,
        title: invitation.email,
        description: 'Pending'
      }))}
      popupMenuOptions={[
        {
          label: 'Delete',
          onClick: (invitation) =>
            routeTo(`/agency/${agency.id}/delete-invitation/${invitation.id}`)
        }
      ]}
    />
  )
}

export default InvitationListing
