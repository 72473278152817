import { h } from 'preact'
import { Block } from 'jsxstyle/preact'
import { MutableRef, useEffect, useRef, useState } from 'preact/hooks'
import { useMedia } from '@sodra/use-media'

type Bounds = {
  x: number
  y: number
  width: number
  height: number
}

function useBounds(elem: MutableRef<HTMLDivElement | null>) {
  const [bounds, setBounds] = useState<Bounds | null>(null)

  useEffect(() => {
    if (elem.current) {
      const b = elem.current.getBoundingClientRect()
      setBounds({ x: b.x || b.left, y: b.y || b.top, width: b.width, height: b.height })

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === elem.current) {
            const b = elem.current.getBoundingClientRect()
            setBounds({
              x: b.x || b.left,
              y: b.y || b.top,
              width: entry.contentRect.width,
              height: entry.contentRect.height
            })
          }
        }
      })
      resizeObserver.observe(elem.current)
      return () => {
        if (!elem.current) return
        resizeObserver.unobserve(elem.current)
      }
    }
  }, [elem.current])

  useEffect(() => {
    function onWindowResize() {
      if (!elem.current) return
      const b = elem.current.getBoundingClientRect()
      setBounds({ x: b.x || b.left, y: b.y || b.top, width: b.width, height: b.height })
    }
    window.addEventListener('resize', onWindowResize)

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])

  return bounds
}

function calcValue(e: TouchEvent | any, bounds: Bounds) {
  const padding = 0

  if (e && e.touches && e.touches.length === 0) {
    return
  }

  const clientX = e.touches ? e.touches[0].clientX : e.clientX
  const offset = clientX - padding - bounds.x

  let newValue = offset / (bounds.width - padding * 2)

  if (newValue < 0) {
    return 0
  } else if (newValue > 1) {
    return 1
  }

  return newValue
}

function getViewportTime(duration: number, pos: number, zoom: number) {
  const zoomDuration = duration / zoom

  const min = Math.max(0, duration * pos - zoomDuration / 2)
  const max = Math.min(duration, min + zoomDuration)

  return { min, max }
}

const getTime = (value: number, audioBuffer: AudioBuffer, pos: number, zoom: number) => {
  // New time
  const { min: viewportMin, max: viewportMax } = getViewportTime(audioBuffer.duration, pos, zoom)
  let newTime = viewportMin + value * (viewportMax - viewportMin)
  if (newTime < viewportMin) {
    newTime = viewportMin
  } else if (newTime > viewportMax) {
    newTime = viewportMax
  }
  return newTime
}

type Props = {
  audioBuffer: AudioBuffer
  startTime: number
  onChangeStart: (value: number) => void
  endTime: number
  onChangeEnd: (value: number) => void
  pos: number
  zoom: number
  disabled: boolean
}

export function AudioSelect({
  audioBuffer,
  startTime,
  onChangeStart,
  endTime,
  onChangeEnd,
  pos,
  zoom,
  disabled
}: Props) {
  const supportsHover = useMedia(['(hover: hover)'], [true], false)

  const elem = useRef<(HTMLDivElement & { base: HTMLDivElement }) | null>(null)
  const bounds = useBounds(elem)

  const [startValue, setStartValue] = useState<number | null>(null)
  const [endValue, setEndValue] = useState<number | null>(null)

  const updateStart = (v: number) => {
    if (!onChangeStart) return

    const value = v >= 0 ? v : startValue
    if (value === null) return
    const time = getTime(value, audioBuffer, pos, zoom)
    onChangeStart(time)
  }

  const updateEnd = (v: number) => {
    if (!onChangeEnd) return

    const value = v >= 0 ? v : endValue
    if (value === null) return
    const time = getTime(value, audioBuffer, pos, zoom)
    onChangeEnd(time)
  }

  // Update selection when external data changes
  useEffect(() => {
    if (!bounds) return

    const { min: viewportMin, max: viewportMax } = getViewportTime(audioBuffer.duration, pos, zoom)

    // Start
    const start = (startTime - viewportMin) / (viewportMax - viewportMin)
    setStartValue(start)

    // End
    const end = (endTime - viewportMin) / (viewportMax - viewportMin)
    setEndValue(end)

  }, [bounds, startTime, endTime, audioBuffer, pos, zoom])

  const [markers, setMarkers] = useState<{ start: number; end: number }>({
    start: 0,
    end: 1,
  })
  const [isMoving, setIsMoving] = useState(false)

  const [movingMarker, setMovingMarker] = useState<'start' | 'end' | null>(null)

  function onMouseDown(e: MouseEvent) {
    if (!bounds) return
    const value = calcValue(e, bounds)
    if (!value) return

    if (!movingMarker) {
      setStartValue(value)
      setEndValue(value)
      updateStart(value)
      updateEnd(value)
      setMarkers({
        start: value,
        end: 1,
      })
    }

    setIsMoving(true)
  }

  function handleMoving(e: MouseEvent) {
    if (!bounds) return
    const value = calcValue(e, bounds)
    if (!value) return
    if (!movingMarker) {
      setMarkers({
        start: markers.start,
        end: value
      })
    }

    if (movingMarker === 'start') {
      setMarkers({
        start: value,
        end: markers.end
      })
    }

    if (movingMarker === 'end') {
      setMarkers({
        start: markers.start,
        end: value
      })
    }
  }

  function onMouseMove(e: MouseEvent) {
    if (!bounds) return
    const value = calcValue(e, bounds)
    if (!value) return

    const dStart = Math.abs(markers.start - value)
    const dEnd = Math.abs(markers.end - value)

    const closest = Math.min(dStart, dEnd)

    if (closest < 0.005) {
      setMovingMarker(closest === dStart ? 'start' : 'end')
    } else {
      setMovingMarker(null)
    }
  }

  function onMouseUp(e: MouseEvent) {
    setMovingMarker(null)
    setIsMoving(false)
    if (startValue !== null && endValue !== null && Math.abs(startValue - endValue) < 0.0001) {
      // Reset start/end if start and end are "the same"
      setStartValue(null)
      setEndValue(null)
      updateStart(0)
      updateEnd(1)
      setMarkers({
        start: 0,
        end: 1
      })
    }
  }

  function handleMouseOut(e: MouseEvent) {
    setIsMoving(false)
  }

  useEffect(() => {
    if (markers.start === null || markers.end === null) return
    if (markers.start < markers.end) {
      if (markers.start) {
        setStartValue(markers.start)
        updateStart(markers.start)
      }
      if (markers.end) {
        setEndValue(markers.end)
        updateEnd(markers.end)
      }
    }
    if (markers.start > markers.end) {
      if (markers.start) {
        setEndValue(markers.start)
        updateEnd(markers.start)
      }
      if (markers.end) {
        setStartValue(markers.end)
        updateStart(markers.end)
      }
    }
  }, [markers])

  return (
    <Block
      userSelect="none"
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      props={{
        ref: elem,
        onMouseDown: !disabled ? onMouseDown : null,
        onMouseMove: !disabled && isMoving ? handleMoving : onMouseMove,
        onMouseUp: !disabled && isMoving ? onMouseUp : null,
        onMouseLeave: !disabled && supportsHover ? handleMouseOut : null,
        onTouchStart: !disabled ? onMouseDown : null,
        onTouchMove:  !disabled && isMoving ? handleMoving : onMouseMove,
        onTouchEnd: !disabled && isMoving ? onMouseUp : null,
      }}
      cursor={movingMarker ? 'ew-resize' : 'text' }
      overflow="hidden"
    >
      {bounds && (
        <Block
          position="absolute"
          top="0"
          bottom="0"
          left={`${startValue !== null ? startValue * bounds.width : 0}px`}
          borderLeft="2px solid var(--on-surface)"
        />
      )}
      {bounds && (
        <Block
          position="absolute"
          top="0"
          bottom="0"
          right={`${
            endValue !== null ? bounds.width - endValue * bounds.width : bounds.width + 2
          }px`}
          borderRight="2px solid var(--on-surface)"
        />
      )}
    </Block>
  )
}
