import { h } from 'preact'
import { Block } from 'jsxstyle/preact'

export const Form = ({ children, onSubmit, props, ...rest }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    if (onSubmit) {
      onSubmit()
    }
  }

  return (
    <Block component="form" props={{ onSubmit: handleSubmit, ...props }} {...rest}>
      {children}
    </Block>
  )
}
