import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { ColorName, colors } from '../../colors'
import { drawAudioBuffer, useDrawAudioBuffer } from './draw-audio-buffer'
import { useAnimationFrameCanvas } from './useAnimationFrame'

type Props = {
  audioBuffer: AudioBuffer
  height?: string
  zoom?: number
  pos?: number
  setPos?: (zoom: number) => void
  start?: number
  end?: number
  currentTime?: number
  color?: ColorName
  progressColor?: ColorName
}

export function AudioBufferVisualizer({
  audioBuffer,
  height = '100px',
  zoom = 1,
  pos = 0.5,
  start: startTime = 0,
  end: endTime = Infinity,
  currentTime = 0,
  color = '--on-surface',
  progressColor = '--accent'
}: Props) {
  const canvasElem = useRef<HTMLCanvasElement | null>(null)

  // NOTE: Slow (cpu intensive) rendering. Can be removed later on.
  // useAnimationFrameCanvas(canvasElem, () => {
  //   drawAudioBuffer({
  //     canvas: canvasElem.current,
  //     audioBuffer,
  //     color: style.getPropertyValue(color),
  //     progressColor: style.getPropertyValue(progressColor),
  //     startTime,
  //     endTime,
  //     currentTime,
  //     zoom,
  //     pos
  //   })
  // })

  // NOTE: Needed to make sure useDrawAudioBuffer is called when
  // canvasElem.current is updated
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
  useEffect(() => {
    setCanvas(canvasElem.current)
  }, [canvasElem.current])
  // NOTE: end

  useDrawAudioBuffer({
    canvas,
    audioBuffer,
    color: colors[color],
    progressColor: colors[progressColor],
    startTime,
    endTime,
    currentTime,
    zoom,
    pos
  })

  return <canvas ref={canvasElem} style={{ width: '100%', height }} />
}
