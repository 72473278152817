import { Fragment, h } from 'preact'
import { BasicDialog, Button, NativeSelect, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { useEffect, useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'

const allMicrophones = [
  'Shure SM7B',
  'Aston Microphones Spirit',
  'AKG C414',
  'Shure Super 55',
  'Rode NTK',
  'Shure SM58',
  'IK Multimedia iRig Mic Studio',
  'AKG C636',
  'Shure SM57',
  'Shure SM58',
  'Neumann U 87',
  'Neumann M 50',
  'Shure SM7B',
  'Electro-Voice RE20',
  'AKG C 414',
  'Royer R-121',
  'Telefunken Ela M 251',
  'AKG C 12',
  'Telefunken/Neumann U 47',
  'Neumann U 67',
  'Neumann M 49',
  'Sennheiser MD 421',
  'Sennheiser MD 441U',
  'RCA 44-BX',
  'Coles 4038',
  'Neumann KM 84',
  'AKG C 451',
  'RCA 77-DX',
  'Sony C-800G',
  'Blue Yeti',
  'Rode NT1-A',
  'Neumann TLM 103',
  'Beyerdynamic M 160',
  'Mojave Audio MA-200',
  'Blue Bottle',
  'DPA 4011',
  'DPA 4006',
  'Shure Beta 87A',
  'Neumann KM 140',
  'Audio-Technica AT4050',
  'Schoeps MK 4 / CMC 6',
  'Sennheiser e604',
  'Shure SM81',
  'Schoeps CMIT 5U',
  'Sennheiser MKH 416',
  'Sennheiser MKH 60',
  'DPA 4060',
  'Sony ECM 77B',
  'Sanken COS-11D',
  'Countryman B3',
  'Heil PR40',
  'Shure Beta 52',
  'DPA 4099',
  'Shure Beta 98H/C',
  'Schoeps CMXY 4V',
  'Sennheiser MKH 418-S',
  'Royer SF-24',
  'Crown PZM 30D'
]

const exampleMicrophoneText = `eg. ${allMicrophones[Math.floor((Math.random() * allMicrophones.length))]}`

const AddMicrophone = ({ current, onAdd, onClose }) => {
  const [mode, setMode] = useState('select-input')
  const [microphone, setMicrophone] = useState(null)
  const [microphoneError, setMicrophoneError] = useState(null)

  useEffect(() => {
    if (!microphone) return
    setMicrophoneError(null)
  }, [microphone])

  const handleSave = () => {
    if (!microphone) {
      setMicrophoneError('Please select a microphone')
      return
    }
    if (onAdd) {
      onAdd(microphone)
    }
    onClose()
  }

  const options = allMicrophones
    .filter((microphone) => !current.includes(microphone))
    .sort()
    .map((microphone) => ({ value: microphone, label: microphone }))

  return (
    <BasicDialog
      title="Add microphone"
      primaryActionText="Add"
      onPrimaryActionClick={handleSave}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Block minHeight="100px">
        <SpacerVertical />

        {mode === 'select-input' && (
          <Fragment>
            <NativeSelect
              width="100%"
              errorText={microphoneError}
              label="Microphone"
              value={microphone}
              onChange={setMicrophone}
              options={options}
            />
            <SpacerVertical />
            <Button
              onClick={() => {
                setMicrophone(null)
                setMicrophoneError(null)
                setMode('text-input')
              }}
            >
              other
            </Button>
            <SpacerVertical large/>
          </Fragment>
        )}
        {mode === 'text-input' && (
          <TextField
            width="100%"
            errorText={microphoneError}
            value={microphone}
            infoText={exampleMicrophoneText}
            label="Microphone"
            onChange={setMicrophone}
          />
        )}
      </Block>
    </BasicDialog>
  )
}

export default AddMicrophone
