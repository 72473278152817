import { store, resetStore } from '../store'
import { post } from '../api'
import { routeTo } from '@sodra/prutt'

export * from './agencies'
export * from './users'
export * from './audio'
export * from './player'
export * from './invitations'
export * from './image'

export const login = async ({ email, password }) => {
  try {
    store.setState({ isLoggingIn: true })
    const { data: currentUser } = await post('/login', { email, password })
    store.setState({ currentUser, isLoggingIn: false })
    return currentUser
  } catch (error) {
    store.setState({ error, isLoggingIn: false })
  }
}

export const logout = async () => {
  try {
    store.setState({ isLoggingOut: true })
    await post('/logout')
    routeTo('/')
    resetStore()
    return true
  } catch (error) {
    store.setState({ error, isLoggingOut: false })
  }
}

export const forgotPassword = async (params) => {
  try {
    store.setState({ isForgettingPassword: true })
    await post('/forgot-password', params)
    store.setState({ isForgettingPassword: false })
    return true
  } catch (error) {
    store.setState({ error, isForgettingPassword: false })
  }
}

export const resetPassword = async (params) => {
  try {
    store.setState({ isResettingPassword: true })
    const { data: currentUser } = await post('/reset-password', params)
    store.setState({ currentUser, isResettingPassword: false })
    return currentUser
  } catch (error) {
    store.setState({ error, isResettingPassword: false })
  }
}

export const verifyUser = async (userId) => {
  try {
    store.setState({ isVerifyingUser: true })
    await post(`/users/${userId}/verify`)
    store.setState({ isVerifyingUser: false })
    return true
  } catch (error) {
    store.setState({ error, isVerifyingUser: false })
  }
}

export const setError = (error) => store.setState({ error })

export const clearError = () => setError(null)

export const showSnackbar = (message) => store.setState({ snackbar: message })

export const hideSnackbar = () => store.setState({ snackbar: undefined })
