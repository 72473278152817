import { Block, Col } from 'jsxstyle/preact'
import { h } from 'preact'
import { ScrollBar } from './ScrollBar'

type Props = {
  children: h.JSX.Element
  pos: number
  setPos: (pos: number) => void
  zoom: number
  setZoom: (zoom: number) => void
}

/*
    Creates a scrollbar that can be used to control child elements, e.g. a canvas element.
*/
export function ScrollControl({ children, pos, setPos, zoom, setZoom }: Props) {
  return (
    <Col
      flex="1"
      props={{
        onWheel: (e: any) => {
          e.preventDefault()
          const rect = e.target.getBoundingClientRect()
          // TODO: Use x to zoom to cursor
          const x = e.clientX - rect.left
          const zoomIn = e.deltaY > 0
          let newZoom = Math.max(zoom * (zoomIn ? 0.9 : 1.1))
          if (newZoom < 1) {
            newZoom = 1
          }
          setZoom(newZoom)
        }
      }}
    >
      {children}
      <ScrollBar zoom={zoom} pos={pos} setPos={setPos} />
    </Col>
  )
}
